import Layout from "../../layout";
import { Introduction, PerfectPrediction } from "../../features";
import "../../features/home/Home.scss";

const Home = () => {
  return (
    <div>
      <Layout>
        <Introduction />
        <PerfectPrediction />
      </Layout>
    </div>
  );
};

export default Home;
