/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import "./TourneyCreated.scss";
import Header from "../../layout/header";

const TourneyCreated = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const location = useLocation();
  const [matchTimer, setMatchTimer] = useState<string>("");
  const [daysDifference, setDaysDifference] = useState(0);
  const [hoursDifference, setHoursDifference] = useState(0);
  const [minutesDifference, setMinutesDifference] = useState(0);
  const [timeDifference, setTimeDifference] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [currentDate, setCurrentDate] = useState("");

  const { state } = location;

  const data = state?.response?.data?.tournament;
  const seasonBanner = state?.response?.data?.seasonBanner;

  const copiedFixtures = data ? [...data?.fixtures] : [];
  const sortedFixtures = copiedFixtures?.sort((a, b) => {
    const dateA = moment(a.fixture.date) as any;
    const dateB = moment(b.fixture.date) as any;
    return dateA - dateB;
  });

  const firstDate = sortedFixtures?.length
    ? sortedFixtures[0]?.fixture.date
    : undefined;
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (firstDate) {
      const updateTimeLeft = () => {
        const currentDateTime = moment();

        const targetDateTime = moment(firstDate);

        const timeDiff = targetDateTime.diff(currentDateTime);

        if (timeDiff > 0) {
          const duration = moment.duration(timeDiff);

          const days = String(duration.days()).padStart(2, "0");
          const hours = String(duration.hours()).padStart(2, "0");
          const minutes = String(duration.minutes()).padStart(2, "0");
          const seconds = String(duration.seconds()).padStart(2, "0");

          const remainingDaysNumber = parseInt(days, 10);
          // const remainingHoursNumber = parseInt(hours, 10);
          const timerString = `${hours}h ${minutes}m ${seconds}s`;
          if (remainingDaysNumber >= 1) {
            setMatchTimer(`${days}  days`);
          } else {
            setMatchTimer(timerString);
          }
        } else {
          clearInterval(timer);
          setMatchTimer("");
        }
      };

      timer = setInterval(updateTimeLeft, 1000);
      updateTimeLeft();
    } else {
      console.log("No fixtures found.");
    }

    return () => {
      clearInterval(timer);
    };
  }, [firstDate]);

  useEffect(() => {
    if (seasonBanner) {
      const startDateTime = moment(
        `${seasonBanner?.startDate}T${seasonBanner?.startTime}:00.037Z`
      );

      const currentDateTime = moment().format("YYYY-MM-DD HH:mm");

      setCurrentDate(currentDateTime);
      setStartDate(seasonBanner?.startDate);
      const daysDiff = startDateTime.diff(currentDateTime, "days");
      const hoursDiff = startDateTime.diff(currentDateTime, "hours");
      const minutesDiff = startDateTime.diff(currentDateTime, "minutes");

      const hoursRemaining = ((hoursDiff % 24) + 24) % 24;
      setDaysDifference(daysDiff);
      setHoursDifference(hoursDiff);
      setMinutesDifference(minutesDiff);
    }
  }, [seasonBanner]);

  return (
    <div>
      <Header />
      <div className="nv_spc cm_trny_img trny_crtd">
        <div className="container">
          <div className="mn_trny_crtd">
            <div className="trnys">
              <div className="trny_lft">
                <figure>{/* <img src={data?.image} alt="" /> */}</figure>
              </div>
              <div className="trny_ryt">
                <h4>{data?.name}</h4>
                <h5 className="yellow_text crtd">
                  {translation.tourneyCreated.created}
                </h5>
                <p>{moment(firstDate).format("dddd, HH:mm")}</p>
                <h2>
                  {state?.totalPot ? state?.totalPot : state?.totalToken}{" "}
                  {translation.mytourney.token}
                </h2>

                {/* <h5 className="yellow_text pb_bt">
                  {state?.tokenCategoriesArr?.length > 1
                    ? null
                    : `${state?.response?.data?.totalUsers || 0} total entries`}
                </h5> */}
                <h5>{translation.tourneyCreated.matchStart}</h5>
                <h4>{matchTimer}</h4>
              </div>
            </div>
            <div className="btn_dv">
              <button
                className="btn"
                type="button"
                onClick={() =>
                  navigate(`/predictions/${data?._id}`, {
                    state: {
                      tournamentName: data?.name,
                      tournamentImage: data?.image,
                      tournamentLive: data?.isLive,
                      tournamentFixtures: data?.fixtures,
                    },
                  })
                }
              >
                {translation.Globals.addEntry}
              </button>
              <button
                className="btn mob_bottom"
                type="button"
                onClick={() => navigate("/mytourneys")}
              >
                {translation.Globals.great}
              </button>
              <p>
                {translation.tourneyCreated.note1}{" "}
                <span className="yellow_text">{data?.cutOffTime} mins</span>{" "}
                <span>{translation.tourneyCreated.note3}</span>
              </p>
            </div>
          </div>
          {/* );
          })} */}
          {seasonBanner ? (
            <div
              className="season_tym season_tym2"
              style={{
                backgroundImage: seasonBanner?.image
                  ? `url(${seasonBanner?.image})`
                  : `url ${"static/images/perfect2.png"}`,
              }}
              onClick={() =>
                navigate(`/seasonaltournament/${seasonBanner?._id}`)
              }
            >
              <div className="ssn_lft">
                <h6>{`"${seasonBanner?.title}"`}</h6>
                <p
                  dangerouslySetInnerHTML={{
                    __html: seasonBanner?.description,
                  }}
                />
              </div>
              <div className="ssn_ryt">
                <h6>
                  {translation.tourneyCreated.reg_fee}:{" "}
                  <span>
                    {seasonBanner?.tokenFee} {translation.Globals.tokens}
                  </span>
                </h6>

                {hoursDifference <= 0 &&
                daysDifference <= 0 &&
                minutesDifference <= 0 ? (
                  <p className="text_danger italic reg_text">
                    Registration Closed
                  </p>
                ) : (
                  <p className="text_danger italic reg_text">
                    Registration closes in{" "}
                    {daysDifference > 0
                      ? `${daysDifference} day${daysDifference > 1 ? "s" : ""}`
                      : ""}
                    {hoursDifference > 0 && daysDifference <= 0
                      ? `${hoursDifference} ${
                          hoursDifference === 1 ? "hour" : "hours"
                        } ${minutesDifference} minutes`
                      : ""}
                    {hoursDifference <= 0 && daysDifference <= 0
                      ? `${minutesDifference} minute${
                          minutesDifference > 1 ? "s" : ""
                        }`
                      : ""}
                    {/* {hoursDifference <= 0 && daysDifference <= 0
                      ? `${minutesDifference} minutes`
                      : ""} */}
                  </p>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TourneyCreated;
