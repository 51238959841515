/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Layout from "../../layout";

import { Loader, MatchCard } from "../../components";
import useTranslation from "../../hooks/Translation";
import { RightDrawer } from "../../features";
import {
  useLazyGetSeasonDetailsQuery,
  useSubscribeMutation,
} from "../../service/tournaments";
import { errorToast } from "../../helpers/ShowToast";
import { Season, user } from "../../types/general";
import { useAppSelector } from "../../hooks/Store";
import { getToken } from "../../reducers/authSlice";
import { generateSEKAndHash } from "../../utils/crypto";

type props = {
  scrollPosition: ScrollPosition;
};
const SeasonalTournament = ({ scrollPosition }: props) => {
  const { id } = useParams();
  const [getSeason] = useLazyGetSeasonDetailsQuery();
  const [subscribe] = useSubscribeMutation();

  const token = useAppSelector(getToken);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [newCls, setNewCls] = useState<boolean>(false);
  const [ActiveDrawer, SetActiveDrawer] = useState(1);
  const [open, setOpen] = useState(false);
  const [seasonalData, setSeasonalData] = useState<Season>();
  // const [showPdf, setShowPdf] = useState(false);
  const [users, setUsers] = useState<user[]>([]);
  const [daysDifference, setDaysDifference] = useState(0);
  const [minutesDifference, setMinutesDifference] = useState(0);
  const [hoursDifference, setHoursDifference] = useState(0);
  const [currentDate, setCurrentDate] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
    outline: "none",
    border: "none",
    cursor: "pointer",
  };

  const onSelectCard = () => {};
  const handleClick = () => {
    setOpen(false);
    // navigate("/");
  };

  const doSubscribe = async (_id: any) => {
    if (token) {
      const data = {
        seasonId: _id,
        appkey: new Date().toISOString(),
      };
      const body = generateSEKAndHash(data);
      if (body) {
        try {
          setLoading(true);
          const response = await subscribe(body).unwrap();
          setLoading(false);
          if (response?.statusCode === 200) {
            setOpen(true);
            setTimeout(() => {
              navigate("/");
            }, 2000);
          }
        } catch (error: any) {
          setLoading(false);
          errorToast(error?.data?.message || "");
        }
      } else {
        navigate("/login");
      }
    }
  };

  const getSeasonalDetail = async (_id: any) => {
    try {
      setLoading(true);
      const response = await getSeason({ banner_id: _id }).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        setSeasonalData(response?.data);
        setUsers(response?.data?.users);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSeasonalDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (seasonalData) {
      const startDateTime = moment(
        `${seasonalData?.startDate}T${seasonalData?.startTime}:00.037Z`
      );
      const currentDateTime = moment().format("YYYY-MM-DD HH:mm");
      setCurrentDate(currentDateTime);

      const daysDiff = startDateTime.diff(currentDateTime, "days");
      const hoursDiff = startDateTime.diff(currentDateTime, "hours");
      const hoursRemaining = ((hoursDiff % 24) + 24) % 24;
      const minutesDiff = startDateTime.diff(currentDateTime, "minutes");

      setDaysDifference(daysDiff);
      setHoursDifference(hoursDiff);
      setMinutesDifference(minutesDiff);
    }
  }, [seasonalData]);

  return (
    <Layout>
      <Loader isLoad={loading} />
      <div className="nv_spc cmn_bg tornmain ">
        <RightDrawer
          sidebarVisible={sidebarVisible}
          setSidebarVisible={setSidebarVisible}
          ActiveDrawer={ActiveDrawer}
          tournamentId={seasonalData?._id}
          predictions=""
          userName=""
          userImage=""
        />
        <div className="container">
          <div className="sb">
            <div className="mob_view">
              <i
                className="fa-solid fa-arrow-up-long bck_btn mt_19"
                onClick={() => navigate("/")}
              />
            </div>
            <div className="sb dfgfg">
              <h2
                className="season_hdng"
                dangerouslySetInnerHTML={{
                  __html: seasonalData?.title || "",
                }}
              />
              <p className="yellow_txt">{translation.homepage.selection}</p>
            </div>
          </div>
          <div className="tourmain">
            <div className="trLft">
              <MatchCard
                onSelectCard={() => onSelectCard()}
                id=""
                image={seasonalData?.image}
                type={seasonalData?.type}
                tokenFee={seasonalData?.tokenFee}
                season={seasonalData?.title}
                startDate={seasonalData?.startDate}
                startTime={seasonalData?.startTime}
                endDate={seasonalData?.endDate}
                users={seasonalData?.users || undefined}
                setNewCls={setNewCls}
                seasonCat={seasonalData || undefined}
                isSubscribed={seasonalData?.isSubscribed}
                cutoff=""
              />
            </div>
            <div className={newCls ? "trRyt added" : "trRyt"}>
              <div className="">
                <div className="mob_view">
                  <i
                    className="fa-solid fa-arrow-up-long bck_btn"
                    onClick={() => setNewCls(false)}
                  />
                  <div className="matchmain" style={{ width: "100%" }}>
                    <div className="sb only_mob selct_head ">
                      <h2
                        className="season_hdng"
                        dangerouslySetInnerHTML={{
                          __html: seasonalData?.title || "",
                        }}
                      />
                      <p className="yellow_txt">
                        {translation.homepage.selection}
                      </p>
                    </div>
                  </div>
                  <div>
                    {" "}
                    <div className="matchmain_mob trLft  ">
                      <div className="matchmain" style={{ width: "100%" }}>
                        <MatchCard
                          onSelectCard={() => onSelectCard()}
                          id=""
                          image={seasonalData?.image}
                          type={seasonalData?.type}
                          tokenFee={seasonalData?.tokenFee}
                          season={seasonalData?.title}
                          startDate={seasonalData?.startDate}
                          startTime={seasonalData?.startTime}
                          endDate={seasonalData?.endDate}
                          users={seasonalData?.users || undefined}
                          isSubscribed={seasonalData?.isSubscribed}
                          seasonCat={seasonalData || undefined}
                          setNewCls={setNewCls}
                          cutoff=""
                        />
                      </div>
                    </div>
                    <div className="trTop">
                      <h4>
                        {moment(
                          `${seasonalData?.startDate}T${seasonalData?.startTime}:00.037Z`
                        ).format("dddd D MMMM, HH:mm")}
                      </h4>

                      <div className="trIcn">
                        <div
                          onClick={() => {
                            setSidebarVisible(true);
                            SetActiveDrawer(1);
                          }}
                        >
                          <figure>
                            <LazyLoadImage
                              src="/static/images/userarr.png"
                              alt=""
                              effect="blur"
                              height="100%"
                              width="100%"
                              scrollPosition={scrollPosition}
                            />
                          </figure>
                        </div>

                        {hoursDifference <= 0 &&
                        daysDifference <= 0 &&
                        minutesDifference <= 0 ? (
                          <div
                            onClick={() => {
                              if (token) {
                                navigate("/predictionlist", {
                                  state: {
                                    cardId: seasonalData?._id,
                                    type: seasonalData?.type,
                                  },
                                });
                              } else {
                                navigate("/login");
                              }
                            }}
                          >
                            <figure>
                              <LazyLoadImage
                                src="/static/images/pdf.svg"
                                alt=""
                                effect="blur"
                                height="100%"
                                width="100%"
                                scrollPosition={scrollPosition}
                              />
                            </figure>
                          </div>
                        ) : null}

                        <div
                          onClick={() => {
                            if (token) {
                              setSidebarVisible(true);
                              SetActiveDrawer(2);
                            } else {
                              navigate("/login");
                            }
                          }}
                        >
                          <figure>
                            <LazyLoadImage
                              src="/static/images/chat.png"
                              alt=""
                              effect="blur"
                              height="100%"
                              width="100%"
                              scrollPosition={scrollPosition}
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="btmdiv">
                      <h4 className="heading">
                        {translation.mytourney.total} {users?.length}{" "}
                        {translation.mytourney.entries}
                      </h4>
                      <div className="userlisting">
                        <div className="users">
                          <table>
                            <thead>
                              <tr>
                                <th>{translation.mytourney.rank}</th>

                                <th style={{ textAlign: "center" }}>
                                  {translation.mytourney.total_pts}
                                </th>
                              </tr>
                            </thead>
                            {users?.length
                              ? users.map((item) => {
                                  return (
                                    <tbody key={item?._id}>
                                      <tr>
                                        <td>
                                          <div className="user">
                                            <div className="userleft">
                                              <figure>
                                                <LazyLoadImage
                                                  src={
                                                    item?.image
                                                      ? item?.image
                                                      : "/static/images/user_placeholder.png"
                                                  }
                                                  alt=""
                                                  effect="blur"
                                                  height="100%"
                                                  width="100%"
                                                  scrollPosition={
                                                    scrollPosition
                                                  }
                                                />
                                              </figure>
                                              <h4>{item?.userName}</h4>
                                            </div>
                                          </div>
                                        </td>

                                        <td>{item?.total}</td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                              : null}
                          </table>

                          {seasonalData?.isSubscribed ||
                          (hoursDifference <= 0 &&
                            daysDifference <= 0 &&
                            minutesDifference <= 0) ? null : (
                            <button
                              className="newentry"
                              type="submit"
                              onClick={() => doSubscribe(id)}
                            >
                              {translation.seasonal_tournamnet.pay}{" "}
                              {seasonalData?.tokenFee || 0}{" "}
                              {translation.seasonal_tournamnet.subscribe}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="congrats_pop subscribed_pop"
      >
        <Box sx={style} className="congrat_wrp" onClick={handleClick}>
          <div className="congrats">
            <figure>
              <LazyLoadImage
                src="/static/images/congratulation.svg"
                alt=""
                effect="blur"
                height="100%"
                width="100%"
                scrollPosition={scrollPosition}
              />
            </figure>

            <h2> {translation.auth.congrats}</h2>

            <p>{translation.seasonal_tournamnet.subscribed}</p>
          </div>
        </Box>
      </Modal>
    </Layout>
  );
};

export default trackWindowScroll(SeasonalTournament);
