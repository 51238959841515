/* eslint-disable import/no-extraneous-dependencies */
import React, { Dispatch, SetStateAction } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useTranslation from "../hooks/Translation";
import useAuth from "../hooks/UseAuth";

interface Sidebartype {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  menuVisible: boolean;
  setMenuVisible: Dispatch<SetStateAction<boolean>>;
  SetActiveDrawer: Dispatch<SetStateAction<number>>;
  setSidebarVisible: Dispatch<SetStateAction<boolean>>;
  scrollPosition: ScrollPosition;
}

const SidebarMenu = ({
  toggleDrawer,
  menuVisible,
  setMenuVisible,
  SetActiveDrawer,
  setSidebarVisible,
  scrollPosition,
}: Sidebartype) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const user = useAuth();

  const handleTourneys = () => {
    setMenuVisible(false);
    navigate("/mytourneys");
  };
  const handleHome = () => {
    setMenuVisible(false);
    navigate("/");
  };
  const handleLeaderboard = () => {
    setMenuVisible(false);
    navigate("/leaderboarddetails");
  };
  const handleInviteFriends = () => {
    setMenuVisible(false);
    setSidebarVisible(true);
    SetActiveDrawer(1);
  };
  const handleUserProfile = () => {
    if (user) {
      setMenuVisible(false);
      navigate("/userprofile");
    } else {
      setMenuVisible(false);
      navigate("/signup");
    }
  };
  const list = () => (
    <Box
      className="drwr"
      sx={{ width: "100%", background: "transparent" }}
      role="presentation"
      onClick={() => setMenuVisible(false)}
    >
      <div className="mnu">
        <div>
          <button type="button" onClick={() => handleHome()}>
            <h4>{translation.header.home}</h4>
          </button>
        </div>
        <div>
          <button type="button" onClick={() => handleTourneys()}>
            <h4>{translation.header.my_tourneys}</h4>
          </button>
        </div>
        <div>
          <button type="button" onClick={() => handleLeaderboard()}>
            <h4>{translation.header.leaderboard} </h4>
          </button>
        </div>
        <div>
          <button
            type="button"
            onClick={() => {
              handleInviteFriends();
            }}
          >
            <h4>{translation.header.invite_friends}</h4>
          </button>
        </div>
        <div>
          <button type="button" onClick={() => handleUserProfile()}>
            <h4>{translation.header.profile}</h4>
          </button>
        </div>
        <div
          onClick={() => setMenuVisible(false)}
          className="mob_vw mob_vw_icon"
        >
          <figure>
            <LazyLoadImage
              src="/static/images/close-circle.png"
              alt=""
              effect="blur"
              scrollPosition={scrollPosition}
            />
          </figure>
        </div>
      </div>
    </Box>
  );
  return (
    <div>
      <SwipeableDrawer
        className="drwr"
        anchor="left"
        open={menuVisible}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
};

export default trackWindowScroll(SidebarMenu);
