/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { UserPridictionCard } from "../../components";
import useTranslation from "../../hooks/Translation";

interface MatchData {
  id: number;
  fixtureId: any;
  match_id: number;
  team_1_img: string;
  team_1: string;
  timeLeft: string;
  team_2: string;
  team_2_img: string;
}
interface GroupedData {
  [date: string]: MatchData[];
}
interface DrawerState {
  setSidebarVisible: Dispatch<SetStateAction<boolean>>;
  predictions: any;
  userName: string;
  userImage: string;
  scrollPosition: ScrollPosition;
}

const UserPridiction = ({
  setSidebarVisible,
  predictions,
  userName,
  userImage,
  scrollPosition,
}: DrawerState) => {
  const translation = useTranslation() as any;
  const [matches, setMatches] = useState<GroupedData | null>(null);

  const handleClick = () => {
    // setSelectedMatch(id);
  };

  useEffect(() => {
    const groupedData: GroupedData = predictions.reduce(
      (groups: GroupedData, obj: MatchData) => {
        const { fixtureId } = obj;
        const date = fixtureId?.fixture?.date.substring(0, 10); // Extracting only the date part
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(obj);
        return groups;
      },
      {}
    );
    setMatches(groupedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predictions]);

  return (
    <div className="chat_otr usr_pri_pop">
      <div className="userpridict">
        <div className="userimg">
          <div className="mob_view">
            <i
              className="fa-solid fa-arrow-up-long bck_btn"
              onClick={() => setSidebarVisible(false)}
            />
          </div>

          <figure>
            {!userImage ? (
              <LazyLoadImage
                src="/static/images/user_placeholder.png"
                alt=""
                effect="blur"
                height="100%"
                width="100%"
                scrollPosition={scrollPosition}
              />
            ) : (
              <LazyLoadImage
                src={userImage}
                alt=""
                effect="blur"
                height="100%"
                width="100%"
                scrollPosition={scrollPosition}
              />
            )}
          </figure>

          <h3>
            {userName} {translation.Globals.pre_dictions}
          </h3>
        </div>
        <div className="cards">
          {matches &&
            Object.keys(matches).map((date) => (
              <div key={date}>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    textAlign: "center",
                    padding: "10px 0 ",
                  }}
                >
                  {moment(date).format("dddd MMMM D")}
                </p>
                {matches[date]?.map((item) => (
                  <div
                    onClick={() => setSidebarVisible(false)}
                    className="prit_crd_mn"
                    key={item.id}
                  >
                    <UserPridictionCard
                      handleClick={() => handleClick()}
                      key={item.id}
                      id={item.id}
                      created_at={item.fixtureId}
                      match_id={item.match_id}
                      team_1_img={item.team_1_img}
                      team_1={item.team_1}
                      timeLeft={item.timeLeft}
                      team_2={item.team_2}
                      team_2_img={item.team_2_img}
                      items={item}
                      state=""
                    />
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default trackWindowScroll(UserPridiction);
