/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout";
import { useLazyGetSeasonDetailsQuery } from "../../service/tournaments";
import { Seasons } from "../../types/general";
import { Loader } from "../../components";
import useTranslation from "../../hooks/Translation";

const Bonus = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const translation = useTranslation() as any;

  const staticImage = "static/images/perfect2.png";

  const [bonusData, setBonusData] = useState<Seasons>();
  const [loading, setLoading] = useState(false);
  const [getBonus] = useLazyGetSeasonDetailsQuery();

  const getSeasonalDetail = async (_id: any) => {
    try {
      setLoading(true);
      const response = await getBonus({ banner_id: _id }).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        setBonusData(response?.data);
      }
    } catch (error: any) {
      setLoading(false);
      // errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getSeasonalDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Loader isLoad={loading} />
      <main className="page_bg">
        <div className=" tornmain nv_spc_bns ext_bn_wrp">
          <div className="container">
            <div className="mob_view">
              <i
                className="fa-solid fa-arrow-up-long bck_btn"
                onClick={() => navigate("/")}
              />
            </div>
            <h2 className="hdng">{translation.extraBonus.extraBonus}</h2>
            <div className="bonmain">
              <div
                className="bonusBnrr"
                style={{
                  backgroundImage: bonusData?.image
                    ? `url(${bonusData?.image})`
                    : `url ${staticImage}`,
                }}
              >
                <h1>{bonusData?.title}</h1>
                {/* <h3>
                  Collect more than 40 points in any tournaments and get up to
                  1000x your token entry fees
                </h3> */}
                {bonusData?.bannerDescription && (
                  <h3
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: bonusData.bannerDescription,
                    }}
                  />
                )}
              </div>

              <div className="tireddiv mt_40">
                {/* <h3>
                  Cheer up!! You’re automatically registered to this challenge!
                </h3> */}
                {bonusData?.description && (
                  <p
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: bonusData?.description,
                    }}
                  />
                )}
              </div>
              <div className="mt_autom_mov bons_sec">
                <h5>*{translation.extraBonus.star_match}</h5>

                <h2 className="fw_24">{translation.extraBonus.good_luck}</h2>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Bonus;
