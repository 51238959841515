/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from "react";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useTranslation from "../../hooks/Translation";

type props = { scrollPosition: ScrollPosition };
const ChangeCountry = ({ scrollPosition }: props) => {
  const translation = useTranslation() as any;
  const [england, setEngland] = useState(true);
  return (
    <div className="editprofile">
      <h4>{translation.pridictionList.country}</h4>
      <div className="acc_Contnr">
        <div className="country">
          <p>{translation.pridictionList.selectcrry}</p>
          <div className="seltcnty">
            <div className="option" onClick={() => setEngland(true)}>
              <div className="opleft">
                <figure>
                  <LazyLoadImage
                    src="/static/images/england.png"
                    alt=""
                    effect="blur"
                    height="100%"
                    width="100%"
                    scrollPosition={scrollPosition}
                  />
                </figure>
              </div>
              <div className="opRyt">
                <h3>English</h3>
                <h4>Hello</h4>
              </div>
              {england ? (
                <figure className="selectick">
                  <LazyLoadImage
                    src="/static/images/countrytick.svg"
                    alt=""
                    effect="blur"
                    height="100%"
                    width="100%"
                    scrollPosition={scrollPosition}
                  />
                </figure>
              ) : null}
            </div>
            {/* <div className="option" onClick={() => setEngland(false)}>
              <div className="opleft">
                <figure>
                 
                    <LazyLoadImage
                   src="/static/images/french.png" alt="" 
                    effect="blur"
                    height="100%"
                    width="100%"
                    scrollPosition={scrollPosition}
                  />
                </figure>
              </div>
              <div className="opRyt">
                <h3>Arabic</h3>
                <h4>مرحبًا</h4>
              </div>
              {!england ? (
                <figure className="selectick">
       
                      <LazyLoadImage
                     src="/static/images/countrytick.svg" alt="" 
                    effect="blur"
                    height="100%"
                    width="100%"
                    scrollPosition={scrollPosition}
                  />
                </figure>
              ) : null}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default trackWindowScroll(ChangeCountry);
