/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box, Modal, Tab, Tabs, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Loader, MatchCard } from "../../components";
import useTranslation from "../../hooks/Translation";

import { Season, Tournaments } from "../../types/general";
import {
  useLazyGetSeasonDetailsQuery,
  useLazyGetTournamentDetailsQuery,
} from "../../service/tournaments";
import { errorToast } from "../../helpers/ShowToast";

type TabLabel = "upcoming" | "history";
interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 2,
  outline: "none",
  borderRadius: 3,
};
type MatchCardsTabsProps = {
  setNewCls: Dispatch<SetStateAction<boolean>>;
  setSelectedMatchId: Dispatch<SetStateAction<string>>;
  setTorunament: Dispatch<SetStateAction<string>>;
  seasonData: Season[];
  tourneyData: Tournaments[];
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
  setSeasonalData: Dispatch<SetStateAction<any>>;
  path?: string;

  setMatchDetail: Dispatch<SetStateAction<any>>;
  selectedMatchId?: string;
  setCardFirstDate?: Dispatch<SetStateAction<string | null | undefined>>;
  setSelectedCategoryId: Dispatch<SetStateAction<string>>;
  selectedCategoryId: string;
  setTourneyData?: any;
  setSeasonData?: any;
  userEntries?: any;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setCountDown: Dispatch<SetStateAction<number>>;
  countDown: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
};

const MatchCardTabs = ({
  loading,
  setLoading,
  seasonData,
  setSeasonData,
  setNewCls,
  setSelectedMatchId,
  tourneyData,
  setTourneyData,
  activeTab,
  setActiveTab,
  setTorunament,
  setSeasonalData,
  path,
  setMatchDetail,
  selectedMatchId,
  selectedCategoryId,
  setCardFirstDate,
  setSelectedCategoryId,
  userEntries,
  setCountDown,
  countDown,
  setOpen,
  open,
}: MatchCardsTabsProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;

  // const [loading, setLoading] = useState<boolean>(false);

  const tabLabels = [
    { label: "Upcoming", type: "upcoming" },
    { label: "History", type: "history" },
  ];

  const [getSeason] = useLazyGetSeasonDetailsQuery();
  const [getTourneyDetailData] = useLazyGetTournamentDetailsQuery();
  // const handleClose = () => setOpen(false);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countDown, setCountDown]);

  useEffect(() => {
    if (countDown === 0) {
      setOpen(false);
      window.location.reload();
    }
  }, [countDown, setOpen]);

  const getSeasonalDetail = async (_id: any) => {
    try {
      setLoading(true);
      const response = await getSeason({ banner_id: _id }).unwrap();
      setLoading(false);

      if (response?.statusCode === 200) {
        setSelectedMatchId(_id);
        // setSelectedCategoryId(categoryId);
        setSeasonalData(response?.data);
      }
    } catch (error: any) {
      setLoading(false);
      // errorToast(error?.data?.message || "");
    }
  };

  const getTournamentDetails = async (
    id: string,
    key: boolean | undefined,
    fixtureId: string | null | undefined,
    categoryId: string
  ) => {
    try {
      const response = await getTourneyDetailData({
        type: key ? "live" : activeTab,
        id,
        fixtureId: key ? fixtureId : null,
        categoryId,
      }).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        setSelectedMatchId(id);
        setSelectedCategoryId(categoryId);
        setMatchDetail(response?.data);
      } else if (response.statusCode === 503) {
        errorToast(translation.toastMessages.service_unavailable);
      }
    } catch (error: any) {
      if (error?.originalStatus === 429) {
        setCountDown(59);
        setOpen(true);
      }
      setLoading(false);

      // console.log(error);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabLabel) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (activeTab) {
      setTourneyData([]);
      setSeasonData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <Box sx={{ width: "100%" }} className="tannn">
      {/* <Loader isLoad={loading} /> */}
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          className={path === "/tournamentdetail" ? "tabsssss" : ""}
        >
          {tabLabels.map((tab, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tab key={index} label={tab.label} value={tab.type} />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={activeTab} index="upcoming">
        {/* Tournaments */}
        <div id="tabComponent" className="matchmain" style={{ width: "100%" }}>
          {tourneyData.length
            ? tourneyData?.map((item) => {
                return (
                  <div
                    key={item._id}
                    onClick={() => {
                      setTorunament("Tournament");
                    }}
                  >
                    <MatchCard
                      onSelectCard={(_id: any, categoryId: any) => {
                        setSelectedMatchId(_id);
                        setSelectedCategoryId(categoryId);
                      }}
                      id={item._id}
                      image={item?.image}
                      fixtures={item?.fixtures}
                      title={item.name}
                      totalTokens={item?.totalTokens}
                      setNewCls={setNewCls}
                      totalUsers={item?.totalUsers}
                      isLive={item?.isLive}
                      getTournamentDetails={getTournamentDetails}
                      tokenCat={item}
                      cutoff=""
                      categoryId={item?.categoryId}
                      selectedMatchId={selectedMatchId}
                      selectedCategoryId={selectedCategoryId}
                      setCardFirstDate={setCardFirstDate}
                      userEntries={userEntries}
                    />
                  </div>
                );
              })
            : null}
        </div>
        {/* Seasonal card */}
        <div className="matchmain" style={{ width: "100%" }}>
          {seasonData.length
            ? seasonData?.map((item) => {
                return (
                  <div
                    key={item._id}
                    onClick={() => {
                      setTorunament("seasonal");
                      getSeasonalDetail(item?._id);
                    }}
                  >
                    <MatchCard
                      onSelectCard={({ _id }: any) => {
                        setSelectedMatchId(_id);
                      }}
                      cutoff=""
                      id={item._id}
                      image={item?.image}
                      type={item?.type}
                      tokenFee={item?.tokenFee}
                      season={item?.title}
                      startDate={item?.startDate}
                      startTime={item?.startTime}
                      endDate={item?.endDate}
                      users={item?.users || undefined}
                      setNewCls={setNewCls}
                      isSubscribed={item?.isSubscribed}
                      seasonCat={item}
                      selectedMatchId={selectedMatchId}
                    />
                  </div>
                );
              })
            : null}
        </div>
      </TabPanel>
      <TabPanel value={activeTab} index="history">
        {/* Tournaments */}
        <div id="tabComponent" className="matchmain" style={{ width: "100%" }}>
          {tourneyData.length
            ? tourneyData?.map((item) => {
                return (
                  <div
                    key={item._id}
                    onClick={() => {
                      setTorunament("Tournament");
                    }}
                  >
                    <MatchCard
                      onSelectCard={({ _id, categoryId }: any) => {
                        setSelectedMatchId(_id);
                        setSelectedCategoryId(categoryId);
                      }}
                      id={item._id}
                      image={item?.image}
                      fixtures={item?.fixtures}
                      title={item.name}
                      totalTokens={item?.totalTokens}
                      setNewCls={setNewCls}
                      totalUsers={item?.totalUsers}
                      getTournamentDetails={getTournamentDetails}
                      tokenCat={item}
                      cutoff=""
                      categoryId={item?.categoryId}
                      selectedMatchId={selectedMatchId}
                      setCardFirstDate={setCardFirstDate}
                      selectedCategoryId={selectedCategoryId}
                      userEntries={userEntries}
                      isFinished={item?.isFinished}
                      userNames={item?.userNames}
                    />
                  </div>
                );
              })
            : null}
        </div>
        {/* Seasonal card */}
        <div className="matchmain" style={{ width: "100%" }}>
          {seasonData.length
            ? seasonData?.map((item) => {
                return (
                  <div
                    key={item._id}
                    onClick={() => {
                      setTorunament("seasonal");
                      getSeasonalDetail(item?._id);
                    }}
                  >
                    <MatchCard
                      onSelectCard={({ _id }: any) => {
                        setSelectedMatchId(_id);
                      }}
                      cutoff=""
                      id={item._id}
                      image={item?.image}
                      type={item?.type}
                      tokenFee={item?.tokenFee}
                      season={item?.title}
                      startDate={item?.startDate}
                      startTime={item?.startTime}
                      endDate={item?.endDate}
                      users={item?.users || undefined}
                      setNewCls={setNewCls}
                      isSubscribed={item?.isSubscribed}
                      seasonCat={item}
                      selectedMatchId={selectedMatchId}
                    />
                  </div>
                );
              })
            : null}
        </div>
      </TabPanel>
      <div>
        {!loading && seasonData?.length === 0 && tourneyData?.length === 0 ? (
          <div className="no_data  web_nodata">
            {" "}
            You haven’t joined any tournament yet. Hurry up, Join Now!!
          </div>
        ) : null}
      </div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop
      >
        <Box sx={style} className=" prmodl2">
          <h2 style={{ textAlign: "center", color: "#ecac35" }}>Loading...</h2>
          <p
            style={{
              textAlign: "center",
              paddingTop: "6px",
              color: "#4c4a4a",
            }}
          >
            Please wait for{" "}
            {countDown < 10 ? `00: 0${countDown}` : `00: ${countDown}`}s
          </p>
        </Box>
      </Modal>
    </Box>
  );
};
export default MatchCardTabs;
