import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import Layout from "../../layout";
import {
  About,
  ChangeCountry,
  Contact,
  Deposit,
  EditProfile,
  Faqs,
  Notification,
  ProfileSidebar,
  RightDrawer,
  Transactions,
  Withdraw,
} from "../../features";
import "./UserProfile.scss";

import { SidebarMenu } from "../../components";

const UserProfile = () => {
  const [activeCase, setActiveCase] = useState<number>(1);
  const [newCls, setNewCls] = useState<boolean>(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [ActiveDrawer, SetActiveDrawer] = useState(1);
  const [ChangePass, setChangePass] = useState(false);

  const { state } = useLocation();

  useEffect(() => {
    if (state === "contactUs") {
      setActiveCase(4);
    } else if (state === "about_us") {
      setActiveCase(5);
    } else if (state === "faq") {
      setActiveCase(6);
    }
  }, [state]);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setMenuVisible(open);
    };

  const handleActiveCase = () => {
    switch (activeCase) {
      case 1:
        return (
          <EditProfile ChangePass={ChangePass} setChangePass={setChangePass} />
        );
      case 2:
        return <Notification />;
      case 3:
        return <ChangeCountry />;
      case 4:
        return <Contact />;
      case 5:
        return <About />;
      case 6:
        return <Faqs />;
      case 8:
        return <Transactions />;
      case 9:
        return <Deposit />;
      case 10:
        return <Withdraw />;
      default:
        return (
          <EditProfile ChangePass={ChangePass} setChangePass={setChangePass} />
        );
    }
  };
  useEffect(() => {
    handleActiveCase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCase]);

  return (
    <Layout setActiveCase={setActiveCase}>
      <div className=" nv_spc  tornmain prfl_wrp   ">
        <div className="container">
          <div className="profilemain">
            <div className="proleft">
              <div
                // className={!newCls ? "" : ""}
                onClick={() => setMenuVisible(true)}
              >
                <i className="fa-solid fa-arrow-up-long bck_btn bck_btn_prfl" />
              </div>

              <ProfileSidebar
                setActiveCase={setActiveCase}
                activeTab={activeCase}
                setNewCls={setNewCls}
                setChangePass={setChangePass}
              />
            </div>
            {/* proryt_mob */}
            <div
              className={
                newCls ? "proryt proryt_mobww2" : "proryt proryt_mobww"
              }
            >
              <div onClick={() => setNewCls(false)}>
                <i className="fa-solid fa-arrow-up-long bck_btn bck_btn_prfl" />
              </div>
              {handleActiveCase()}
            </div>
          </div>
        </div>
      </div>
      <SidebarMenu
        toggleDrawer={toggleDrawer}
        menuVisible={menuVisible}
        setMenuVisible={setMenuVisible}
        SetActiveDrawer={SetActiveDrawer}
        setSidebarVisible={setSidebarVisible}
      />
      <RightDrawer
        sidebarVisible={sidebarVisible}
        setSidebarVisible={setSidebarVisible}
        ActiveDrawer={ActiveDrawer}
        predictions=""
        userName=""
        userImage=""
      />
    </Layout>
  );
};

export default UserProfile;
