import React, { useEffect, useState } from "react";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import { useLazyGetHomeQuery } from "../../service/tournaments";
import { Seasons } from "../../types/general";
import { errorToast } from "../../helpers/ShowToast";
import { Loader } from "../../components";

const CustomPrevArrow = (props: any) => {
  const { onClick } = props;

  return (
    <div className="prevarrow_home" onClick={onClick}>
      <ChevronLeftIcon />
    </div>
  );
};

const CustomNextArrow = (props: any) => {
  const { onClick } = props;

  return (
    <div className="nextarrow_home" onClick={onClick}>
      <ChevronRightIcon />
    </div>
  );
};
const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  // afterChange: handleSlideChange,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
};
const PerfectPrediction = () => {
  const navigate = useNavigate();
  const staticImage = "static/images/perfect2.png";
  const [getHomeData] = useLazyGetHomeQuery();
  const [seasonalData, setSeasonalData] = useState<Seasons>();
  const [extraBonus, setExtraBonus] = useState<Seasons>();
  const [daysDifference, setDaysDifference] = useState(0);
  const [hoursDifference, setHoursDifference] = useState(0);
  const [minutesDifference, setMinutesDifference] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [loading, setLoading] = useState(false);
  // console.log(hoursDifference, "hoursDifference");
  // console.log(daysDifference, "daysDifference");

  // console.log(minutesDifference, "minutesDifference");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const videos = [
    {
      id: 1,
      url: "/static/images/video1.mp4",
    },
    {
      id: 2,
      url: "/static/images/video2.mp4",
    },
    {
      id: 3,
      url: "/static/images/video3.mp4",
    },
    {
      id: 4,
      url: "/static/images/video4.mp4",
    },
    {
      id: 5,
      url: "/static/images/video5.mp4",
    },
    {
      id: 6,
      url: "/static/images/video6.mp4",
    },
    {
      id: 7,
      url: "/static/images/video7.mp4",
    },
    {
      id: 8,
      url: "/static/images/video8.mp4",
    },
    {
      id: 9,
      url: "/static/images/video9.mp4",
    },
  ];
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getHomeData({}).unwrap();
      setLoading(false);

      if (response?.statusCode === 200) {
        setSeasonalData(response?.data?.seasonBanner);
        setExtraBonus(response?.data?.banner);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   if (seasonalData) {
  //     const startDateTime = moment(
  //       `${seasonalData?.startDate}T${seasonalData?.startTime}:00.037Z`
  //     );
  //     const currentDateTime = moment().format("YYYY-MM-DD HH:mm");

  //     setCurrentDate(currentDateTime);
  //     setStartDate(seasonalData?.startDate);
  //     const daysDiff = startDateTime.diff(currentDateTime, "days");
  //     const hoursDiff = startDateTime.diff(currentDateTime, "hours");
  //     const minutesDiff = startDateTime.diff(currentDateTime, "minutes");

  //     const hoursRemaining = ((hoursDiff % 24) + 24) % 24;
  //     setDaysDifference(daysDiff);
  //     setHoursDifference(hoursDiff);
  //     setMinutesDifference(minutesDiff);
  //   }
  // }, [seasonalData]);

  useEffect(
    () => {
      if (seasonalData) {
        const startDateTime = moment(
          `${seasonalData?.startDate}T${seasonalData?.startTime}:00.037Z`
        );
        console.log(startDateTime, "startDateTime");

        const currentDateTime = moment().format("YYYY-MM-DD HH:mm");
        console.log(currentDateTime, "currentDateTime");

        const daysDiff = startDateTime.diff(currentDateTime, "days");
        const hoursDiff = startDateTime.diff(currentDateTime, "hours");
        // const hoursRemaining = ((hoursDiff % 24) + 24) % 24;
        const minutesDiff = startDateTime.diff(currentDateTime, "minutes");
        setDaysDifference(daysDiff);

        if (minutesDiff > 59) {
          // Calculate hours and remaining minutes
          const hoursRemaining = Math.floor(minutesDiff / 60);
          const remainingMinutes = minutesDiff % 60;
          setHoursDifference(hoursRemaining);
          setMinutesDifference(remainingMinutes);
        } else {
          // If less than 59 minutes, set minutes only
          setHoursDifference(0);
          setMinutesDifference(minutesDiff);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [seasonalData]
  );
  return (
    <>
      {seasonalData || extraBonus ? (
        <section className="all_sc prfct_sc">
          <Loader isLoad={loading} />
          <div className="container prfct_ctn">
            {seasonalData ? (
              <div
                style={{ backgroundImage: `url(${seasonalData?.image})` }}
                className="prfct_inr dv_lft"
                onClick={() => navigate(`/topseasons/${seasonalData?._id}`)}
              >
                <div className="txt">
                  <h2 className="fw_33 top_stick">{seasonalData?.title} </h2>
                  {seasonalData?.bannerDescription && (
                    <p
                      className="blk_span"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: seasonalData?.bannerDescription,
                      }}
                    />
                  )}
                  {/* {hoursDifference <= 0 &&
                  daysDifference <= 0 &&
                  minutesDifference <= 0 ? (
                    <p className="text_danger italic  reg_text bottom_stick reg_txt_sz">
                      Registration Closed
                    </p>
                  ) : (
                    <p className="text_danger italic  reg_text bottom_stick reg_txt_sz">
                      Registration closes in{" "}
                      {daysDifference > 0 ? `${daysDifference} days` : ""}
                      {hoursDifference > 0 && daysDifference <= 0
                        ? `${hoursDifference} hours`
                        : ""}
                      {hoursDifference <= 0 && daysDifference <= 0
                        ? `${minutesDifference} minutes`
                        : ""}
                    </p>
                  )} */}
                  {hoursDifference <= 0 &&
                  daysDifference <= 0 &&
                  minutesDifference <= 0 ? (
                    <p className="text_danger italic reg_text bottom_stick reg_txt_sz">
                      Registration Closed
                    </p>
                  ) : (
                    <p className="text_danger italic reg_text bottom_stick reg_txt_sz">
                      Registration closes in{" "}
                      {daysDifference > 0
                        ? `${daysDifference} day${
                            daysDifference > 1 ? "s" : ""
                          }`
                        : ""}
                      {hoursDifference > 0 && daysDifference <= 0
                        ? `${hoursDifference} ${
                            hoursDifference === 1 ? "hour" : "hours"
                          } ${minutesDifference} minutes`
                        : ""}
                      {hoursDifference <= 0 && daysDifference <= 0
                        ? `${minutesDifference} minute${
                            minutesDifference > 1 ? "s" : ""
                          }`
                        : ""}
                      {/* {hoursDifference <= 0 && daysDifference <= 0
                      ? `${minutesDifference} minutes`
                      : ""} */}
                    </p>
                  )}
                </div>
              </div>
            ) : null}
            {extraBonus ? (
              <div
                className="prfct_inr dv_ryt"
                onClick={() => navigate(`/bonus/${extraBonus?._id}`)}
                style={{
                  backgroundImage: extraBonus?.image
                    ? `url(${extraBonus?.image})`
                    : `url ${staticImage}`,
                }}
              >
                <div className="txt">
                  <h2 className="fw_33 bonus_txt top_stick">
                    {extraBonus?.title}
                  </h2>

                  {extraBonus?.bannerDescription && (
                    <p
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: extraBonus.bannerDescription,
                      }}
                    />
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </section>
      ) : null}

      <section className="all_sc vdo_sc">
        <div className="container vdo_cnt">
          <div className="sb_hd">
            <h2>{translation.homepage.working}</h2>
          </div>
          <Slider className="home_slider" {...settings}>
            {videos?.map((item) => {
              return (
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="hm_vd"
                  preload="yes"
                  key={item?.id}
                >
                  <source src={item?.url} type="video/mp4" />
                </video>
              );
            })}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default PerfectPrediction;
