/* eslint-disable import/no-extraneous-dependencies */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import { useAppDispatch } from "../../hooks/Store";
import {
  useLazyGetProfileQuery,
  useLazyGetTeamsQuery,
  usePostLogoutMutation,
  usePostProfileMutation,
} from "../../service/Auth";
import { setCredentials } from "../../reducers/authSlice";

import useAuth from "../../hooks/UseAuth";
import {
  STORAGE_KEYS,
  getFromStorage,
  removeFromStorage,
} from "../../helpers/Storage";
import { errorToast, successToast } from "../../helpers/ShowToast";
import { Loader } from "../../components";
import { Teams } from "../../types/User";
import { generateSEKAndHash } from "../../utils/crypto";

type ProfileProps = {
  activeTab: number;
  setActiveCase: Dispatch<SetStateAction<number>>;
  setNewCls: Dispatch<SetStateAction<boolean>>;
  setChangePass: Dispatch<SetStateAction<boolean>>;
  scrollPosition: ScrollPosition;
};

const ProfileSidebar = ({
  setActiveCase,
  setNewCls,
  activeTab,
  setChangePass,
  scrollPosition,
}: ProfileProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const [LogoutMutation, LogoutMutationData] = usePostLogoutMutation();
  const [PostProfileMutation] = usePostProfileMutation();
  const [teams, setTeams] = useState<Teams[]>();
  const [favTeam, setFavTeam] = useState("");
  const [GetProfile] = useLazyGetProfileQuery();
  const [getTeams] = useLazyGetTeamsQuery();
  const [open, setOpen] = useState(false);
  const [openToChangeTeam, setOpenToChangeTeam] = useState(false);
  const handleClose = () => setOpen(false);
  const handleCloseToChangeTeam = () => setOpenToChangeTeam(false);
  const dispatch = useAppDispatch();
  const user = useAuth();

  const token = getFromStorage(STORAGE_KEYS.token);

  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 6,
    outline: "none",
    borderRadius: 3,
  };
  const sideTabs = [
    {
      id: 1,
      tab: translation.pridictionList.editprofile,
    },
    {
      id: 2,
      tab: translation.pridictionList.notification,
    },
    {
      id: 8,
      tab: translation.pridictionList.transactions,
    },
    {
      id: 3,
      tab: translation.pridictionList.country,
    },
    {
      id: 4,
      tab: translation.pridictionList.contct,
    },
    {
      id: 5,
      tab: translation.pridictionList.about,
    },
    {
      id: 6,
      tab: translation.pridictionList.faq,
    },
    {
      id: 7,
      tab: translation.pridictionList.logout,
    },
  ];

  const fetchProfileData = async () => {
    try {
      const response = await GetProfile({}).unwrap();
      if (response?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: response?.data || null,
          })
        );
      } else if (response?.statusCode === 503) {
        errorToast(translation.toastMessages.service_unavailable);
      }
    } catch (error: any) {
      if (error?.data?.message) {
        errorToast(error?.data?.message || "");
      } else {
        errorToast(translation.toastMessages.no_internet);
      }
    }
  };

  const handleLogout = async () => {
    const data = {
      deviceType: "WEB",
      appkey: new Date().toISOString(),
    };
    const body = generateSEKAndHash(data);
    if (body) {
      try {
        const response = await LogoutMutation(body).unwrap();
        if (response?.statusCode === 200) {
          successToast(response?.message || "");

          dispatch(
            setCredentials({
              token: null,
              user: null,
            })
          );
          removeFromStorage(STORAGE_KEYS.token);
          removeFromStorage(STORAGE_KEYS.userData);

          navigate("/login", { replace: true });
        }
      } catch (error: any) {
        if (error?.data?.message) {
          errorToast(error?.data?.message || "");
        } else {
          errorToast("No internet connection");
        }
      }
    }
  };
  const handleSwitch = (id: number) => {
    if (id === 7) {
      setOpen(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setActiveCase(id);
      setChangePass(false);
      setNewCls(true);
    }
  };

  // for teams select
  const fetchData = async () => {
    try {
      const res = await getTeams({}).unwrap();
      if (res?.statusCode === 200) {
        setTeams(res?.data);
      } else if (res.statusCode === 503) {
        errorToast(translation.toastMessages.service_unavailable);
      }
    } catch (error: any) {
      // errorToast(error?.data?.message || "");
    }
  };

  const forChangeTeam = async () => {
    const data = {
      favouriteTeam: favTeam || "",
      deviceType: "WEB",
      appkey: new Date().toISOString(),
    };
    const body = generateSEKAndHash(data);

    if (body) {
      try {
        const response = await PostProfileMutation(body).unwrap();

        if (response?.statusCode === 200) {
          fetchProfileData();
          setOpenToChangeTeam(false);
          successToast(response?.message || "");
        } else if (response?.statusCode === 503) {
          errorToast(translation.toastMessages.service_unavailable);
        }
      } catch (error) {
        // errorToast(error?.data?.message || "");
      }
    }
  };

  const handleChange = (
    event: React.ChangeEvent<object>,
    value: Teams | null
  ) => {
    if (value !== null) {
      setFavTeam(value?._id);
    } else {
      setFavTeam(""); // Handle the case where no option is selected
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="sidemain">
      <Loader isLoad={LogoutMutationData.isLoading} />
      <h1 className="hdng">{translation.pridictionList.myprofile}</h1>
      <div className="userinfo">
        <div className="for_mob_prfl_ttl ">
          <h2>{translation.pridictionList.myprofile}</h2>
        </div>
        <div className="uinleft">
          <figure>
            <LazyLoadImage
              src={
                user && user?.image
                  ? user?.image
                  : "/static/images/user_placeholder.png"
              }
              alt=""
              effect="blur"
              height="100%"
              width="100%"
              scrollPosition={scrollPosition}
            />
          </figure>
        </div>
        <div className="uinRyt">
          <h2>{user ? user?.userName : ""}</h2>
          <div
            style={{ cursor: "pointer" }}
            className="teamsupport"
            onClick={() => setOpenToChangeTeam(true)}
          >
            <figure>
              <LazyLoadImage
                src={user ? user?.favouriteTeam?.logo : ""}
                alt=""
                effect="blur"
                height="100%"
                width="100%"
                scrollPosition={scrollPosition}
              />
            </figure>
            <h5>{user ? user?.favouriteTeam?.name : "No data found"}</h5>
          </div>
          <div onClick={() => navigate("/tokenhistory")}>
            <h3>
              {user ? user?.tokens : 0} {translation.Globals.tokens}{" "}
              <span>{translation.pridictionList.balance}</span>
            </h3>
          </div>
          <div className="btn_sec ">
            <button
              type="submit"
              onClick={() => {
                setNewCls(true);
                handleSwitch(9);
              }}
            >
              {translation.pridictionList.deposit}
            </button>
            <button
              type="submit"
              onClick={() => {
                setNewCls(true);
                handleSwitch(10);
              }}
            >
              {translation.pridictionList.withdraw}
            </button>
          </div>
        </div>
      </div>
      <div className="winrate">
        <div className="win_image">
          <p className="coin_text">{user ? user?.silver : 0}</p>

          <LazyLoadImage
            src="/static/images/silver.svg"
            alt=""
            effect="blur"
            height="100%"
            width="100%"
            scrollPosition={scrollPosition}
          />
        </div>
        <div className="win_image">
          <p className="coin_text">{user ? user?.gold : 0}</p>

          <LazyLoadImage
            src="/static/images/gold.svg"
            alt=""
            effect="blur"
            height="100%"
            width="100%"
            scrollPosition={scrollPosition}
          />
        </div>
        <div className="win_image">
          <p className="coin_text">{user ? user?.bronze : 0}</p>

          <LazyLoadImage
            src="/static/images/bronze.svg"
            alt=""
            effect="blur"
            height="100%"
            width="100%"
            scrollPosition={scrollPosition}
          />
        </div>
      </div>
      <div className="side">
        {sideTabs.map((item) => {
          return (
            <div
              className={activeTab === item?.id ? "tabs tabActv" : "tabActv"}
              key={item?.id}
              onClick={() => {
                setNewCls(true);
                handleSwitch(item?.id);
              }}
            >
              <h4>{item?.tab}</h4>
            </div>
          );
        })}
      </div>

      <Modal
        open={openToChangeTeam}
        onClose={handleCloseToChangeTeam}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {" "}
          <h2
            style={{ fontSize: "16px", fontWeight: "400", textAlign: "center" }}
          >
            Change Favorite Team
          </h2>
          <Autocomplete
            className="prfl_autocmplt"
            disablePortal
            id="combo-box-demo"
            onChange={handleChange}
            options={teams?.length ? teams : []}
            getOptionLabel={(option) => option?.name}
            sx={{ width: "auto" }}
            renderInput={(params) => <TextField {...params} label="Teams" />}
          />
          <div
            className="btn_dv"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "10px",
            }}
          >
            <button
              className="btn"
              type="button"
              onClick={() => forChangeTeam()}
              style={{ width: "49%", cursor: "pointer", padding: "10px" }}
            >
              Change
            </button>
            <button
              className="btn"
              type="button"
              style={{ width: "49%", cursor: "pointer", padding: "10px" }}
              onClick={() => setOpenToChangeTeam(false)}
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {" "}
          <h2
            style={{ fontSize: "16px", fontWeight: "400", textAlign: "center" }}
          >
            {translation.pridictionList.sure_logout}
          </h2>
          <div
            className="btn_dv"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
            }}
          >
            <button
              className="btn"
              type="button"
              onClick={() => handleLogout()}
              style={{ width: "49%", cursor: "pointer", padding: "10px" }}
            >
              {translation.Globals.yes}
            </button>
            <button
              className="btn"
              type="button"
              style={{ width: "49%", cursor: "pointer", padding: "10px" }}
              onClick={() => setOpen(false)}
            >
              {translation.Globals.no}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default trackWindowScroll(ProfileSidebar);
