/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-underscore-dangle */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import moment from "moment";
import { Box, Modal } from "@mui/material";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./Components.scss";
import useTranslation from "../hooks/Translation";
import { Fixtures, Profile, Season, Tournaments } from "../types/general";
import useAuth from "../hooks/UseAuth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 2,
  outline: "none",
  borderRadius: 3,
};

type MatchCardtype = {
  id: any;
  image?: string;
  title?: string;
  tokenFee?: number;
  token?: any;
  fixtures?: Fixtures[];
  startDate?: string;
  startTime?: string;
  endDate?: string;
  users?: Profile[] | undefined;
  totalEnteries?: number;
  season?: string;
  totalTokens?: number;
  tourneyType?: string;
  totalUsers?: number;
  type?: string;
  onSelectCard: (id: string, categoryId: string) => void;
  setNewCls: Dispatch<SetStateAction<boolean>>;
  isSubscribed?: boolean;
  isLive?: boolean;
  tokenCat?: Tournaments | undefined;
  seasonCat?: Season | undefined;
  cutoff: string;
  categoryId?: any;
  selectedMatchId?: string;
  setCardFirstDate?: Dispatch<SetStateAction<string | null | undefined>>;
  selectedCategoryId?: string;
  userEntries?: any;
  isFinished?: boolean;
  userNames?: any;
  setLoad?: any;
  load?: any;
  scrollPosition: ScrollPosition;

  getTournamentDetails?: (
    arg: string,
    key: boolean | undefined,
    fixtureId: string | null | undefined,
    categoryId: string
  ) => Promise<void> | undefined;
};
const MatchCard = ({
  id,
  categoryId,
  image,
  title,
  startDate,
  startTime,
  endDate,
  users,
  totalTokens,
  tokenFee,
  token,
  totalEnteries,
  fixtures,
  totalUsers,
  season,
  tourneyType,
  setNewCls,
  onSelectCard,
  type,
  isSubscribed,
  isLive,
  getTournamentDetails,
  tokenCat,
  seasonCat,
  cutoff,
  selectedMatchId,
  setCardFirstDate,
  selectedCategoryId,
  userEntries,
  isFinished,
  userNames,
  setLoad,
  load,
  scrollPosition,
}: MatchCardtype) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const user = useAuth();
  const path = window.location.pathname;

  const [open, setOpen] = React.useState(false);
  const [matchTimer, setMatchTimer] = useState<string>("");
  const [registrationtTimer, setRegistrationTimer] = useState<string>("");
  const [firstDate, setFirstDate] = useState<string>();
  const [sortedTokens, setSortedTokens] = useState([]);
  // console.log(firstDate, "firstDate");

  const historyUserEntries = userNames?.length
    ? userNames?.filter(
        (item: { userName: string }) =>
          item?.userName?.split("_")[0] === user?.userName
      )
    : null;

  const handleOpen = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleCardClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setNewCls(true);
    onSelectCard(id, categoryId?._id);
    if (setCardFirstDate) {
      setCardFirstDate(firstDate);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (firstDate) {
      const updateTimeLeft = () => {
        const currentDateTime = moment(); // Current date and time
        const targetDateTime = moment(firstDate); // Target date and time

        const timeDiff = targetDateTime.diff(currentDateTime); // Difference in milliseconds
        if (timeDiff) {
          const duration = moment.duration(timeDiff);
          const days = String(duration.days()).padStart(2, "0");
          const hours = String(duration.hours()).padStart(2, "0");
          const minutes = String(duration.minutes()).padStart(2, "0");
          const seconds = String(duration.seconds()).padStart(2, "0");
          const remainingDaysNumber = parseInt(days, 10);

          if (remainingDaysNumber >= 1) {
            setMatchTimer(
              `${days} ${translation.tourneyCreated.days_to_start} `
            );
            setRegistrationTimer(`${days} ${translation.tourneyCreated.days} `);
          } else {
            if (parseInt(seconds, 10) > -1) {
              setMatchTimer(`${hours}h: ${minutes}m: ${seconds}s to start`);
            }

            if (
              path === "/" &&
              remainingDaysNumber === 0 &&
              parseInt(hours, 10) === 0 &&
              parseInt(minutes, 10) === 0 &&
              parseInt(seconds, 10) === 0
              // setLoad
            ) {
              setTimeout(() => {
                setLoad(!load);
              }, 500);
            }

            const totalMinutes =
              parseInt(hours, 10) * 60 + parseInt(minutes, 10);
            const cutoffTime = parseInt(cutoff, 10);
            const remainingMinutes = totalMinutes - cutoffTime;
            const remainingHours = Math.floor(remainingMinutes / 60);
            const remainingMinutesInHour = remainingMinutes % 60;
            // const remainingSeconds = parseInt(seconds, 10);
            if (remainingHours < 0 && remainingMinutesInHour < 0) {
              setRegistrationTimer("");
            } else {
              setRegistrationTimer(
                `${remainingHours}h: ${String(remainingMinutesInHour).padStart(
                  2,
                  "0"
                )}m: ${seconds}s`
              );
            }
          }
        } else {
          clearInterval(timer);
          setMatchTimer("");
          setRegistrationTimer("closed now");
        }
      };

      timer = setInterval(updateTimeLeft, 1000);
      updateTimeLeft();
    } else {
      // console.log("No fixtures found.");
    }

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDate]);

  useEffect(() => {
    if (fixtures?.length) {
      const copiedFixtures = [...fixtures];
      const sortedFixtures = copiedFixtures.sort((a, b) => {
        const dateA = moment(a?.fixture?.date) as any;
        const dateB = moment(b?.fixture?.date) as any;
        return dateA - dateB;
      });

      const currentDate = moment();

      // Check if the current time is after the start time of the first fixture
      const isFirstMatchStarted = currentDate.isSameOrAfter(
        moment(sortedFixtures[0]?.fixture.date)
      );

      // Check if the current time is before the end time of the last fixture
      const isLastMatchNotEnded = currentDate.isBefore(
        moment(sortedFixtures[sortedFixtures.length - 1]?.fixture.date)
      );

      if (isFirstMatchStarted && isLastMatchNotEnded) {
        // console.log()
      } else {
        setFirstDate(moment(sortedFixtures[0]?.fixture.date).format());
      }
    }
  }, [fixtures]);

  useEffect(() => {
    if (token) {
      const sortedTokenCategories = [...token].sort(
        (a, b) => parseInt(a.tokens, 10) - parseInt(b.tokens, 10)
      );
      setSortedTokens(sortedTokenCategories as any);
    }
  }, [token]);

  return (
    <div
      className={
        selectedMatchId === id && selectedCategoryId === categoryId?._id
          ? "highlighted_card mtch_crd_inr"
          : "mtch_crd_inr"
      }
    >
      <div
        onClick={() => {
          handleCardClick();
          if (getTournamentDetails) {
            getTournamentDetails(
              id,
              isLive,
              fixtures?.length ? fixtures[0]?.fixture_id : null,
              categoryId?._id
            );
          }
        }}
      >
        {type === "SEASON_BANNER" ? <h3>{season}</h3> : <h3>{tourneyType}</h3>}

        <div className="crd_top">
          <div className="crd_lft">
            <figure>
              <LazyLoadImage
                alt=""
                src={image === "" ? "/static/images/team1.png" : image}
                effect="blur"
                scrollPosition={scrollPosition}
              />
            </figure>

            <div className="crd_mdl">
              {type === "SEASON_BANNER" ? null : <h5>{categoryId?.name}</h5>}
              {type === "SEASON_BANNER" ? (
                <p>
                  {moment(`${startDate}T${startTime}:00.037Z`).format(
                    "dddd, D MMMM, HH:mm"
                  )}
                </p>
              ) : (
                <p>{moment(firstDate).format("dddd Do MMM HH:mm")}</p>
              )}
              {type === "SEASON_BANNER" ? (
                <h2>{translation.homepage.selection}</h2>
              ) : (
                <h2>{title}</h2>
              )}
              {path === "/" ? (
                <div>
                  {" "}
                  {firstDate && (
                    <div className="timer">
                      <p>{matchTimer}</p>
                    </div>
                  )}
                  {firstDate && registrationtTimer ? (
                    <p className="reg_text">
                      {translation.homepage.reg_close_in} {registrationtTimer}
                    </p>
                  ) : (
                    <p className="reg_text">Registration Closed</p>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          {path !== "/" ? (
            <div className="mytor">
              {users?.length && tokenFee && (
                <h3>
                  {tokenFee * users?.length - tokenFee * users?.length * 0.1}{" "}
                  {translation.mytourney.token}
                </h3>
              )}
              {totalTokens && (
                <h3>
                  {totalTokens} {translation.mytourney.token}
                </h3>
              )}
              <p>{translation.mytourney.tokenpot}</p>
            </div>
          ) : (
            <div className="crd_ryt">
              {sortedTokens?.map((val: any) => {
                return (
                  <p key={val?._id}>
                    {val?.tokens} {translation.mytourney.token}
                  </p>
                );
              })}
            </div>
          )}
        </div>
        <div className="crd_btm">
          {path !== "/" ? (
            <div className="btm_sec_crd">
              {type === "SEASON_BANNER" && (
                <h5>
                  {moment(startDate).format("D MMM")} -{" "}
                  {moment(endDate).format("D MMM")}
                </h5>
              )}

              <div className="crdbtm2">
                {type === "SEASON_BANNER" ? (
                  <h5>
                    {users?.length ? users?.length : 0}{" "}
                    {translation.tourneyCreated.total_entries}
                  </h5>
                ) : selectedMatchId === id &&
                  selectedCategoryId === categoryId?._id &&
                  isLive ? (
                  <h5 style={{ color: "#035103" }}>
                    Your best entry rank{" "}
                    {userEntries?.length ? userEntries[0]?.rank || "" : ""}/
                    {totalUsers}
                  </h5>
                ) : isFinished ? (
                  <div>
                    {historyUserEntries?.length &&
                    historyUserEntries[0]?.rank <= 3 ? (
                      <h5 style={{ color: "#035103" }}>
                        Congratulations , you finished{" "}
                        {historyUserEntries?.length
                          ? historyUserEntries[0]?.rank || ""
                          : ""}
                        /{totalUsers} and
                        <br /> won{" "}
                        {historyUserEntries?.length
                          ? historyUserEntries[0]?.tokens || "0"
                          : ""}{" "}
                        Tokens
                      </h5>
                    ) : (
                      <h5 style={{ color: "#035103" }}>
                        Hard luck , your best entry rank{" "}
                        {historyUserEntries?.length
                          ? historyUserEntries[0]?.rank || ""
                          : ""}
                        /{totalUsers}
                      </h5>
                    )}
                  </div>
                ) : (
                  <h5>
                    {" "}
                    {totalUsers} {translation.tourneyCreated.total_entries}
                  </h5>
                )}

                <div className="detail">
                  {isSubscribed ? (
                    <p className="subscribed_txt">
                      {" "}
                      {translation.tourneyCreated.subscribed}
                    </p>
                  ) : null}

                  {isLive ? (
                    <div>
                      <h4>{translation.tourneyCreated.live}</h4>
                    </div>
                  ) : null}

                  <div onClick={(e) => handleOpen(e)}>
                    <figure>
                      <LazyLoadImage
                        alt="info"
                        src="/static/images/info-circle.svg"
                        effect="blur"
                        scrollPosition={scrollPosition}
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="cd_bm_lt">
                <figure>
                  <LazyLoadImage
                    alt=""
                    src="/static/images/person.png"
                    effect="blur"
                    scrollPosition={scrollPosition}
                  />
                </figure>
                <h6>
                  {totalEnteries} {translation.tourneyCreated.total_entries}
                </h6>
              </div>
              <div className="cd_bm_rt">
                <h6>{translation.Globals.regFee}</h6>
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="prmodal">
          {tokenCat ? (
            <div className="pricedition">
              <h2>{translation.mytourney.distri}</h2>
              {path === "/tournamentdetail" ? null : (
                <div className="entryfee">
                  <div className="efleft">
                    <h4>{translation.mytourney.entry}</h4>
                  </div>
                  <div className="efRyt">
                    <h5>
                      {tokenCat?.categoryId
                        ? `${tokenCat?.categoryId?.tokens} T`
                        : "0 T"}
                    </h5>
                  </div>
                </div>
              )}

              <div className="entryfee">
                <div className="efleft">
                  <h4>{translation.mytourney.totalpot}</h4>
                </div>
                <div className="efRyt">
                  <h4>{totalTokens || ""} T</h4>
                </div>
              </div>
              <div className="listng">
                <div className="efleft">
                  <h4>No. 1</h4>
                </div>
                <div className="efRyt">
                  <h5>{totalTokens ? (totalTokens * 50) / 100 : ""} T</h5>
                </div>
              </div>
              <div className="listng">
                <div className="efleft">
                  <h4>No. 2</h4>
                </div>
                <div className="efRyt">
                  <h5>{totalTokens ? (totalTokens * 30) / 100 : ""} T</h5>
                </div>
              </div>
              <div className="listng">
                <div className="efleft">
                  <h4>No. 3</h4>
                </div>
                <div className="efRyt">
                  <h5>{totalTokens ? (totalTokens * 20) / 100 : ""} T</h5>
                </div>
              </div>
            </div>
          ) : null}
          {seasonCat ? (
            <div className="pricedition">
              <h2>{translation.mytourney.distri}</h2>
              {path === "/tournamentdetail" ? null : (
                <div className="entryfee">
                  <div className="efleft">
                    <h4>{translation.mytourney.entry}</h4>
                  </div>
                  <div className="efRyt">
                    <h5>{seasonCat?.tokenFee || ""} T</h5>

                    {/* <h4>X{seasonCat?.users?.length || 0} </h4> */}
                  </div>
                </div>
              )}

              <div className="entryfee">
                <div className="efleft">
                  <h4>{translation.mytourney.totalpot}</h4>
                </div>
                <div className="efRyt">
                  <h4>
                    {seasonCat?.tokenFee * seasonCat?.users?.length -
                      seasonCat?.tokenFee * seasonCat?.users?.length * 0.1 ||
                      ""}{" "}
                    T
                    {/* {seasonCat?.tokenFee * seasonCat?.users?.length || ""} T */}
                  </h4>
                </div>
              </div>
              <div className="listng">
                <div className="efleft">
                  <h4>No. 1</h4>
                </div>
                <div className="efRyt">
                  <h5>
                    {seasonCat
                      ? ((seasonCat?.tokenFee * seasonCat?.users?.length -
                          seasonCat?.tokenFee *
                            seasonCat?.users?.length *
                            0.1) *
                          50) /
                        100
                      : ""}{" "}
                    T
                  </h5>
                </div>
              </div>
              <div className="listng">
                <div className="efleft">
                  <h4>No. 2</h4>
                </div>
                <div className="efRyt">
                  <h5>
                    {seasonCat
                      ? ((seasonCat?.tokenFee * seasonCat?.users?.length -
                          seasonCat?.tokenFee *
                            seasonCat?.users?.length *
                            0.1) *
                          30) /
                        100
                      : ""}{" "}
                    T
                  </h5>
                </div>
              </div>
              <div className="listng">
                <div className="efleft">
                  <h4>No. 3</h4>
                </div>
                <div className="efRyt">
                  <h5>
                    {seasonCat
                      ? ((seasonCat?.tokenFee * seasonCat?.users?.length -
                          seasonCat?.tokenFee *
                            seasonCat?.users?.length *
                            0.1) *
                          20) /
                        100
                      : ""}{" "}
                    T
                  </h5>
                </div>
              </div>
            </div>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
};
export default trackWindowScroll(MatchCard);
