// import { STORAGE_KEYS, getFromStorage } from "../constants/Storage";
// import { API_URL, END_POINTS } from "../constants/Urls";

import { STORAGE_KEYS, getFromStorage } from "../helpers/Storage";
import { API_URL, END_POINTS } from "../helpers/constants/Urls";

export const UploadMedia = async (imageObject: any) => {
  const formData = new FormData();

  formData.append("upload", imageObject as any);

  const getToken = await getFromStorage(STORAGE_KEYS.token);
  const token = getToken ? JSON.parse(`${getToken}`) : null;
  const headers = {
    // "Content-Type": "multipart/form-data",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  try {
    const res = await fetch(API_URL + END_POINTS.mediaUpload, {
      method: "POST",
      headers,
      body: formData,
    });
    const response = await res.json();

    return response;
  } catch (error) {
    console.log(error, ">>>>>>>>>");
    return error;
  }
};
