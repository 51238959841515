/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Dispatch, useState, SetStateAction, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useTranslation from "../../hooks/Translation";
import { useLazyGetFiltersQuery } from "../../service/Leaderboard";
import { Filters } from "../../types/general";
import { Loader } from "../../components";

type filterProps = {
  activeCls: boolean;
  setActiveCls: Dispatch<SetStateAction<boolean>>;
  setSelectedTournament: Dispatch<SetStateAction<any>>;
  selectedTournament: string[];
  setSelectedCat: Dispatch<SetStateAction<any>>;
  selectedCat: string[];
  getTeamsByIdData: (arg: string) => void;
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
  scrollPosition: ScrollPosition;
};

const Filter = ({
  activeCls,
  setActiveCls,
  setSelectedTournament,
  selectedTournament,
  setSelectedCat,
  selectedCat,
  getTeamsByIdData,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  scrollPosition,
}: filterProps) => {
  const translation = useTranslation() as any;
  const [addActive, setAddActive] = useState(1);
  const [filterData, setFilterData] = useState<Filters>();
  const [loading, setLoading] = useState(false);

  const [getFiltersMethod] = useLazyGetFiltersQuery();

  const getFilterData = async () => {
    try {
      setLoading(true);
      const res = await getFiltersMethod({}).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setFilterData(res?.data || undefined);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSelectCategory = (item: string) => {
    if (selectedCat.includes(item)) {
      setSelectedCat(selectedCat.filter((id) => id !== item));
    } else {
      setSelectedCat([...selectedCat, item]);
    }
  };

  const handleSelectTournaments = (item: string) => {
    if (item?.length > 0) {
      setStartDate(null);
      setEndDate(null);
    }
    if (selectedTournament.includes(item)) {
      setSelectedTournament(selectedTournament.filter((id) => id !== item));
    } else {
      setSelectedTournament([...selectedTournament, item]);
    }
  };

  useEffect(() => {
    getFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={activeCls ? "fltr_sec active" : "fltr_sec"}>
      <Loader isLoad={loading} />
      <div className="fltr_hdngs">
        <h2 className="yellow_text fltr_hdng">
          {translation.leaderBoard.filter}
        </h2>
        <h2
          onClick={() => {
            setSelectedCat([]);
            setSelectedTournament([]);
            setStartDate(null);
            setEndDate(null);
            getTeamsByIdData("clear");
          }}
          className="yellow_text fltr_hdng cursor_pointer"
        >
          {translation.leaderBoard.clear}
        </h2>
      </div>
      <div className="mob-flex">
        <div className="mob-filter">
          <ul>
            <li
              onClick={() => setAddActive(1)}
              className={addActive === 1 ? "active" : ""}
            >
              <h2 className="fltr_nm">{translation.leaderBoard.category}</h2>
            </li>
            <li
              onClick={() => setAddActive(2)}
              className={addActive === 2 ? "active" : ""}
            >
              <h2 className="fltr_nm">{translation.leaderBoard.tourney}</h2>
            </li>
            <li
              onClick={() => setAddActive(3)}
              className={addActive === 3 ? "active" : ""}
            >
              <h2 className="fltr_nm">{translation.leaderBoard.date}</h2>
            </li>
          </ul>
        </div>
        <div className="filter-detail">
          <div
            className={
              addActive === 1 ? "com ctgry_fltr active" : "com ctgry_fltr"
            }
          >
            <h2 className="fltr_nm">{translation.leaderBoard.category}</h2>
            <ul className="selct">
              {filterData?.categories?.length
                ? filterData?.categories?.map((item) => {
                    return (
                      <li
                        key={item?._id}
                        className={
                          selectedCat.includes(item?._id) ? "activecate" : ""
                        }
                      >
                        <div onClick={() => handleSelectCategory(item?._id)}>
                          <h4>{item?.name || ""}</h4>
                        </div>
                        {selectedCat.includes(item?._id) ? (
                          <figure>
                            <LazyLoadImage
                              src="/static/images/tick2.png"
                              alt=""
                              effect="blur"
                              scrollPosition={scrollPosition}
                            />
                          </figure>
                        ) : null}
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
          <div
            className={
              addActive === 2 ? "com ctgry_fltr active" : "com ctgry_fltr"
            }
          >
            <h2 className="fltr_nm">{translation.leaderBoard.tourney}</h2>
            <ul className="selct">
              {filterData?.categories?.length
                ? filterData?.tournaments?.map((item) => {
                    return (
                      <li
                        key={item?._id}
                        className={
                          selectedTournament.includes(item?._id)
                            ? "activecate"
                            : ""
                        }
                      >
                        <div onClick={() => handleSelectTournaments(item._id)}>
                          <h4>{item?.name || ""}</h4>
                        </div>
                        {selectedTournament.includes(item?._id) ? (
                          <figure>
                            <LazyLoadImage
                              src="/static/images/tick2.png"
                              alt=""
                              effect="blur"
                              scrollPosition={scrollPosition}
                            />
                          </figure>
                        ) : null}
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
          <div
            className={
              addActive === 3 ? "com ctgry_fltr active" : "com ctgry_fltr"
            }
          >
            <h2 className="fltr_nm">{translation.leaderBoard.date}</h2>
            <div className="da_tes">
              {/* <input className="dt_inpts" type="text" placeholder="DD" />
              <input className="dt_inpts" type="text" placeholder="MM" />
              <input className="dt_inpts" type="text" placeholder="YYYY" /> */}
              <div>
                <p className="dt_pckr_txt">Date From</p>
                <DatePicker
                  disabled={selectedTournament?.length > 0}
                  className="dtpckr"
                  selected={startDate}
                  onChange={(date: Date | null) => setStartDate(date)}
                  selectsStart
                  // startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                />
                <p className="dt_pckr_txt">Date to</p>
                <DatePicker
                  disabled={selectedTournament?.length > 0}
                  className="dtpckr"
                  selected={endDate}
                  onChange={(date: Date | null) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  maxDate={new Date()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        onClick={() => {
          getTeamsByIdData("");
          setActiveCls(!activeCls);
        }}
        className="btn"
      >
        {translation.Globals.applyFilter}
      </button>
    </div>
  );
};

export default trackWindowScroll(Filter);
