/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { FormControl, MenuItem, Select } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useTranslation from "../../hooks/Translation";
import { useLazyGetTransactionsQuery } from "../../service/cms";
import { errorToast } from "../../helpers/ShowToast";
import { TransactionData } from "../../types/general";
import { Loader } from "../../components";

const Transactions = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const translation = useTranslation() as any;
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [getTransaction, getTransactionData] = useLazyGetTransactionsQuery();
  const [transactionData, setTransactionData] = useState<TransactionData[]>([]);
  const totalPages = Math.ceil(totalCount / 10);

  const fetchData = async () => {
    try {
      const res = await getTransaction({ page, size: 10 }).unwrap();
      if (res?.statusCode === 200) {
        setTransactionData(res?.data?.data);
        setTotalCount(res?.data?.count);
      } else if (res.statusCode === 503) {
        errorToast(translation.toastMessages.service_unavailable);
      }
    } catch (error: any) {
      // errorToast(error?.data?.message || "");
    }
  };
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <div className="editprofile">
        <Loader isLoad={getTransactionData.isLoading} />
        <h4>{translation.pridictionList.transactions}</h4>
        <div className="acc_Contnr">
          <div className="notification">
            <div className="ldrbrd_dtls">
              {transactionData?.length
                ? transactionData?.map((item) => {
                    return (
                      <div className="tms trnsctns" style={{ cursor: "auto" }}>
                        <div className="transactns_lft">
                          <div className="trnsctn_title_dv">
                            <h4>{item?.label}</h4>
                            <p>
                              {moment(item?.createdAt).format(
                                "D MMM, YYYY h:mm A"
                              )}
                            </p>
                          </div>
                          <div className="trnsctn_des_dv">
                            {item?.label === "Deposit" ? (
                              <p>Deposit</p>
                            ) : item?.label === "Withdrawl" ? (
                              <p>Withdrawl</p>
                            ) : (item?.label === "Entry" ||
                                item?.label === "Prize") &&
                              item?.message ? (
                              <p>{item?.message}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className="transactns_ryt">
                          <h4
                            className={
                              item?.label === "Deposit" ||
                              item?.label === "Prize"
                                ? "green_text"
                                : "red_text"
                            }
                          >
                            {item?.label === "Deposit" ||
                            item?.label === "Prize"
                              ? "+"
                              : "-"}{" "}
                            {item?.tokens} T
                          </h4>
                          <p>{item?.balance} T</p>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
      <div className="pagination">
        <p>
          <span>
            Showing {transactionData?.length ? `${transactionData?.length}` : 0}{" "}
            items{" "}
          </span>
        </p>
        <div className="pagination_mn">
          <FormControl fullWidth>
            <Select
              className="dropse"
              style={{ width: 20 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              inputProps={{
                "aria-label": "Without label",
              }}
              displayEmpty
              value={page}
              onChange={(pages: any) => setPage(pages.target.value)}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              {Array.from({ length: totalPages }).map((_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="pages">
            <button
              className="prevbtn"
              onClick={() => onPageChange(page - 1)}
              disabled={page === 1}
              type="button"
            >
              <ArrowBackIosIcon />
            </button>
            <div className="count">
              {totalPages > 6
                ? Array.from({ length: totalPages })
                    .slice(0, 5)
                    .map((_, index) => (
                      <button
                        className={page === index + 1 ? "actv" : "inActv"}
                        key={index}
                        onClick={() => onPageChange(index + 1)}
                        disabled={page === index + 1}
                        type="button"
                      >
                        {index + 1}
                      </button>
                    ))
                    .concat(
                      <span
                        key="ellipsis"
                        className="ellipsis"
                        style={{ color: "white" }}
                      >
                        ...
                      </span>,
                      <button
                        className="actv"
                        type="button"
                        style={{
                          display:
                            page < 6 || page === totalPages
                              ? "none"
                              : undefined,
                        }}
                        key={totalPages}
                      >
                        {page}
                      </button>,
                      <button
                        className={page === totalPages ? "actv" : "inActv"}
                        key={totalPages}
                        type="button"
                        onClick={() => onPageChange(totalPages)}
                        disabled={page === totalPages}
                      >
                        {totalPages}
                      </button>
                    )
                : Array.from({ length: totalPages }).map((_, index) => (
                    <button
                      className={page === index + 1 ? "actv" : "inActv"}
                      type="button"
                      key={index}
                      onClick={() => onPageChange(index + 1)}
                      disabled={page === index + 1}
                    >
                      {index + 1}
                    </button>
                  ))}
            </div>
            <button
              className="prevbtn"
              type="button"
              onClick={() => onPageChange(page + 1)}
              disabled={page === totalPages}
            >
              <ArrowForwardIosIcon
              // className="prevbtn"
              // onClick={() => goToPage(currentPage + 1)}
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
