/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useTranslation from "../../hooks/Translation";
import { useAppSelector } from "../../hooks/Store";
import { getCms } from "../../reducers/generalSlice";

type ContactType = {
  scrollPosition: ScrollPosition;
};
const Contact = ({ scrollPosition }: ContactType) => {
  const translation = useTranslation() as any;
  const getCmsData = useAppSelector(getCms);

  const contact = [
    // {
    //   id: 2,
    //   image: "/static/images/callcntc.png",
    //   title: translation.pridictionList.callnow,
    //   description: translation.pridictionList.callp,
    //   href: getCmsData
    //     ? `tel:${getCmsData?.countryCode} ${getCmsData?.phone}`
    //     : "",
    //   target: "",
    //   rel: "",
    // },
    {
      id: 3,
      image: "/static/images/write.png",
      title: translation.pridictionList.writeus,
      description: translation.pridictionList.average,
      href: getCmsData ? `mailto:${getCmsData?.email}` : "",
      target: "_blank",
      rel: "noopener noreferrer",
    },
  ];

  return (
    <div className="editprofile">
      <h4>{translation.pridictionList.contct}</h4>
      <div className="acc_Contnr">
        <div className="contct">
          <figure className="contctfig">
            <LazyLoadImage
              src="/static/images/contact.png"
              alt=""
              effect="blur"
              height="100%"
              width="100%"
              scrollPosition={scrollPosition}
            />
          </figure>
          <h3>{translation.pridictionList.facingErr}</h3>
          <p>{translation.pridictionList.facingP}</p>

          {contact.map((item) => {
            return (
              <div className="chatwithus" key={item.id}>
                <div className="chleft">
                  <figure className="contctfig">
                    <LazyLoadImage
                      src={item?.image}
                      alt=""
                      effect="blur"
                      height="100%"
                      width="100%"
                      scrollPosition={scrollPosition}
                    />
                  </figure>
                </div>
                <div className="chRyt">
                  <a href={item.href} target={item.target} rel={item.rel}>
                    {" "}
                    <h3>{item?.title}</h3>
                  </a>
                  <p style={{ textAlign: "left", lineHeight: "22px" }}>
                    {item?.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default trackWindowScroll(Contact);
