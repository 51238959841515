import { Route, Routes } from "react-router-dom";
import Pages from "./pages";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Pages.Home />} />
      <Route path="/predictions/:_id" element={<Pages.Predictions />} />
      <Route path="/mytourneys" element={<Pages.MyTourneys />} />
      <Route path="/entries/:id" element={<Pages.Entries />} />
      <Route path="/predictionlist" element={<Pages.PridictionList />} />
      <Route path="/userprofile" element={<Pages.UserProfile />} />
      <Route path="/tourneycreated" element={<Pages.TourneyCreated />} />
      <Route path="/leaderboard" element={<Pages.LeaderBoard />} />
      <Route
        path="/leaderboarddetails"
        element={<Pages.LeaderboardDetails />}
      />
      <Route path="/signup" element={<Pages.SignUp />} />
      <Route path="/login" element={<Pages.Login />} />
      <Route path="/forgotpassword" element={<Pages.ForgotPassword />} />
      <Route path="/verification" element={<Pages.Verification />} />
      <Route path="/topseasons/:id" element={<Pages.ExtraBonus />} />
      <Route path="/bonus/:id" element={<Pages.Bonus />} />
      <Route path="*" element={<Pages.PageNotFound />} />
      <Route
        path="/seasonaltournament/:id"
        element={<Pages.SeasonalTournament />}
      />
      <Route path="/resetpassword" element={<Pages.ResetPassword />} />
      <Route path="/privacypolicy" element={<Pages.PrivacyPolicy />} />
      <Route
        path="/termsandconditions"
        element={<Pages.TermsAndConditions />}
      />
      <Route path="/tokenhistory" element={<Pages.TokenHistory />} />
      <Route path="/tournamentdetail" element={<Pages.TournamentDetail />} />
      <Route path="/pointscalculation" element={<Pages.PointsCalculation />} />
    </Routes>
  );
};
export default Router;
