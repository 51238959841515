/* eslint-disable import/no-extraneous-dependencies */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";
import type { RootState } from "../app/Store";
import type { User } from "../types/User";
import { STORAGE_KEYS } from "../helpers/Storage";

export interface AuthState {
  user: User | null;
  token: string | null;
  tempToken: string | null;
}
const initialState: AuthState = {
  user: null,
  token: null,
  tempToken: null,
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state) => {
      // removeKeyFromStorage(STORAGE_KEYS.token);
      secureLocalStorage.removeItem(STORAGE_KEYS.token);
      secureLocalStorage.removeItem(STORAGE_KEYS.userData);
      state.user = null;
      state.token = null;
    },
    setCredentials: (
      state,
      action: PayloadAction<Pick<AuthState, "user" | "token">>
    ) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    temporaryToken: (
      state,
      action: PayloadAction<Pick<AuthState, "tempToken">>
    ) => {
      state.tempToken = action.payload.tempToken;
    },
  },
});
export const { resetAuth, setCredentials, temporaryToken } = authSlice.actions;
export const getCurrentUser = (state: RootState) => state.auth.user;
export const getToken = (state: RootState) => state.auth.token;
export const getTempToken = (state: RootState) => state.auth.tempToken;

export default authSlice.reducer;
