export const isString = (val: any) => {
  if (val.includes(".")) {
    return true;
  }
  if (val.length === 1 && val === " ") {
    return false;
  }
  if (
    val[val.length - 1] === " " &&
    val[val.length - 1] !== val[val.length - 2]
  ) {
    return true;
  }
  if (
    val[val.length - 1]?.trim()?.toLowerCase() !==
      val[val.length - 1]?.trim()?.toUpperCase() ||
    val === ""
  ) {
    return true;
  }
  return false;
};
