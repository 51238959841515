import { END_POINTS } from "../helpers/constants/Urls";
import type { Profile, Teams, User } from "../types/User";

import emptySplitApi from "../utils/rtk";

type PostLoginBody = {
  hash: string;
  sek: string;
};

type PostLoginResponse = {
  statusCode: number;
  data?: User;
  message: string;
};
type PostSignupBody = {
  // userName: string;
  // firstName: string;
  // lastName: string;
  // password: string;
  // email: string;
  // favouriteTeam: string | null;
  // image: string | null;
  // countryCode: string;
  // phone: string | undefined;
  hash: string;
  sek: string;
  // appKey?: string | undefined;
  // deviceId: string;
};
type PostSignupResponse = {
  statusCode: number;
  data?: null;
  message: string;
};

type PostOtpVerificationBody = {
  hash: string;
  sek: string;
};

type PostOtpVerificationResponse = {
  statusCode: number;
  data?: any;
  message: string;
};
type PostLogoutResponse = {
  statusCode: number;
  data: [];
  message: string;
};
type PostLogoutBody = {
  hash: string;
  sek: string;
};
type PostForgotPasswordBody = {
  hash: string;
  sek: string;
};
type PostForgotPasswordResponse = {
  statusCode: number;
  data?: User;
  message: string;
};
type PostResetPasswordBody = {
  hash: string;
  sek: string;
};
type PostResetPasswordResponse = {
  statusCode: number;
  data?: any;
  message: string;
};
type PostChangePasswordBody = {
  hash: string;
  sek: string;
};
type PostChangePasswordResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type GetProfileResponse = {
  statusCode: number;
  data?: any;
  message: string;
};
type GetTeamsResponse = {
  statusCode: number;
  data?: Teams[];
  message: string;
};

type PostProfileBody = {
  // userName?: string | undefined;
  // firstName?: string | undefined;
  // lastName: string;
  // email: string;
  // image?: string | undefined;
  // countryCode: string;
  // phone: string;
  hash: string;
  sek: string;
  // appKey?: string | undefined;
  // deviceId: string;
};

type PostProfileResponse = {
  statusCode: number;
  data?: Profile;
  message: string;
};
type PostResendOtpResponse = {
  statusCode: number;
  data?: any;
  message: string;
};
type PostResendOtpBody = {
  hash: string;
  sek: string;
  // countryCode: string;
  // phone: string;
  // type: string;
};
type PostProfileOtpBody = {
  hash: string;
  sek: string;
};
type PostProfileOtpResponse = {
  statusCode: number;
  data?: any;
  message: string;
};
export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation<PostLoginResponse, PostLoginBody>({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),
    postSignup: builder.mutation<PostSignupResponse, PostSignupBody>({
      query: (body) => ({
        url: END_POINTS.signUp,
        method: "POST",
        body,
      }),
    }),
    // eslint-disable-next-line @typescript-eslint/ban-types
    getTeams: builder.query<GetTeamsResponse, {}>({
      query: () => ({
        url: END_POINTS.teams,
        method: "GET",
      }),
    }),
    postForgotPassword: builder.mutation<
      PostForgotPasswordResponse,
      PostForgotPasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.forgotPassword,
        method: "POST",
        body,
      }),
    }),
    postResetPassword: builder.mutation<
      PostResetPasswordResponse,
      PostResetPasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.resetPassword,
        method: "POST",
        body,
      }),
    }),
    postChangePassword: builder.mutation<
      PostChangePasswordResponse,
      PostChangePasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.changePassword,
        method: "POST",
        body,
      }),
    }),
    postOtpVerification: builder.mutation<
      PostOtpVerificationResponse,
      PostOtpVerificationBody
    >({
      query: (body) => ({
        url: END_POINTS.otp_verify,
        method: "POST",
        body,
      }),
    }),
    // eslint-disable-next-line @typescript-eslint/ban-types
    getProfile: builder.query<GetProfileResponse, {}>({
      query: () => ({
        url: END_POINTS.getProfile,
        method: "GET",
      }),
    }),

    postProfile: builder.mutation<PostProfileResponse, PostProfileBody>({
      query: (body) => ({
        url: END_POINTS.updateProfile,
        method: "POST",
        body,
      }),
    }),
    // eslint-disable-next-line @typescript-eslint/ban-types
    postLogout: builder.mutation<PostLogoutResponse, PostLogoutBody>({
      query: (body) => ({
        url: END_POINTS.logout,
        method: "POST",
        body,
      }),
    }),
    postResendOtp: builder.mutation<PostResendOtpResponse, PostResendOtpBody>({
      query: (body) => ({
        url: END_POINTS.resend_otp,
        method: "POST",
        body,
      }),
    }),
    postProfileOtp: builder.mutation<
      PostProfileOtpResponse,
      PostProfileOtpBody
    >({
      query: (body) => ({
        url: END_POINTS.otp,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const {
  usePostLoginMutation,
  usePostSignupMutation,
  useLazyGetTeamsQuery,
  usePostChangePasswordMutation,
  usePostForgotPasswordMutation,
  usePostResetPasswordMutation,
  usePostOtpVerificationMutation,
  useLazyGetProfileQuery,
  usePostProfileMutation,
  usePostLogoutMutation,
  usePostResendOtpMutation,
  usePostProfileOtpMutation,
} = authApi;
