import Home from "./home";
import Predictions from "./predictions";
import MyTourneys from "./myTourneys";
import Entries from "./entries";
import PridictionList from "./pridictionList";
import UserProfile from "./userProfile";
import TourneyCreated from "./tourneyCreated";
import LeaderBoard from "./leaderboard";
import SignUp from "./signup";
import Login from "./login";
import ForgotPassword from "./forgotPassword";
import Verification from "./verification";
import LeaderboardDetails from "./leaderboardDetails";
import ExtraBonus from "./extrabonus";
import Bonus from "./bonus";
import PageNotFound from "./pagenotfound";
import SeasonalTournament from "./seasonalTournament";
import ResetPassword from "./resetPassword";
import PrivacyPolicy from "./privacyPolicy";
import TermsAndConditions from "./termsAndConditions";
import TokenHistory from "./tokenHistory";
import TournamentDetail from "./tournamentDetail";
import PointsCalculation from "./pointsCalculation";

export default {
  Home,
  Predictions,
  MyTourneys,
  Entries,
  PridictionList,
  UserProfile,
  TourneyCreated,
  LeaderBoard,
  SignUp,
  Login,
  ForgotPassword,
  Verification,
  LeaderboardDetails,
  ExtraBonus,
  Bonus,
  PageNotFound,
  SeasonalTournament,
  ResetPassword,
  PrivacyPolicy,
  TermsAndConditions,
  TokenHistory,
  TournamentDetail,
  PointsCalculation,
};
