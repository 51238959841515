import React from "react";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import { useAppSelector } from "../../hooks/Store";
import { getCms } from "../../reducers/generalSlice";
import Layout from "../../layout";

const TermsAndConditions = () => {
  const translation = useTranslation() as any;
  const getCmsData = useAppSelector(getCms);
  const navigate = useNavigate();
  return (
    <Layout>
      <div className=" nv_spc  cms">
        <div className="container">
          <div className="mob_view">
            <i
              className="fa-solid fa-arrow-up-long bck_btn"
              onClick={() => navigate("/")}
            />
            <h2 className="cms_hdng">{translation.Globals.terms}</h2>
          </div>

          <p
            className={getCmsData ? "policy_txt" : "policy_txt2"}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: getCmsData
                ? getCmsData?.terms
                : translation.Globals.no_data,
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
