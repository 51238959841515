/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useTranslation from "../../hooks/Translation";
// eslint-disable-next-line import/no-extraneous-dependencies
import RightDrawer from "./RightDrawer";

import { MatchCard } from "../../components";
import { Season, Seasons, Tournaments } from "../../types/general";

interface MatchDetailType {
  cardId: string;
  seasonData: Season[];
  tourneyData: Tournaments[];
  seasonalData: Seasons | undefined;
  setSelectedMatchId: Dispatch<SetStateAction<string>>;
  scrollPosition: ScrollPosition;
}

const LiveMatchDetail = ({
  cardId,
  seasonData,
  tourneyData,
  seasonalData,
  setSelectedMatchId,
  scrollPosition,
}: MatchDetailType) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [ActiveDrawer, SetActiveDrawer] = useState(1);
  const [showPdf, setShowPdf] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newCls, setNewCls] = useState(false);

  const handleCardClick = () => {};

  useEffect(() => {
    if (seasonalData) {
      const startDate = moment(`${seasonalData.startDate}`).format(
        "YYYY-MM-DD"
      );
      const startTime = moment(`${seasonalData.startTime}`).format("HH:mm");
      const currentDate = moment().format("YYYY-MM-DD");
      const currentTime = moment().format("HH:mm");

      if (currentDate > startDate) {
        setShowPdf(true);
      } else {
        setShowPdf(false);

        if (currentDate === startDate) {
          if (currentTime > startTime) {
            setShowPdf(true);
          } else {
            setShowPdf(false);
          }
        } else {
          setShowPdf(false);
        }
      }
    }
  }, [seasonalData]);

  return (
    <>
      <RightDrawer
        sidebarVisible={sidebarVisible}
        setSidebarVisible={setSidebarVisible}
        ActiveDrawer={ActiveDrawer}
        predictions=""
        userName=""
        userImage=""
        tournamentId={cardId}
      />
      <div className="matchmain_mob trLft  ">
        <div className="matchmain" style={{ width: "100%" }}>
          {tourneyData.length
            ? tourneyData?.map((item) => {
                return (
                  <div key={item._id} onClick={() => handleCardClick()}>
                    {cardId === item._id && (
                      <MatchCard
                        onSelectCard={() => setSelectedMatchId("")}
                        id={item._id}
                        image={item?.image}
                        fixtures={item?.fixtures}
                        title={item.name}
                        totalTokens={item?.totalTokens}
                        setNewCls={setNewCls}
                        cutoff=""
                      />
                    )}
                  </div>
                );
              })
            : null}
        </div>
        <div className="matchmain" style={{ width: "100%" }}>
          {seasonData.length
            ? seasonData?.map((item) => {
                return (
                  <div key={item._id} onClick={() => handleCardClick()}>
                    {cardId === item._id && (
                      <MatchCard
                        onSelectCard={() => setSelectedMatchId("")}
                        id={item._id}
                        image={item?.image}
                        type={item?.type}
                        tokenFee={item?.tokenFee}
                        season={item?.title}
                        startDate={item?.startDate}
                        startTime={item?.startTime}
                        endDate={item?.endDate}
                        users={item?.users || undefined}
                        setNewCls={setNewCls}
                        cutoff=""
                        isSubscribed={item?.isSubscribed}
                        seasonCat={item}
                      />
                    )}
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <div className="trTop">
        <h4>
          {moment(
            `${seasonalData?.startDate}T${seasonalData?.startTime}:00.037Z`
          ).format("dddd D MMMM, HH:mm")}
        </h4>

        <div className="trIcn">
          <div
            onClick={() => {
              setSidebarVisible(true);
              SetActiveDrawer(1);
            }}
          >
            <figure>
              <LazyLoadImage
                src="/static/images/userarr.png"
                alt=""
                effect="blur"
                scrollPosition={scrollPosition}
                height="100%"
                width="100%"
              />
            </figure>
          </div>

          {showPdf ? (
            <div
              onClick={() =>
                navigate("/predictionlist", {
                  state: {
                    cardId,
                    type: seasonalData?.type,
                    tournaments: seasonalData?.tournaments,
                  },
                })
              }
            >
              <figure>
                <LazyLoadImage
                  src="/static/images/pdf.svg"
                  alt=""
                  effect="blur"
                  scrollPosition={scrollPosition}
                  height="100%"
                  width="100%"
                />
              </figure>
            </div>
          ) : null}

          <div
            onClick={() => {
              setSidebarVisible(true);
              SetActiveDrawer(2);
            }}
          >
            <figure>
              <LazyLoadImage
                src="/static/images/chat.png"
                alt=""
                effect="blur"
                height="100%"
                width="100%"
                scrollPosition={scrollPosition}
              />
            </figure>
          </div>
        </div>
      </div>

      <div className="btmdiv">
        <h4 className="heading">
          {translation.mytourney.total} {seasonalData?.users?.length}{" "}
          {translation.mytourney.entries}
        </h4>
        <div className="userlisting">
          <div className="users">
            <table>
              <thead>
                <tr>
                  <th>{translation.mytourney.rank}</th>

                  <th style={{ textAlign: "center" }}>
                    {translation.mytourney.total_pts}
                  </th>
                </tr>
              </thead>
              {seasonalData?.users?.map((item, index) => {
                return (
                  <tbody key={item._id}>
                    <tr>
                      <td>
                        <div className="user" key={item?._id}>
                          <div className="userleft">
                            <figure>
                              <LazyLoadImage
                                src={
                                  item?.image
                                    ? item?.image
                                    : "/static/images/user_placeholder.png"
                                }
                                alt=""
                                effect="blur"
                                height="100%"
                                width="100%"
                                scrollPosition={scrollPosition}
                              />
                            </figure>
                            <h4
                              className={
                                item?.rank === 1
                                  ? "golden_txt"
                                  : item?.rank === 2
                                  ? "silver_txt"
                                  : item?.rank === 3
                                  ? "bronze_txt"
                                  : ""
                              }
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {item?.rank || " "}.{" "}
                              </span>
                              {item?.userName || ""}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td
                        className={
                          item?.rank === 1
                            ? "golden_txt"
                            : item?.rank === 2
                            ? "silver_txt"
                            : item?.rank === 3
                            ? "bronze_txt"
                            : ""
                        }
                      >
                        {item?.total || "0"}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default trackWindowScroll(LiveMatchDetail);
