import { END_POINTS } from "../helpers/constants/Urls";
import type { User } from "../types/User";
import { ChatResponse, tokenhistory } from "../types/general";
import emptySplitApi from "../utils/rtk";

type PostUploadMediaBody = {
  file: string;
};
type PostUploadImageResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type GetTokenHistoryResponse = {
  statusCode: number;
  data?: tokenhistory[];
  message: string;
};

type getChatResponse = {
  statusCode: number;
  data?: ChatResponse[] | [];
  message: string;
};

export const commonApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postUploadMedia: builder.mutation<
      PostUploadImageResponse,
      PostUploadMediaBody
    >({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),
    // eslint-disable-next-line @typescript-eslint/ban-types
    getTokenHistory: builder.query<GetTokenHistoryResponse, {}>({
      query: () => ({
        url: END_POINTS.tokenHistory,
        method: "GET",
      }),
    }),
    getChatHistory: builder.query<
      getChatResponse,
      { tournament_id: string | undefined }
    >({
      query: ({ tournament_id }) => ({
        url: `${END_POINTS.get_chat_history}${tournament_id}/`,
        method: "GET",
      }),
    }),
    postMail: builder.mutation<
      {
        message: string;
        statusCode: number;
      },
      { hash: string; sek: string }
    >({
      query: (body) => ({
        url: `${END_POINTS.mail}`,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const {
  usePostUploadMediaMutation,
  useLazyGetTokenHistoryQuery,
  useLazyGetChatHistoryQuery,
  usePostMailMutation,
} = commonApi;
