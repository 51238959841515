/* eslint-disable no-useless-concat */
/* eslint-disable @typescript-eslint/ban-types */
import { END_POINTS } from "../helpers/constants/Urls";
import {
  DownloadPdfData,
  leaderBoardData,
  leaderData,
  myPrediction,
  preDictionByUser,
  PredictionLst,
  Season,
  Seasons,
  Tournaments,
  TourneyDetails,
} from "../types/general";

import emptySplitApi from "../utils/rtk";

type GetTournamentResponse = {
  statusCode: number;
  data: { tournaments: Tournaments[]; seasonBanner: Seasons; banner: Seasons };
  message: string;
};
type GetTourneyResponse = {
  statusCode: number;
  data: any;
  message: string;
};
type EditPredictionResponse = {
  statusCode: number;
  data: any;
  message: string;
};

type GetTourneyDetailsResponse = {
  statusCode: number;
  data: TourneyDetails;
  message: string;
};

type PostSavePredictionResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type PostSavePredictionBody = {
  hash: string;
  sek: string;
  // tournament_id: string;
  // fixture_id: string;
  // homeTeam: string;
  // awayTeam: string;
  // homeTeam_id: string;
  // awayTeam_id: string;
  // homeTeam_goals: string;
  // awayTeam_goals: string;
  // tokenCategory: any;
  // awayTeam_logo: string;
  // homeTeam_logo: string;
  // fixtureId: string | null;
};

type PredictionResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type SubscribeBody = {
  // seasonId: string;
  hash: string;
  sek: string;
};
type GetSelectedTourneyResponse = {
  statusCode: number;
  message: string;
  data: {
    tournaments: [];
    seasons: Season[];
    leagues: [];
  };
};

type GetSeasonResponse = {
  statusCode: number;
  message: string;
  data: Season;
};

type GetPdfResponse = {
  statusCode: number;
  message: string;
  data: any;
};

type GetMyPredictionResponse = {
  statusCode: number;
  message: string;
  data?: PredictionLst[];
};

type GetPredictionByUserResponse = {
  statusCode: number;
  message: string;
  data?: any;
};

type GetPredictionByUserParams = {
  id: string | undefined;
  userName: string;
  categoryId: string | undefined;
};
type GetDownloadPdfResponse = {
  data: string;
  statusCode: number;
  message: string;
};

type GetLiveMatchResponse = {
  statusCode: number;
  data: Tournaments;
  message: string;
};
type GetTourneyDetailResponse = {
  statusCode: number;
  data: Tournaments[];
  message: string;
};

export const tournamentsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getHome: builder.query<GetTournamentResponse, {}>({
      query: () => ({
        url: END_POINTS.home,
        method: "GET",
      }),
    }),

    getTournamentFixtures: builder.query<
      GetTourneyResponse,
      { tournament_id: any }
    >({
      query: ({ tournament_id }) => ({
        url: `${END_POINTS.tournamentFixtures}${tournament_id}`,
        method: "GET",
      }),
    }),
    postSavePrediction: builder.mutation<
      PostSavePredictionResponse,
      PostSavePredictionBody
    >({
      query: (body) => ({
        url: END_POINTS.savePrediction,
        method: "POST",
        body,
      }),
    }),
    getSeasonDetails: builder.query<GetSeasonResponse, { banner_id: string }>({
      query: ({ banner_id }) => ({
        url: `${END_POINTS.seasonDetails}${banner_id}`,
        method: "GET",
      }),
    }),
    getPredictionById: builder.query<PredictionResponse, { user_id: string }>({
      query: ({ user_id }) => ({
        url: `${END_POINTS.get_preduction_by_id}${user_id}`,
        method: "GET",
      }),
    }),
    subscribe: builder.mutation<PredictionResponse, SubscribeBody>({
      query: (body) => ({
        url: END_POINTS.subscribe,
        method: "POST",
        body,
      }),
    }),
    editPrediction: builder.mutation<
      EditPredictionResponse,
      PostSavePredictionBody
    >({
      query: (body) => ({
        url: END_POINTS.editPrediction,
        method: "PUT",
        body,
      }),
    }),
    getTourneys: builder.query<GetSelectedTourneyResponse, { type: string }>({
      query: ({ type }) => ({
        url: `${END_POINTS.tourneys}?type=${type}`,
        method: "GET",
      }),
    }),
    getPdf: builder.query<GetPdfResponse, { id: string; categoryId: string }>({
      query: ({ id, categoryId }) => ({
        url: `${END_POINTS.getPdf}/${id}?categoryId=${categoryId}`,
        method: "GET",
      }),
    }),
    getTournamentDetails: builder.query<
      GetTourneyDetailsResponse,
      {
        type: string;
        id: string;
        fixtureId: string | null | undefined;
        categoryId: string;
      }
    >({
      query: ({ id, type, fixtureId, categoryId }) => ({
        url: `${END_POINTS.tournamentDetails}/${id}?type=${type}${
          fixtureId
            ? `&fixture_id=${fixtureId}` + `&categoryId=${categoryId}`
            : "" + `&categoryId=${categoryId}`
        }`,
        method: "GET",
      }),
    }),

    getMyPredictions: builder.query<
      GetMyPredictionResponse,
      { user_id: string; categoryId: string }
    >({
      query: ({ user_id, categoryId }) => ({
        url: `${END_POINTS.getMyPrediction}${user_id}?categoryId=${categoryId}`,
        method: "GET",
      }),
    }),

    getPredictionByUserName: builder.query<
      GetPredictionByUserResponse,
      GetPredictionByUserParams
    >({
      query: ({ userName, id, categoryId }) => ({
        url: `${END_POINTS.getPredictionsByUsername}?username=${userName}&tournamentId=${id}&categoryId=${categoryId}`,
        method: "GET",
      }),
    }),

    downloadPdf: builder.query<
      GetDownloadPdfResponse,
      { id: string; categoryId: string }
    >({
      query: ({ id, categoryId }) => ({
        url: `${END_POINTS.downloadPdf}/${id}?categoryId=${categoryId}`,
        method: "GET",
      }),
    }),
    getSeasonPdf: builder.query<
      GetPdfResponse,
      { id: string; tournamentId: string; categoryId: string }
    >({
      query: ({ id, tournamentId, categoryId }) => ({
        url: `${END_POINTS.getSeasonPdf}${id}?tournamentId=${tournamentId}&categoryId=${categoryId}`,
        method: "GET",
      }),
    }),
    downloadSeasonPdf: builder.query<
      DownloadPdfData,
      { id: string; tournamentId: string; categoryId: string }
    >({
      query: ({ id, tournamentId, categoryId }) => ({
        url: `${END_POINTS.downloadSeasonPdf}${id}?tournamentId=${tournamentId}&categoryId=${categoryId}`,
        method: "GET",
      }),
    }),

    getLiveMatchData: builder.query<GetLiveMatchResponse, { fixtureId: any }>({
      query: ({ fixtureId }) => ({
        url: `${END_POINTS.fixture}?id=${fixtureId}`,
        method: "GET",
      }),
    }),
    getTourneysDetailPage: builder.query<
      GetTourneyDetailResponse,
      { state: string | null }
    >({
      query: ({ state }) => ({
        url: `${END_POINTS.tournamentDetailpage}${state}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetHomeQuery,
  useLazyGetTourneysQuery,
  useLazyGetTournamentFixturesQuery,
  usePostSavePredictionMutation,
  useLazyGetSeasonDetailsQuery,
  useLazyGetPredictionByIdQuery,
  useSubscribeMutation,
  useEditPredictionMutation,
  useLazyGetTournamentDetailsQuery,
  useLazyGetPdfQuery,
  useLazyGetMyPredictionsQuery,
  useLazyGetPredictionByUserNameQuery,
  useLazyDownloadPdfQuery,
  useLazyGetSeasonPdfQuery,
  useLazyDownloadSeasonPdfQuery,
  useLazyGetLiveMatchDataQuery,
  useLazyGetTourneysDetailPageQuery,
} = tournamentsApi;
