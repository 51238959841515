/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Button } from "@mui/material";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import useTranslation from "../../hooks/Translation";

import { errorToast, successToast } from "../../helpers/ShowToast";
import { Loader } from "../../components";
import useAuth from "../../hooks/UseAuth";

import { usePostMailMutation } from "../../service/Common";
import { STORAGE_KEYS, getFromStorage } from "../../helpers/Storage";
import { generateSEKAndHash } from "../../utils/crypto";

type ContactType = {
  scrollPosition: ScrollPosition;
};
const Deposit = ({ scrollPosition }: ContactType) => {
  const deviceId = navigator.userAgent;
  const userData = useAuth();
  const [phoneCode, setPhoneCode] = useState(userData?.countryCode || "");
  const translation = useTranslation() as any;
  const [deposit, { isLoading }] = usePostMailMutation();

  const formik = useFormik({
    initialValues: {
      phone: userData?.phone || "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .required(translation.validations.phone_required)
        .min(8, translation.validations.correct_phone)
        .max(14),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      const data = {
        type: "deposit",
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: formik.values.phone,
        appkey: new Date().toISOString(),
        deviceType: "WEB",
      };
      console.log(data, "data");

      const body = generateSEKAndHash(data);
      console.log(body, "body");

      if (body) {
        try {
          const response = await deposit(body).unwrap();
          if (response?.statusCode === 200) {
            successToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      }
    },
  });

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    // setCountryName(country?.countryCode);
  };

  return (
    <div className="editprofile">
      <Loader isLoad={isLoading} />
      <h4>{translation.profile.deposit}</h4>
      <div className="acc_Contnr">
        <div className="contct">
          <figure className="contctfig">
            <LazyLoadImage
              src="/static/images/deposit_img.svg"
              alt=""
              effect="blur"
              height="100%"
              width="100%"
              scrollPosition={scrollPosition}
            />
          </figure>
          <h3>{translation.profile.thankYou}</h3>
          <p>{translation.profile.contactYou}</p>
          <div className="inputdiv2 inputdiv">
            <form onSubmit={formik.handleSubmit}>
              <div className="phoinput">
                <PhoneInput
                  // country="us"
                  excludeCountries={["bg"]}
                  value={phoneCode + formik.values.phone}
                  inputClass="phoneInput"
                  buttonClass="phoneBtn"
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <h6 className="validation_msg2">
                    {formik.touched.phone && formik.errors.phone}
                  </h6>
                ) : (
                  ""
                )}
              </div>
              <p className="gray_txt">{translation.profile.whatsAppNo}</p>
              <Button
                type="submit"
                className="btnn btn_primary"
                // onClick={handleDeposit}
                // disabled={formik.isSubmitting}
              >
                {translation.pridictionList.deposit}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default trackWindowScroll(Deposit);
