export const API_URL =
  "https://apisportsprediction.appgrowthcompany.com/api/v1/user/";
// export const API_URL =
//   "https://apistagingsportsprediction.appgrowthcompany.com/api/v1/user/";
export const END_POINTS = {
  login: "login",
  signUp: "signup",
  forgotPassword: "forget/password",
  resetPassword: "reset/password",
  changePassword: "change/password",
  socialLogin: "",
  updateProfile: "profile",
  getProfile: "profile",
  logout: "logout",
  mediaUpload: "upload",
  otp_verify: "verify",
  resend_otp: "resend",
  cms: "cms",
  otp: "otp",
  faq: "faq",
  home: "home",
  teams: "teams",
  tokenHistory: "token/history",
  tournamentFixtures: "tournament/fixtures/",
  savePrediction: "prediction",
  seasonDetails: "season/",
  get_preduction_by_id: "user/my/predictions/",
  get_chat_history: "chat/history/",
  subscribe: "subscribe",
  tournamentDetails: "tournament",
  tourneys: "tourneys",
  editPrediction: "prediction",
  getPdf: "pdf",
  getSeasonPdf: "season/pdf/",
  getMyPrediction: "my/predictions/",
  getPredictionsByUsername: "prediction",
  getLeaderBoard: "leaderboard",
  downloadPdf: "download/pdf/",
  downloadSeasonPdf: "season/download/pdf/",
  fixture: "fixture",
  filters: "filters",
  leaderBoardPlayer: "player",
  notification: "notification",
  transactions: "transactions",
  tournamentDetailpage: "tournament/details/",
  mail: "mail",
};
