/* eslint-disable @typescript-eslint/ban-types */
import { END_POINTS } from "../helpers/constants/Urls";
import { Faq, Notificationdata, TransactionData } from "../types/general";
import emptySplitApi from "../utils/rtk";
// import type { Cms } from "../types/general";

type GetCmsResponse = {
  statusCode: number;
  data?: any;
  message: string;
};
type GetFaqResponse = {
  statusCode: number;
  data?: Faq[];
  message: string;
};
type GetNotificationResponse = {
  data: Notificationdata[];
  statusCode: number;
  message: string;
};
type GetTransactionResponse = {
  data: {
    count: number;
    data: TransactionData[];
    totalPages: number;
  };
  statusCode: number;
  message: string;
};

export const cmsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/ban-types
    getCms: builder.query<GetCmsResponse, {}>({
      query: () => ({
        url: END_POINTS.cms,
        method: "GET",
      }),
    }),
    // eslint-disable-next-line @typescript-eslint/ban-types
    getFaq: builder.query<GetFaqResponse, {}>({
      query: () => ({
        url: END_POINTS.faq,
        method: "GET",
      }),
    }),
    getNotification: builder.query<GetNotificationResponse, {}>({
      query: () => ({
        url: END_POINTS.notification,
        method: "GET",
      }),
    }),
    getTransactions: builder.query<
      GetTransactionResponse,
      { page: number; size: number }
    >({
      query: ({ page, size }) => ({
        url: `${END_POINTS.transactions}?size=${size}&page=${page}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetCmsQuery,
  useLazyGetFaqQuery,
  useLazyGetNotificationQuery,
  useLazyGetTransactionsQuery,
} = cmsApi;
