/* eslint-disable no-useless-concat */
/* eslint-disable @typescript-eslint/ban-types */
import { END_POINTS } from "../helpers/constants/Urls";
import {
  Filters,
  PlayersData,
  TeamsData,
  leaderBoardData,
  leaderData,
} from "../types/general";

import emptySplitApi from "../utils/rtk";

type GetLeaderBoardResponse = {
  statusCode: number;
  message: string;
  data: {
    data: leaderData[] | [];
    count: number;
    totalPages: number;
  };
};

type GetLeaderBoardByIdResponse = {
  statusCode: number;
  message: string;
  data: TeamsData[] | [];
};

type GetLeaderBoardByNameResponse = {
  statusCode: number;
  message: string;
  data: PlayersData[];
};

type GetFiltersResponse = {
  statusCode: number;
  message: string;
  data: Filters | null;
};

export const leaderBoardApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getLeaderBoard: builder.query<
      GetLeaderBoardResponse,
      {
        type: string;
        search: string;
        category: [];
        tournament: [];
        fromDate: string | null;
        toDate: string | null;
      }
    >({
      query: ({ type, search, category, tournament, fromDate, toDate }) => ({
        url: `${END_POINTS.getLeaderBoard}?type=${type}&search=${search}`,
        method: "GET",
        params: {
          category,
          tournament,
          fromDate,
          toDate,
        },
      }),
    }),

    getLeaderBoardById: builder.query<
      GetLeaderBoardByIdResponse,
      {
        id: string;
        category: [];
        tournament: [];
        fromDate: string | null;
        toDate: string | null;
      }
    >({
      query: ({ id, category, tournament, fromDate, toDate }) => ({
        url: `${END_POINTS.getLeaderBoard}/${id}`,
        method: "GET",
        params: {
          category,
          tournament,
          fromDate,
          toDate,
        },
      }),
    }),

    getFilters: builder.query<GetFiltersResponse, unknown>({
      query: () => ({
        url: `${END_POINTS.filters}`,
        method: "GET",
      }),
    }),

    getLeaderBoardByPlayerName: builder.query<
      GetLeaderBoardByNameResponse,
      {
        name: string;
        category: [];
        tournament: [];
        fromDate: string | null;
        toDate: string | null;
      }
    >({
      query: ({ name, category, tournament, fromDate, toDate }) => ({
        url: `${END_POINTS.leaderBoardPlayer}?userId=${name}`,
        method: "GET",
        params: {
          category,
          tournament,
          fromDate,
          toDate,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetLeaderBoardQuery,
  useLazyGetLeaderBoardByIdQuery,
  useLazyGetFiltersQuery,
  useLazyGetLeaderBoardByPlayerNameQuery,
} = leaderBoardApi;
