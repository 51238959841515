/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import useTranslation from "../../hooks/Translation";

import { useLazyGetFaqQuery } from "../../service/cms";
import { errorToast } from "../../helpers/ShowToast";
import { Faq } from "../../types/general";
import { Loader } from "../../components";

const Faqs = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const [getFaq, getFaqData] = useLazyGetFaqQuery();
  const [faqData, setFaqData] = useState<Faq[]>();

  const fetchData = async () => {
    try {
      const res = await getFaq({}).unwrap();
      if (res?.statusCode === 200) {
        setFaqData(res?.data);
      } else if (res.statusCode === 503) {
        errorToast(translation.toastMessages.service_unavailable);
      }
    } catch (error: any) {
      // errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="editprofile">
      <Loader isLoad={getFaqData.isLoading} />
      <h4>{translation.pridictionList.faq}</h4>
      <div className="acc_Contnr">
        <div className="aboutpage">
          {faqData ? (
            faqData.map((item) => {
              return (
                // eslint-disable-next-line no-underscore-dangle
                <div key={item?._id} className="fa_q">
                  <h4
                    className="faq_qtn"
                    dangerouslySetInnerHTML={{
                      __html: item?.question,
                    }}
                  />
                  <p
                    className="faq_ans"
                    dangerouslySetInnerHTML={{
                      __html: item?.answer,
                    }}
                  />
                </div>
              );
            })
          ) : (
            <p className="about_txt2"> Information not found </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
