import React from "react";
import Header from "./header";
import Footer from "./footer";

const Layout = ({
  children,
  setActiveCase,
}: {
  setActiveCase?: any;
  children: any;
}) => {
  return (
    <div>
      <Header />
      {children}
      <Footer setActiveCase={setActiveCase} />
    </div>
  );
};

export default Layout;
