/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { TextField } from "@mui/material";

// eslint-disable-next-line import/no-extraneous-dependencies
import io from "socket.io-client";
import moment from "moment";
import useTranslation from "../../hooks/Translation";
import { useLazyGetChatHistoryQuery } from "../../service/Common";

import { Loader } from "../../components";
import { useAppSelector } from "../../hooks/Store";
import { getToken } from "../../reducers/authSlice";
import useAuth from "../../hooks/UseAuth";
import { ChatResponse } from "../../types/general";

interface DrawerState {
  setSidebarVisible: Dispatch<SetStateAction<boolean>>;
  tournamentId?: string | undefined;
  sidebarVisible: boolean;
  scrollPosition: ScrollPosition;
}

const Chat = ({
  setSidebarVisible,
  tournamentId,
  sidebarVisible,
  scrollPosition,
}: DrawerState) => {
  const socketRef = useRef<SocketIOClient.Socket>();

  const token = useAppSelector(getToken);
  const userData = useAuth();

  const [isConnect, setIsConnect] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [text, setText] = useState("");
  const [chatHistory, setChatHistory] = useState<ChatResponse[]>([]);

  const [getChat] = useLazyGetChatHistoryQuery();

  const translation = useTranslation() as any;

  const scrollToBottom = () => {
    setTimeout(() => {
      const ele = document.getElementById("scrollTo");
      ele?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }, 800);
  };

  const getChatHistory = async () => {
    try {
      setLoading(true);
      const response = await getChat({ tournament_id: tournamentId }).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        setChatHistory(response?.data || []);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getChatHistory();
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarVisible]);

  const sendMessage = async () => {
    const body = {
      chatId: tournamentId,
      type: "TEXT",
      message: text,
    };

    const data = {
      _id: tournamentId || undefined,
      type: "TEXT",
      senderId: {
        _id: userData?._id || undefined,
        image: userData?.image || undefined,
        userName: userData?.userName || undefined,
      },
      message: text,
      chatId: tournamentId || undefined,
      createdAt: new Date(),
    };

    if (socketRef?.current) {
      socketRef?.current.emit("sendMessage", body);
      if (body) {
        setChatHistory([...chatHistory, data]);
        setText("");
        scrollToBottom();
      }
    }
  };

  const joinRoom = () => {
    const data = {
      chatId: tournamentId,
    };

    socketRef.current?.emit("connectToChat", data);

    socketRef.current?.on("connectToChatOk", async (message: any) => {
      console.log(message, "===room jon====");
    });
  };

  const recieveChat = async () => {
    if (socketRef.current) {
      socketRef?.current.on("receiveMessage", async (data: any) => {
        console.log("rood-----------------------", data);
        if (data?.senderId?._id === userData?._id) {
          console.log(" null case  send message==================");
        } else {
          const body = {
            _id: data?._id || undefined,
            type: "TEXT",
            senderId: {
              _id: data?.senderId?._id || undefined,
              image: data?.senderId?.image || undefined,
              userName: data?.senderId?.userName || undefined,
            },
            message: data?.message,
            chatId: data?.chatId || undefined,
            createdAt: new Date(),
          };

          setChatHistory((history) => [...history, body]);
          setText("");
          scrollToBottom();
        }
      });
    }
  };

  const handleMessageInput = (e: any) => {
    setText(e.target.value);
  };

  const keyDownHandler = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  const leaveRoom = async () => {
    const data = {
      chatId: tournamentId,
    };

    socketRef.current?.emit("disConnectToChat", data);
    socketRef.current?.on("disConnectToChatOk", async (message: any) => {
      console.log(message, "===room left====");
    });
  };

  useEffect(() => {
    if (isConnect) {
      joinRoom();
    }
    recieveChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnect]);

  useEffect(() => {
    socketRef.current = io(
      `https://apisportsprediction.appgrowthcompany.com/?token=${token}`
    );

    const socket = socketRef.current;

    // Listens for incoming messages
    if (socket) {
      socketRef.current.on("connect", () => {
        console.log("Chat socket connection1", socketRef.current);
        setIsConnect(true);
      });
    }

    return () => {
      // console.log("disconnect --");
      setIsConnect(false);
      leaveRoom();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="chat_otr">
      <Loader isLoad={loading} />
      <div className="mob_view">
        <i
          className="fa-solid fa-arrow-up-long bck_btn"
          onClick={() => setSidebarVisible(false)}
        />
      </div>
      <h3>{translation.mytourney.message}</h3>
      <div className="chats chat_bx">
        {chatHistory?.length
          ? chatHistory?.map((item) => (
              <div key={item?._id} className="chtss_inr">
                {item?.senderId?._id === userData?._id ? (
                  <div className="outgoing">
                    <div className="inRyt">
                      <p>{item?.message || ""}</p>
                      <div className="date">
                        <h6>{item?.senderId?.userName || ""}</h6>
                        <div className="tick">
                          <h6>{moment(item?.createdAt).format("LT") || ""}</h6>
                          <figure>
                            <LazyLoadImage
                              src="/static/images/tick.png"
                              alt=""
                              effect="blur"
                              scrollPosition={scrollPosition}
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="inLeft">
                      <figure>
                        <LazyLoadImage
                          src={
                            item?.senderId?.image ||
                            "/static/images/user_placeholder.png"
                          }
                          alt=""
                          effect="blur"
                          scrollPosition={scrollPosition}
                        />
                      </figure>
                    </div>
                  </div>
                ) : (
                  <div className="incomng">
                    <div className="inLeft">
                      <figure>
                        <LazyLoadImage
                          src={
                            item?.senderId?.image ||
                            "/static/images/user_placeholder.png"
                          }
                          alt=""
                          effect="blur"
                          scrollPosition={scrollPosition}
                        />
                      </figure>
                      <p>{item?.senderId?.userName || ""}</p>
                      <p>{moment(item?.createdAt).format("LT") || ""}</p>
                    </div>
                    <div className="inRyt">
                      <p>{item?.message || ""}</p>
                    </div>
                  </div>
                )}
              </div>
            ))
          : null}
        <div id="scrollTo" />
      </div>

      <div className="sendBar">
        <TextField
          sx={{ width: "100%" }}
          placeholder={translation.mytourney.write}
          multiline
          onChange={(val) => {
            if (val.target.value === " " || val.target.value === ".") {
              console.log("hiiii");
            } else {
              handleMessageInput(val);
            }
          }}
          type="text"
          value={text}
          onKeyDown={(e) => {
            if (text !== "") {
              keyDownHandler(e);
            }
          }}
        />
        <button
          type="submit"
          onClick={() => {
            if (text !== "") {
              sendMessage();
            }
          }}
        >
          <LazyLoadImage
            src="/static/images/send.svg"
            alt=""
            effect="blur"
            scrollPosition={scrollPosition}
          />
        </button>
      </div>
    </div>
  );
};

export default trackWindowScroll(Chat);
