/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useTranslation from "../../hooks/Translation";
import { useLazyGetNotificationQuery } from "../../service/cms";
import { errorToast } from "../../helpers/ShowToast";
import { Notificationdata } from "../../types/general";
import { Loader } from "../../components";

type NotificationType = {
  scrollPosition: ScrollPosition;
};
const Notification = ({ scrollPosition }: NotificationType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;

  const [getNotification, getNotificationData] = useLazyGetNotificationQuery();
  const [notificationData, setNotificationData] = useState<Notificationdata[]>(
    []
  );

  const fetchData = async () => {
    try {
      const res = await getNotification({}).unwrap();
      if (res?.statusCode === 200) {
        setNotificationData(res?.data);
      } else if (res.statusCode === 503) {
        errorToast(translation.toastMessages.service_unavailable);
      }
    } catch (error: any) {
      // errorToast(error?.data?.message || "");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="editprofile">
      <Loader isLoad={getNotificationData.isLoading} />
      <h4>{translation.pridictionList.notification}</h4>
      <div className="acc_Contnr">
        <div className="notification">
          {notificationData?.length ? (
            notificationData?.map((item) => {
              return (
                <div className="noti" key={item?._id}>
                  <div className="notify">
                    <div className="notifleft">
                      <figure>
                        <LazyLoadImage
                          src="/static/images/football.png"
                          alt=""
                          effect="blur"
                          height="100%"
                          width="100%"
                          scrollPosition={scrollPosition}
                        />
                      </figure>
                    </div>
                    <div className="notifRyt">
                      <h5>{item?.title}</h5>
                      <p>{item?.message}</p>
                      <h5>
                        {moment(item?.createdAt).format(
                          "DD-MM-YYYY [at] hh:mm A"
                        )}
                      </h5>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="about_txt2"> No data found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default trackWindowScroll(Notification);
