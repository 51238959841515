/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import { errorToast, successToast } from "../../constants";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { usePostResetPasswordMutation } from "../../service/Auth";
import { generateSEKAndHash } from "../../utils/crypto";
import useTranslation from "../../hooks/Translation";
import { errorToast, successToast } from "../../helpers/ShowToast";
import { Loader } from "../../components";

type props = {
  scrollPosition: ScrollPosition;
};
const ResetPassword = ({ scrollPosition }: props) => {
  const navigate = useNavigate();
  const deviceId = navigator.userAgent;
  const translation = useTranslation() as any;

  const [ResetPasswordMutaion, ResetPasswordMutaionData] =
    usePostResetPasswordMutation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  // formik
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()

        .required(translation.validations.password_required)
        .label("Password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          translation.validations.pass_valid
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], translation.validations.confirm_pass)
        .required(translation.validations.confirmpass_req),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      const data = {
        password: formik.values.password,
        // eslint-disable-next-line object-shorthand
        deviceId: deviceId,
        appkey: new Date().toISOString(),
      };
      const body = generateSEKAndHash(data);
      if (body) {
        try {
          const response = await ResetPasswordMutaion(body).unwrap();
          if (response?.statusCode === 200) {
            successToast(response?.message || "");
            navigate("/login", { replace: true });
          } else if (response?.statusCode === 503) {
            errorToast(translation.toastMessages.service_unavailable);
          }
        } catch (error: any) {
          if (error?.data?.message) {
            errorToast(error?.data?.message || "");
          } else {
            errorToast(translation.toastMessages.no_internet);
          }
        }
      }
    },
  });

  return (
    <section className="signUpmain">
      <Loader isLoad={ResetPasswordMutaionData.isLoading} />
      <div className="container">
        <div className="sigup">
          <Link to="/">
            <figure>
              <LazyLoadImage
                src="/static/images/frediction.png"
                alt=""
                effect="blur"
                height="100%"
                width="100%"
                scrollPosition={scrollPosition}
              />
            </figure>
          </Link>
          <div className="close" onClick={() => navigate("/login")}>
            <figure>
              <LazyLoadImage
                src="/static/images/close-square.svg"
                alt=""
                effect="blur"
                height="100%"
                width="100%"
                scrollPosition={scrollPosition}
              />
            </figure>
          </div>
          <div className="forgotcontainer">
            <h4>{translation.auth.reset_password}</h4>
            <form onSubmit={formik.handleSubmit}>
              <div className="inputdiv">
                <TextField
                  placeholder={translation.auth.new_pass}
                  fullWidth
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  className="txt_fld"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className="iconador"
                        sx={{ top: "27px" }}
                      >
                        <IconButton
                          sx={{ color: "white" }}
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPassword(!showPassword);
                            const inputEl: HTMLInputElement | null =
                              document.getElementById(
                                "confirmNewPassword"
                              ) as HTMLInputElement | null;
                            if (inputEl) {
                              setTimeout(() => {
                                inputEl.setSelectionRange(
                                  inputEl.value.length,
                                  inputEl.value.length
                                );
                              }, 0);
                            }
                          }}
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  placeholder={translation.auth.confirmpass}
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  className="txt_fld"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className="iconador"
                        sx={{ top: "27px" }}
                      >
                        <IconButton
                          sx={{ color: "white" }}
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowConfirmPassword(!showConfirmPassword);
                            const inputEl: HTMLInputElement | null =
                              document.getElementById(
                                "confirmNewPassword"
                              ) as HTMLInputElement | null;
                            if (inputEl) {
                              setTimeout(() => {
                                inputEl.setSelectionRange(
                                  inputEl.value.length,
                                  inputEl.value.length
                                );
                              }, 0);
                            }
                          }}
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <button
                  className="btn submit_btn mtm_80"
                  type="submit"
                  style={{ width: "100%" }}
                  disabled={formik.isSubmitting}
                >
                  {translation.Globals.continue}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default trackWindowScroll(ResetPassword);
