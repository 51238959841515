/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./LeaderBoard.scss";
import moment from "moment";
import Layout from "../../layout";
import useTranslation from "../../hooks/Translation";

import { Filter } from "../../features";
import {
  useLazyGetLeaderBoardByIdQuery,
  useLazyGetLeaderBoardByPlayerNameQuery,
} from "../../service/Leaderboard";
import { PlayersData, TeamsData } from "../../types/general";
import useAuth from "../../hooks/UseAuth";
import { Loader } from "../../components";

type LeaderBoardType = {
  scrollPosition: ScrollPosition;
};
const LeaderBoard = ({ scrollPosition }: LeaderBoardType) => {
  const navigate = useNavigate();
  const translation = useTranslation() as any;
  const [activeCls, setActiveCls] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [teamsData, setTeamsData] = useState<TeamsData[]>([]);
  const [playersData, setPlayersData] = useState<PlayersData[]>([]);
  const [userRank, setUserRank] = useState<number>(0);
  const [selectedCat, setSelectedCat] = useState([] as any);
  const [selectedTournament, setSelectedTournament] = useState([] as any);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const location = useLocation();
  const { state } = location;

  const userData = useAuth();

  const [getLeaderboardByIdData] = useLazyGetLeaderBoardByIdQuery();
  const [getPlayersByNameMethod] = useLazyGetLeaderBoardByPlayerNameQuery();

  const getTeamsByIdData = async (key: string) => {
    try {
      setLoading(true);
      if (state?.teamId) {
        const res = await getLeaderboardByIdData({
          id: state?.teamId,
          category: !key && selectedCat?.length ? selectedCat : [],
          toDate: !key && endDate ? moment(endDate).format("YYYY-MM-DD") : null,
          fromDate:
            !key && startDate ? moment(startDate).format("YYYY-MM-DD") : null,
          tournament:
            !key && selectedTournament?.length ? selectedTournament : [],
        }).unwrap();
        setLoading(false);
        if (res?.statusCode === 200) {
          setTeamsData(res?.data || undefined);
          if (res?.data?.length) {
            const userRank2 = res?.data?.findIndex(
              (item: TeamsData) => item?.user?._id === userData?._id
            );

            setUserRank(userRank2 + 1 || 0);
          }
        }
      } else {
        const res = await getPlayersByNameMethod({
          name: state?.playerId,
          category: !key && selectedCat?.length ? selectedCat : [],
          toDate: !key && endDate ? moment(endDate).format("YYYY-MM-DD") : null,
          fromDate:
            !key && startDate ? moment(startDate).format("YYYY-MM-DD") : null,
          tournament:
            !key && selectedTournament?.length ? selectedTournament : [],
        }).unwrap();
        setLoading(false);
        if (res?.statusCode === 200) {
          setPlayersData(res?.data || undefined);
        }
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getTeamsByIdData("clear");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalPlayerPoints = playersData?.reduce(
    (acc, obj) => acc + obj?.leaderboardPoints,
    0
  );
  const totalTeamsPoints = teamsData?.reduce((acc, obj) => acc + obj.points, 0);

  return (
    <Layout>
      <main className="ldrbrd_wrp ">
        <Loader isLoad={loading} />
        <div className="ldrbrd nv_spc container">
          <div className="fltr_wrp_mob">
            <div className="mob_view">
              <i
                className="fa-solid fa-arrow-up-long bck_btn"
                onClick={() => navigate("/leaderboarddetails")}
              />
            </div>
            <h2 className="hdng">{translation.leaderBoard.leaderBoard}</h2>

            <div
              className="fil_sec_mob"
              onClick={() => setActiveCls(!activeCls)}
            >
              <figure>
                <LazyLoadImage
                  src="/static/img/filter_icon.svg"
                  alt="filter icon"
                  effect="blur"
                  height="100%"
                  width="100%"
                  scrollPosition={scrollPosition}
                />
              </figure>
              <p>{translation.leaderBoard.filters}</p>
            </div>
          </div>
          <div
            className="tm_pnts"
            onClick={() => navigate("/leaderboarddetails")}
          >
            {state?.playerId ? (
              <div className="pnts_lft">
                <figure className="leaderbrd_imgs">
                  <LazyLoadImage
                    src={
                      state?.playerImage ||
                      "/static/images/user_placeholder.png"
                    }
                    alt=""
                    effect="blur"
                    height="100%"
                    width="100%"
                    scrollPosition={scrollPosition}
                  />
                </figure>
                <h2 className="tm_nm">{state?.playerName || ""}</h2>
              </div>
            ) : (
              <div className="pnts_lft">
                <figure className="leaderbrd_imgs">
                  <LazyLoadImage
                    src={state?.teamLogo || "/static/images/team4.png"}
                    alt=""
                    effect="blur"
                    height="100%"
                    width="100%"
                    scrollPosition={scrollPosition}
                  />
                </figure>
                <h2 className="tm_nm">{state?.teamName || ""}</h2>
              </div>
            )}{" "}
            <span />
            <div className="pnts_ryt">
              <h2 className="yellow_txt pnts">
                {totalTeamsPoints || totalPlayerPoints || 0} Pts
              </h2>
            </div>
          </div>
          <div className="mn_ldrbrd tourmain ">
            <Filter
              setSelectedCat={setSelectedCat}
              selectedCat={selectedCat}
              setSelectedTournament={setSelectedTournament}
              selectedTournament={selectedTournament}
              setActiveCls={setActiveCls}
              activeCls={activeCls}
              getTeamsByIdData={getTeamsByIdData}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            {state?.playerId ? (
              <div className="btmdiv trRyt ">
                <div className="users table_res">
                  <table>
                    <thead className="btm_div2">
                      <tr>
                        <th>{translation.leaderBoard.tounrney_dt}</th>
                        <th style={{ textAlign: "center" }}>
                          {translation.leaderBoard.position}
                        </th>
                        <th style={{ textAlign: "center" }}>
                          {" "}
                          {translation.leaderBoard.cate_gory}
                        </th>

                        <th style={{ textAlign: "center" }}>
                          {translation.leaderBoard.points}
                        </th>
                        <th style={{ textAlign: "center" }}>
                          Leaderboard <br /> Points
                        </th>
                      </tr>
                    </thead>
                    {playersData?.length
                      ? playersData?.map((item) => {
                          return (
                            <tbody key={item?._id}>
                              <tr>
                                <td>
                                  <div className="user" key={item?._id}>
                                    <div className="userleft">
                                      <figure className="trphy">
                                        <LazyLoadImage
                                          src={
                                            item?.tournamentId?.image ||
                                            "/static/images/user_placeholder.png"
                                          }
                                          alt=""
                                          effect="blur"
                                          height="100%"
                                          width="100%"
                                          scrollPosition={scrollPosition}
                                        />
                                      </figure>
                                    </div>
                                    <div className="ldr_brd_prsn">
                                      <h4>{item?.tournamentId?.name || ""}</h4>
                                      {/* <p>{item.date}</p> */}
                                    </div>
                                  </div>
                                </td>

                                <td
                                  style={{ textAlign: "center" }}
                                  className="points"
                                >
                                  {item?.position || ""}
                                </td>

                                <td className="points">
                                  {" "}
                                  <div>{item?.category?.name || ""}</div>
                                </td>

                                <td className="points">
                                  {item?.points || "0"}
                                </td>
                                <td className="points">
                                  {item?.leaderboardPoints || "0"}
                                </td>
                              </tr>
                            </tbody>
                          );
                        })
                      : null}
                  </table>
                </div>
              </div>
            ) : (
              <div className="brd_lft">
                <div className="rank_imgs">
                  {teamsData?.length && teamsData?.length > 1 ? (
                    <div className="ranks img_top">
                      <figure>
                        <LazyLoadImage
                          src={
                            teamsData[1]?.user?.image ||
                            "/static/images/user_placeholder.png"
                          }
                          alt=""
                          effect="blur"
                          height="100%"
                          width="100%"
                          scrollPosition={scrollPosition}
                        />
                      </figure>
                      <p>{translation.leaderBoard.rank2}</p>
                    </div>
                  ) : null}
                  {teamsData?.length ? (
                    <div className="ranks img_top">
                      <figure>
                        <LazyLoadImage
                          src={
                            teamsData[0]?.user?.image ||
                            "/static/images/user_placeholder.png"
                          }
                          alt=""
                          effect="blur"
                          height="100%"
                          width="100%"
                          scrollPosition={scrollPosition}
                        />
                      </figure>
                      <p>{translation.leaderBoard.rank1}</p>
                    </div>
                  ) : null}
                  {teamsData?.length && teamsData?.length > 2 ? (
                    <div className="ranks img_top">
                      <figure>
                        <LazyLoadImage
                          src={
                            teamsData[2]?.user?.image ||
                            "/static/images/user_placeholder.png"
                          }
                          alt=""
                          effect="blur"
                          height="100%"
                          width="100%"
                          scrollPosition={scrollPosition}
                        />
                      </figure>
                      <p>{translation.leaderBoard.rank3}</p>
                    </div>
                  ) : null}
                </div>
                <div className="yr_rnk">
                  {/* <p>{translation.leaderBoard.your_rank}</p> */}
                  <p>
                    {translation.leaderBoard.your_rank} {userRank} (
                    {teamsData?.length} {translation.leaderBoard.players})
                  </p>
                </div>
                <div className="userlisting">
                  <div className="head">
                    <h5>{translation.mytourney.rank}</h5>
                    <h5>{translation.leaderBoard.total}</h5>
                  </div>
                  <div className="users fdfdf">
                    {teamsData?.length
                      ? teamsData?.map((item) => {
                          return (
                            <div className="user" key={item?.user?._id}>
                              <div className="userleft">
                                <figure>
                                  <LazyLoadImage
                                    src={
                                      item?.image ||
                                      "/static/images/user_placeholder.png"
                                    }
                                    alt=""
                                    effect="blur"
                                    height="100%"
                                    width="100%"
                                    scrollPosition={scrollPosition}
                                  />
                                </figure>
                                <h4>{item?.userName || ""}</h4>
                              </div>
                              <h3>{item?.points || "0"}</h3>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            )}
          </div>{" "}
        </div>
      </main>
    </Layout>
  );
};

export default trackWindowScroll(LeaderBoard);
