/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Layout from "../../layout";
import useTranslation from "../../hooks/Translation";
import "./MyTourneys.scss";
import "../../components/Components.scss";
import { LiveMatchDetail, MatchCardTabs, MatchDetail } from "../../features";
import {
  useLazyGetTourneysQuery,
  useLazyGetTournamentDetailsQuery,
  useLazyGetSeasonDetailsQuery,
} from "../../service/tournaments";
import { errorToast } from "../../helpers/ShowToast";
import {
  Season,
  Seasons,
  Tournaments,
  TourneyDetails,
} from "../../types/general";
import { Loader } from "../../components";
import useAuth from "../../hooks/UseAuth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 2,
  outline: "none",
  borderRadius: 3,
  background: "white",
};
const MyTourneys = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const [open, setOpen] = useState(false);
  const [newCls, setNewCls] = useState<boolean>(false);
  const [seasonData, setSeasonData] = useState<Season[]>([]);
  const [torunament, setTorunament] = useState("Tournament");
  const [matchDetail, setMatchDetail] = useState<TourneyDetails>();
  const [selectedMatchId, setSelectedMatchId] = useState<string>("");
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");
  const [tourneyData, setTourneyData] = useState<Tournaments[]>([]);
  const [activeTab, setActiveTab] = useState<string>("upcoming");
  const [seasonalData, setSeasonalData] = useState<Seasons>();
  const [loading, setLoading] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<number>(-1);
  const [cardFirstDate, setCardFirstDate] = useState<
    string | null | undefined
  >();
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const [getSeason] = useLazyGetSeasonDetailsQuery();
  const [getTourneys] = useLazyGetTourneysQuery();
  const [getTourneyDetailData] = useLazyGetTournamentDetailsQuery();
  const user = useAuth();
  const handleClose = () => setOpen(false);
  const userEntries = matchDetail?.userNames?.filter(
    (item) => item?.userName?.split("_")[0] === user?.userName
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countDown]);

  useEffect(() => {
    if (countDown === 0) {
      setOpen(false);
      window.location.reload();
    }
  }, [countDown]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getTourneys({ type: activeTab }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setSeasonData(res?.data?.seasons);
        setTourneyData(res?.data?.tournaments);
        if (res?.data?.tournaments?.length) {
          await getTournamentDetails(res?.data?.tournaments);
        } else if (res?.data?.seasons?.length) {
          await getSeasonalDetail(res?.data?.seasons[0]?._id);
        } else {
          setSelectedMatchId("");
          setSelectedCategoryId("");
          setMatchDetail(undefined);
          setSeasonalData(undefined);
          setTorunament("");
        }
      } else if (res.statusCode === 503) {
        errorToast(translation.toastMessages.service_unavailable);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  const getSeasonalDetail = async (_id: any) => {
    try {
      setLoading(true);
      setMatchDetail(undefined);
      const response = await getSeason({ banner_id: _id }).unwrap();
      setLoading(false);

      if (response?.statusCode === 200) {
        setSelectedMatchId(_id);
        setTorunament("seasonal");
        setSeasonalData(response?.data);
      }
    } catch (error: any) {
      setLoading(false);
      // errorToast(error?.data?.message || "");
    }
  };

  const getTournamentDetails = async (data: Tournaments[]) => {
    if (data?.length && data[0]?.fixtures?.length) {
      try {
        setLoading(true);
        setSeasonalData(undefined);
        setMatchDetail(undefined);
        const response = await getTourneyDetailData({
          type: data[0]?.isLive ? "live" : activeTab,
          id: data?.length ? data[0]?._id : "",
          fixtureId: data[0]?.isLive ? data[0]?.fixtures[0]?.fixture_id : null,
          categoryId: data?.length ? data[0]?.categoryId?._id : "",
        }).unwrap();
        setLoading(false);

        if (response?.statusCode === 200) {
          setTorunament("Tournament");
          setSelectedMatchId(data[0]?._id);
          setSelectedCategoryId(data[0]?.categoryId?._id);
          setMatchDetail(response?.data);
        } else if (response.statusCode === 503) {
          errorToast(translation.toastMessages.service_unavailable);
        }
      } catch (error: any) {
        console.log(error);
        if (error?.originalStatus === 429) {
          setCountDown(59);
          setOpen(true);
        }
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY;
    const bodyHeight = document.body.offsetHeight;

    if (scrollY + windowHeight >= bodyHeight) {
      setIsScrolledToBottom(true);
    } else {
      setIsScrolledToBottom(false);
    }
  };

  const handleScrollDown = () => {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    });
  };
  const handleScrollUp = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Layout>
      <Loader isLoad={loading} />
      <div className="nv_spc cmn_bg tornmain cms">
        <div className="container">
          <div className="sb">
            <div className="mob_view">
              <i
                className="fa-solid fa-arrow-up-long bck_btn mt_19"
                onClick={() => navigate("/")}
              />
            </div>
            <h2>{translation.mytourney.tourney}</h2>
          </div>
          <div className="tourmain">
            <div className="trLft ">
              <MatchCardTabs
                loading={loading}
                setLoading={setLoading}
                setTorunament={setTorunament}
                setCardFirstDate={setCardFirstDate}
                seasonData={seasonData}
                setSeasonData={setSeasonData}
                setTourneyData={setTourneyData}
                tourneyData={tourneyData}
                setSelectedMatchId={setSelectedMatchId}
                selectedCategoryId={selectedCategoryId}
                setSelectedCategoryId={setSelectedCategoryId}
                setNewCls={setNewCls}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                setSeasonalData={setSeasonalData}
                setMatchDetail={setMatchDetail}
                selectedMatchId={selectedMatchId}
                userEntries={userEntries}
                setCountDown={setCountDown}
                countDown={countDown}
                setOpen={setOpen}
                open={open}
              />
            </div>

            {(torunament === "Tournament" && matchDetail) ||
            (torunament === "seasonal" && seasonalData) ? (
              <div className={newCls ? "trRyt added" : "trRyt"}>
                <div className="top_hdng" onClick={() => setNewCls(false)}>
                  <div className="mob_view">
                    <i className="fa-solid fa-arrow-up-long bck_btn" />
                  </div>
                </div>
                {torunament === "Tournament" ? (
                  // for  tournament detail
                  <MatchDetail
                    setSelectedMatchId={setSelectedMatchId}
                    cardId={selectedMatchId}
                    selectedCategoryId={selectedCategoryId}
                    matchDetail={matchDetail}
                    seasonData={seasonData}
                    tourneyData={tourneyData}
                    setMatchDetail={setMatchDetail}
                    activeTab={activeTab}
                    cardFirstDate={cardFirstDate}
                    setCardFirstDate={setCardFirstDate}
                    userEntries={userEntries}
                  />
                ) : (
                  // for season detail
                  <LiveMatchDetail
                    setSelectedMatchId={setSelectedMatchId}
                    cardId={selectedMatchId}
                    seasonData={seasonData}
                    tourneyData={tourneyData}
                    seasonalData={seasonalData}
                  />
                )}
              </div>
            ) : !loading ? (
              <div
                style={{ width: "100%" }}
                className="no_data mob_dsply web_nodata"
              >
                {/* You haven’t joined any tournament yet. Hurry up, Join Now!! */}
              </div>
            ) : null}
          </div>
        </div>
        {!loading && seasonData?.length === 0 && tourneyData?.length === 0 ? (
          <p className="no_data mob_nodata">
            You haven’t joined any tournament yet. Hurry up, Join Now!!
          </p>
        ) : null}
      </div>

      <div className="arrw_btn_trny">
        {isScrolledToBottom ? (
          <KeyboardArrowUpIcon onClick={handleScrollUp} />
        ) : (
          <KeyboardArrowDownIcon onClick={handleScrollDown} />
        )}
      </div>
    </Layout>
  );
};

export default MyTourneys;
