/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PredictionCardType } from "../types/general";

const UserPridictionCard = ({
  timeLeft,
  items,
  scrollPosition,
}: PredictionCardType) => {
  return (
    <div>
      {/* <p>{created_at}</p> */}

      <div
        className={items?.isFavourite ? "matchteams match_tms" : "matchteams"}
      >
        <div className="team1">
          <figure>
            <LazyLoadImage
              src={items?.homeTeam_logo}
              alt=""
              effect="blur"
              scrollPosition={scrollPosition}
            />
          </figure>
          <div className="scro">
            <h2>{items?.homeTeam}</h2>
            <h3>{items?.homeTeam_goals}</h3>
          </div>
        </div>
        {items?.isFavourite ? (
          <div>
            <figure className="str_drw">
              <LazyLoadImage
                src="/static/images/white_star_new.svg"
                alt="star"
                effect="blur"
                scrollPosition={scrollPosition}
              />
            </figure>
          </div>
        ) : null}
        <div className="centerdiv">
          <figure className="vs">
            <LazyLoadImage
              src="/static/images/vs2.png"
              alt=""
              effect="blur"
              scrollPosition={scrollPosition}
            />
          </figure>
          <p>{timeLeft}</p>
        </div>
        <div className="team1 team2">
          <div className="scro">
            <h2>{items?.awayTeam}</h2>
            <h3>{items?.awayTeam_goals}</h3>
          </div>
          <figure>
            <LazyLoadImage
              src={items?.awayTeam_logo}
              alt=""
              effect="blur"
              scrollPosition={scrollPosition}
            />
          </figure>
        </div>
      </div>
    </div>
  );
};
export default trackWindowScroll(UserPridictionCard);
