/* eslint-disable react/no-danger */
import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../layout";
import useTranslation from "../../hooks/Translation";
import { useAppSelector } from "../../hooks/Store";
import { getCms } from "../../reducers/generalSlice";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  const translation = useTranslation() as any;
  const getCmsData = useAppSelector(getCms);
  const navigate = useNavigate();
  return (
    <Layout>
      <div className=" nv_spc cms ">
        <div className="container">
          <div className="mob_view">
            <i
              className="fa-solid fa-arrow-up-long bck_btn"
              onClick={() => navigate("/")}
            />
            <h2 className="cms_hdng">{translation.Globals.privacy}</h2>
          </div>

          <p
            className={getCmsData ? "policy_txt" : "policy_txt2"}
            dangerouslySetInnerHTML={{
              __html: getCmsData
                ? getCmsData?.privacy
                : translation.Globals.no_data,
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
