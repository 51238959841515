/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
import { Box, SwipeableDrawer } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useTranslation from "../../hooks/Translation";
import { TokenCategories } from "../../reducers/tournamentSlice";
import { Tournaments } from "../../types/general";

type PredictionDrawerType = {
  tokenCategories: TokenCategories[];
  sidebarVisible: boolean;
  setSidebarVisible: Dispatch<SetStateAction<boolean>>;
  tokenCategoriesArr: string[];
  setTokenCategoriesArr: Dispatch<SetStateAction<string[]>>;
  handleSubmit: () => void;
  tournamentFixtures: Tournaments[];
  totalToken: number;
  setTotalToken: Dispatch<SetStateAction<number>>;
  tournamentName?: string | undefined;
  tournamentImage?: string | undefined;
  saveType?: string;
  scrollPosition: ScrollPosition;
};

const PredictionDrawer = ({
  tokenCategories,
  setSidebarVisible,
  sidebarVisible,
  setTokenCategoriesArr,
  tokenCategoriesArr,
  handleSubmit,
  tournamentFixtures,
  totalToken,
  setTotalToken,
  tournamentName,
  tournamentImage,
  saveType,
  scrollPosition,
}: PredictionDrawerType) => {
  const translation = useTranslation() as any;

  const handleToggleTournament = (tournamentId: string) => {
    if (tokenCategoriesArr.includes(tournamentId)) {
      setTokenCategoriesArr(
        tokenCategoriesArr.filter((id) => id !== tournamentId)
      );
    } else {
      setTokenCategoriesArr([...tokenCategoriesArr, tournamentId]);
    }
  };

  const isTournamentSelected = (tournamentId: string) => {
    return tokenCategoriesArr.includes(tournamentId);
  };
  const checkToken = () => {
    setTotalToken(0);
    const selectedTokenIds = tokenCategoriesArr.map((token) => token);
    let sum = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const item of tokenCategories) {
      if (selectedTokenIds.includes(item._id)) {
        // eslint-disable-next-line no-plusplus
        sum += parseInt(item?.tokens, 10);
        setTotalToken(sum);
      }
    }
  };
  const sortedTokenCategories = [...tokenCategories].sort(
    (a, b) => parseInt(a.tokens, 10) - parseInt(b.tokens, 10)
  );
  useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenCategoriesArr]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setSidebarVisible(open);
    };

  const list = () => (
    <Box className="prdctn_drwr prdctn_drwr_auto " role="presentation">
      <div className="pridt_mn">
        <div className="pridt_tp">
          <div className="sb">
            <div className="mob_view">
              <i
                className="fa-solid fa-arrow-up-long bck_btn mt_19"
                onClick={() => setSidebarVisible(false)}
              />
            </div>
            <h2>{translation.predictions.predict}</h2>
            <p className="pritd_par">{tournamentName || ""}</p>
          </div>
        </div>

        <div className="pridt_crd">
          {sortedTokenCategories?.map((item, index) => {
            return (
              <div>
                <div
                  key={item._id}
                  className="prdctn_crd"
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    handleToggleTournament(item?._id);
                  }}
                >
                  {isTournamentSelected(item?._id) && (
                    <figure className="itm_chkd">
                      <LazyLoadImage
                        src="/static/images/check_black.svg"
                        alt=""
                        effect="blur"
                        height="100%"
                        width="100%"
                        scrollPosition={scrollPosition}
                      />
                    </figure>
                  )}
                  <div className="crd_lt">
                    {" "}
                    <figure>
                      <LazyLoadImage
                        src={tournamentImage || "/static/images/team1.png"}
                        alt=""
                        effect="blur"
                        height="100%"
                        width="100%"
                        scrollPosition={scrollPosition}
                      />{" "}
                    </figure>
                    <div className="crd_lt_cntn">
                      <h3>
                        <span className="blk_span">{tournamentName || ""}</span>
                        {item?.name || ""}
                        <span className="blk_span">
                          Pot Size: {item?.totalPotSize || 0}
                        </span>
                      </h3>

                      {/* <h3>
                        <span className="blk_span">Total Pot:</span>
                      </h3> */}
                    </div>
                  </div>
                  <div className="crd_rt">
                    <h2>
                      {item?.tokens || ""}{" "}
                      <span className="blk_span">
                        {translation.Globals.tokens}
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="fixed_btm">
          {/* {index === tokenCategories?.length - 1 && ( */}
          <div className="pred_btm">
            <h3>{translation.predictions.totalTokens}</h3>
            <h2>{totalToken}</h2>
          </div>
          {/* )} */}
          <button className="btn" type="button" onClick={handleSubmit}>
            {translation.Globals.submit}
          </button>
        </div>
      </div>
    </Box>
  );
  return (
    <div>
      <SwipeableDrawer
        className="drwr"
        anchor="right"
        open={sidebarVisible}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
};

export default trackWindowScroll(PredictionDrawer);
