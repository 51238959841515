// eslint-disable-next-line import/no-extraneous-dependencies
import secureLocalStorage from "react-secure-storage";

export const STORAGE_KEYS = {
  token: "TOKEN",
  userData: "USER_DATA",
  credentials: "REMEMBER_ME",
  tempToken: "TEMP_TOKEN",
  fcmToken: "FCM_TOKEN",
  language: "LANGUAGE",
  tokenCategories: "TOKEN_CATEGORIES",
};

export const setToStorage = (key: string, data: any) => {
  secureLocalStorage.setItem(key, data);
};

export const getFromStorage = (key: string) => {
  return secureLocalStorage.getItem(key);
};

export const removeFromStorage = (key: string) => {
  secureLocalStorage.removeItem(key);
};
