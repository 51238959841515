/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./Footer.scss";
import useTranslation from "../../hooks/Translation";

type FooterType = {
  setActiveCase: any;
  scrollPosition: ScrollPosition;
};
const Footer = ({ setActiveCase, scrollPosition }: FooterType) => {
  const translation = useTranslation() as any;
  const path = window.location.pathname;
  const navigate = useNavigate();
  const handleContactUs = () => {
    if (window.location.pathname === "/userprofile") {
      setActiveCase(4);
    } else {
      navigate("/userprofile", { state: "contactUs" });
    }
  };
  return (
    <footer className={path === "/" ? "ftr mob_vw" : "ftr"}>
      <div className="container mn_ftr">
        <div className="ftr_col ftr_lft">
          <LazyLoadImage
            src="/static/images/frediction.png"
            alt=""
            effect="blur"
            height="100%"
            width="100%"
            scrollPosition={scrollPosition}
          />
        </div>
        <div className="ftr_col ftr_mdl">
          <div className="scl">
            {/* <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
            > */}
            <LazyLoadImage
              src="/static/images/facebook.png"
              alt=""
              effect="blur"
              height="100%"
              width="100%"
              scrollPosition={scrollPosition}
            />
            {/* </a> */}
          </div>
          <div className="scl">
            {/* <a
              href="https://twitter.com/Twitter"
              target="_blank"
              rel="noopener noreferrer"
            > */}
            <LazyLoadImage
              src="/static/images/twitter.png"
              alt=""
              effect="blur"
              height="100%"
              width="100%"
              scrollPosition={scrollPosition}
            />
            {/* </a> */}
          </div>
          <div className="scl">
            {/* <a
              href="https://in.linkedin.com/"
              target="_blank"
              rel="noopener noreferrer"
            > */}
            <LazyLoadImage
              src="/static/images/instagram.png"
              alt=""
              effect="blur"
              height="100%"
              width="100%"
              scrollPosition={scrollPosition}
            />
            {/* </a> */}
          </div>
          <div className="scl">
            {/* <a
              href="https://www.youtube.com/"
              target="_blank"
              rel="noopener noreferrer"
            > */}
            <LazyLoadImage
              src="/static/images/youtube.png"
              alt=""
              effect="blur"
              height="100%"
              width="100%"
              scrollPosition={scrollPosition}
            />
            {/* </a> */}
          </div>
        </div>
        <div className="ftr_col ftr_ryt">
          <div onClick={() => navigate("/termsandconditions")}>
            <h6>{translation.Globals.terms}</h6>
          </div>
          <div className="ftr_col ftr_ryt">
            <div onClick={() => navigate("/privacypolicy")}>
              <h6>{translation.footer.privacy_policy}</h6>
            </div>
            <div onClick={handleContactUs}>
              <h6>{translation.footer.contact_us}</h6>
            </div>
            <div
              onClick={() => {
                navigate("/userprofile", { state: "about_us" });
              }}
            >
              <h6>About us</h6>
            </div>
            <div
              onClick={() => {
                navigate("/userprofile", { state: "faq" });
              }}
            >
              <h6>Faq</h6>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default trackWindowScroll(Footer);
