import { Box, SwipeableDrawer } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import InviteFriend from "./InviteFriend";
import Chat from "./Chat";
import UserPridiction from "./UserPridiction";
import MatchDetailDrawer from "./MatchDetailDrawer";

type DrawerProps = {
  sidebarVisible: boolean;
  ActiveDrawer: number;
  setSidebarVisible: Dispatch<SetStateAction<boolean>>;
  predictions: any;
  userName: string;
  userImage: string;
  tournamentId?: string | undefined;
  selectedFixtureId?: string | undefined;
};

const RightDrawer = ({
  sidebarVisible,
  setSidebarVisible,
  predictions,
  ActiveDrawer,
  userName,
  userImage,
  tournamentId,
  selectedFixtureId,
}: DrawerProps) => {
  const [backgroundColor, setBackgroundColor] = useState("transparent");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const headerHeight = 60; // Adjust this value as needed

      if (scrollPosition > headerHeight) {
        setBackgroundColor("#22252d"); // Replace 'your-color' with the desired background color
      } else {
        setBackgroundColor("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setSidebarVisible(open);
    };

  const list = () => {
    switch (ActiveDrawer) {
      case 1:
        return (
          <InviteFriend
            setSidebarVisible={setSidebarVisible}
            tournamentId={tournamentId || ""}
          />
        );
      case 2:
        return (
          <Chat
            tournamentId={tournamentId}
            setSidebarVisible={setSidebarVisible}
            sidebarVisible={sidebarVisible}
            // seasonalId={seasonalId}
          />
        );
      case 3:
        return (
          <UserPridiction
            setSidebarVisible={setSidebarVisible}
            predictions={predictions}
            userName={userName}
            userImage={userImage}
          />
        );
      case 4:
        return (
          <MatchDetailDrawer
            selectedFixtureId={selectedFixtureId}
            setSidebarVisible={setSidebarVisible}
            sidebarVisible={sidebarVisible}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <SwipeableDrawer
        className="drwr"
        anchor="right"
        open={sidebarVisible}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
      >
        <Box
          className="drwr"
          sx={{ width: "100%", height: "100%", background: "#1B1B1B" }}
          role="presentation"
        >
          {list()}
        </Box>
      </SwipeableDrawer>
    </div>
  );
};

export default RightDrawer;
