/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Layout from "../../layout";
import useTranslation from "../../hooks/Translation";
import "./Entries.scss";
import { useLazyGetMyPredictionsQuery } from "../../service/tournaments";
import { errorToast } from "../../helpers/ShowToast";
import { PredictionLst, myPrediction } from "../../types/general";
import { Loader } from "../../components";

const Entries = () => {
  const { id } = useParams();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const location = useLocation();
  const [getPrediction] = useLazyGetMyPredictionsQuery();
  const [myPredictions, setMyPredictions] = useState<PredictionLst[]>();
  const [loading, setLoading] = useState(false);

  const { state } = location;

  const getUserPridiction = async (_id: any) => {
    try {
      setLoading(true);
      const response = await getPrediction({
        user_id: _id,
        categoryId: state?.categoryId,
      }).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        setMyPredictions(response?.data);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getUserPridiction(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEntryClick = (item: any) => {
    if (
      state?.userData?.page === "match_Detail" ||
      state === "save_new_entry"
    ) {
      navigate(`/predictions/${id}`, {
        state: {
          // eslint-disable-next-line object-shorthand
          id: id,
          key: "entries",
          userName: item?.userName,
          isLive: state?.live,
          tournamentName: state?.tournamentName,
          tournamentImage: state?.tournamentImage,
          tournamentFixtures: state?.tournamentFixtures,
          cutoff: state?.cutoff,
          categoryId: state?.categoryId,
        },
      });
    } else {
      navigate("/predictions", { state });
    }
  };

  return (
    <Layout>
      <Loader isLoad={loading} />
      <div className="nv_spc entrymain cms">
        <div className="container">
          <div className="sb">
            <div className="mob_view" onClick={() => navigate("/mytourneys")}>
              <i className="fa-solid fa-arrow-up-long bck_btn" />
            </div>
            <h2 className="hdng">{translation.entries.yourentry}</h2>
          </div>
          <div className="entrydiv">
            {myPredictions?.length
              ? myPredictions.map((item, index) => {
                  return (
                    <div
                      className="entry"
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={index}
                      onClick={() => handleEntryClick(item)}
                    >
                      <h2>{item?.userName}</h2>
                      <h2 style={{ color: "#ecac35" }}>
                        {item?.tournament_id?.name || ""}
                      </h2>
                      <h4>{moment(item?.createdAt).format("HH:mm")}</h4>
                      <h2>{`${item?._id?.tokens} Tokens` || ""}</h2>
                      <h3 style={{ color: "#ecac35" }}>
                        <span>{translation.entries.lastsaved}</span>
                        {moment(item?.updatedAt).format("DD/MM/YYYY")}
                      </h3>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Entries;
