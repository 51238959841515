/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, MatchCard } from "../../components";
import { setToStorage, STORAGE_KEYS } from "../../helpers/Storage";
import { useAppDispatch } from "../../hooks/Store";
import { resetTokenCategories } from "../../reducers/tournamentSlice";
import { useLazyGetHomeQuery } from "../../service/tournaments";
import { Tournaments } from "../../types/general";

const Introduction = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newCls, setNewCls] = useState<boolean>(false);
  const [cardData, setCardData] = useState<any[]>();
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [getHomeData] = useLazyGetHomeQuery();

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getHomeData({}).unwrap();
      setLoading(false);

      if (response?.statusCode === 200) {
        setCardData(response?.data?.tournaments);
      }
    } catch (error: any) {
      setLoading(false);
      // errorToast(error?.data?.message || "");
    }
  };
  useEffect(() => {
    fetchData();
  }, [load]);

  const handleCardClick = (tournament: Tournaments) => {
    const payload = tournament?.tokenCategories?.map((item) => {
      return {
        _id: item?._id || "",
        name: item?.name || "",
        tokens: item?.tokens || "",
        totalPotSize: item?.totalPotSize || 0,
      };
    });

    dispatch(resetTokenCategories(payload));
    setToStorage(STORAGE_KEYS.tokenCategories, JSON.stringify(payload));

    navigate(`/predictions/${tournament?._id}`, {
      state: {
        tournamentId: tournament?._id,
        tournamentName: tournament?.name,
        tournamentImage: tournament?.image,
        tournamentLive: tournament?.isLive,
        tournamentFixtures: tournament?.fixtures,
        cutoff: tournament?.cutOffTime,
      },
    });
  };

  const onSelectCard = () => {};

  return (
    <div className="nv_spc bg bnr">
      <Loader isLoad={loading} />
      <div className="container matchmain">
        {cardData?.length
          ? cardData?.map((item) => {
              return (
                <div className="outr_mtchcrd">
                  {!item?.isLive ? (
                    <div
                      onClick={() => handleCardClick(item)}
                      className="mtch_crd"
                      key={item?._id}
                    >
                      <MatchCard
                        onSelectCard={onSelectCard}
                        setLoad={setLoad}
                        load={load}
                        id={item?._id}
                        image={item?.image}
                        fixtures={item?.fixtures}
                        title={item?.name}
                        token={item?.tokenCategories}
                        totalEnteries={item?.totalEntries}
                        setNewCls={setNewCls}
                        isLive={item?.isLive}
                        cutoff={item?.cutOffTime}
                      />
                    </div>
                  ) : null}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};
export default Introduction;
