/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Layout from "../../layout";
import useTranslation from "../../hooks/Translation";
import "./ExtraBonus.scss";
import { useLazyGetSeasonDetailsQuery } from "../../service/tournaments";
import { Seasons } from "../../types/general";
import { Loader } from "../../components";

const ExtraBonus = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const translation = useTranslation() as any;
  const [loading, setLoading] = useState<boolean>(false);
  const [seasonalData, setSeasonalData] = useState<Seasons>();
  const [daysDifference, setDaysDifference] = useState(0);
  const [minutesDifference, setMinutesDifference] = useState(0);
  const [hoursDifference, setHoursDifference] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [getSeason] = useLazyGetSeasonDetailsQuery();
  console.log(daysDifference, hoursDifference, minutesDifference, "hghghghg");

  const getSeasonalDetail = async (_id: any) => {
    try {
      setLoading(true);
      const response = await getSeason({ banner_id: _id }).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        setSeasonalData(response?.data);
      }
    } catch (error: any) {
      setLoading(false);
      // errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getSeasonalDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (seasonalData) {
        const startDateTime = moment(
          `${seasonalData?.startDate}T${seasonalData?.startTime}:00.037Z`
        );
        console.log(startDateTime, "startDateTime");

        const currentDateTime = moment().format("YYYY-MM-DD HH:mm");
        console.log(currentDateTime, "currentDateTime");

        const daysDiff = startDateTime.diff(currentDateTime, "days");
        const hoursDiff = startDateTime.diff(currentDateTime, "hours");
        // const hoursRemaining = ((hoursDiff % 24) + 24) % 24;
        const minutesDiff = startDateTime.diff(currentDateTime, "minutes");
        setDaysDifference(daysDiff);

        if (minutesDiff > 59) {
          // Calculate hours and remaining minutes
          const hoursRemaining = Math.floor(minutesDiff / 60);
          const remainingMinutes = minutesDiff % 60;
          setHoursDifference(hoursRemaining);
          setMinutesDifference(remainingMinutes);
        } else {
          // If less than 59 minutes, set minutes only
          setHoursDifference(0);
          setMinutesDifference(minutesDiff);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [seasonalData]
  );

  return (
    <Layout>
      <main className="page_bg">
        <Loader isLoad={loading} />
        <div className=" nv_spc_bns ext_bn_wrp">
          <div className="container">
            <div className="mob_view">
              <i
                className="fa-solid fa-arrow-up-long bck_btn"
                onClick={() => navigate("/")}
              />
            </div>

            <div className="bonmain">
              <div
                className="bonusBnr"
                style={{ backgroundImage: `url(${seasonalData?.image})` }}
              >
                <div className="bns_tp bns_tp_flx">
                  <h1>{seasonalData?.title || ""}</h1>
                </div>
                {seasonalData?.bannerDescription && (
                  <h3
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: seasonalData.bannerDescription,
                    }}
                  />
                )}

                <h4>
                  {translation.Globals.regFee}:{" "}
                  <span>
                    {seasonalData?.tokenFee || 0} {translation.Globals.tokens}
                  </span>
                </h4>
              </div>
              <div className="datediv">
                <h3>
                  {moment(
                    `${seasonalData?.startDate}T${seasonalData?.startTime}:00.037Z`
                  ).format("dddd, D MMMM, HH:mm")}
                </h3>

                <h3>
                  {moment(
                    `${seasonalData?.endDate}T${seasonalData?.endTime}:00.037Z`
                  ).format("dddd, D MMMM, HH:mm")}
                </h3>
              </div>
              <div className="tireddiv">
                {seasonalData?.description && (
                  <p
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: seasonalData.description,
                    }}
                  />
                )}
              </div>
              <div className="mt_autom_mov">
                {hoursDifference <= 0 &&
                daysDifference <= 0 &&
                minutesDifference <= 0 ? (
                  <p className="text_danger italic text_center">
                    Registration Closed
                  </p>
                ) : (
                  <p className="text_danger italic text_center">
                    Registration closes in{" "}
                    {daysDifference > 0
                      ? `${daysDifference} day${daysDifference > 1 ? "s" : ""}`
                      : ""}
                    {hoursDifference > 0 && daysDifference <= 0
                      ? `${hoursDifference} ${
                          hoursDifference === 1 ? "hour" : "hours"
                        } ${minutesDifference} minutes`
                      : ""}
                    {hoursDifference <= 0 && daysDifference <= 0
                      ? `${minutesDifference} minute${
                          minutesDifference > 1 ? "s" : ""
                        }`
                      : ""}
                    {/* {hoursDifference <= 0 && daysDifference <= 0
                      ? `${minutesDifference} minutes`
                      : ""} */}
                  </p>
                )}

                <h5>*{translation.extraBonus.payFee}</h5>
                <div className="btndiv">
                  <button
                    type="button"
                    className="btn"
                    onClick={() =>
                      navigate(`/seasonaltournament/${id}`, {
                        state: "seasonal_tornament",
                      })
                    }
                  >
                    {translation.extraBonus.learn_more}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};
export default ExtraBonus;
