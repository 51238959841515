/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import useTranslation from "../../hooks/Translation";
import { usePostLoginMutation } from "../../service/Auth";

import { useAppDispatch } from "../../hooks/Store";
import { setCredentials } from "../../reducers/authSlice";

import { generateSEKAndHash } from "../../utils/crypto";
import { Loader } from "../../components";
import {
  STORAGE_KEYS,
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../helpers/Storage";
import { errorToast } from "../../helpers/ShowToast";

type props = {
  scrollPosition: ScrollPosition;
};
const Login = ({ scrollPosition }: props) => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const [LoginUpMutation, LoginMutationData] = usePostLoginMutation();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();

  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      userName: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      userName: Yup.string()
        .required(translation.validations.user_required)
        .label("Username")
        .min(2, translation.validations.min_username)
        .max(15),
      password: Yup.string()
        .required(translation.validations.password_required)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          translation.validations.pass_valid
        )
        .label("Password"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
      const data = {
        userName: formik.values.userName,
        password: formik.values.password,
        appkey: new Date().toISOString(),
        deviceType: "WEB",
        deviceToken: fcmtoken,
      };

      const body = generateSEKAndHash(data);

      if (body) {
        try {
          const response = await LoginUpMutation(body).unwrap();
          if (response?.statusCode === 200) {
            setToStorage(
              STORAGE_KEYS.token,
              JSON.stringify(response?.data?.token || "")
            );

            setToStorage(
              STORAGE_KEYS.userData,
              JSON.stringify(response?.data || "")
            );
            dispatch(
              setCredentials({
                token: response?.data?.token || "",
                user: response?.data || null,
              })
            );

            navigate("/", { replace: true });
          } else if (response?.statusCode === 503) {
            errorToast("Please wait! Service not available");
          }
        } catch (error: any) {
          if (error?.data?.message) {
            errorToast(error?.data?.message || "");
          } else {
            errorToast("No internet connection");
          }
        }
      }
      if (formik.values.remember) {
        setToStorage(STORAGE_KEYS.credentials, JSON.stringify(data));
      } else {
        removeFromStorage(STORAGE_KEYS.credentials);
      }
    },
  });

  // ----- for remember me ------
  const handleRemember = (item: boolean) => {
    console.log(item, "remember");
  };

  useEffect(() => {
    const userdata = getFromStorage(STORAGE_KEYS.credentials);
    if (userdata) {
      const rememberData = JSON.parse(`${userdata}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("userName", rememberData?.userName);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="signUpmain">
      <Loader isLoad={LoginMutationData?.isLoading} />
      <div className="container">
        <div className="sigup">
          <Link to="/">
            <figure>
              <LazyLoadImage
                src="/static/images/frediction.png"
                alt=""
                effect="blur"
                height="100%"
                width="100%"
                scrollPosition={scrollPosition}
              />
            </figure>
          </Link>

          <div className="signcontainer">
            <h4>{translation.auth.login}</h4>
            <form onSubmit={formik.handleSubmit}>
              <div className="inputdiv">
                <TextField
                  inputProps={{ maxLength: 15 }}
                  placeholder={translation.pridictionList.username}
                  fullWidth
                  className="txt_fld"
                  id="userName"
                  name="userName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.userName}
                  helperText={formik.touched.userName && formik.errors.userName}
                />
                <TextField
                  className="adornment txt_fld"
                  placeholder={translation.pridictionList.pass}
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className="iconador"
                        sx={{ top: "27px" }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPassword(!showPassword);
                            const inputEl: HTMLInputElement | null =
                              document.getElementById(
                                "password"
                              ) as HTMLInputElement | null;
                            if (inputEl) {
                              setTimeout(() => {
                                inputEl.setSelectionRange(
                                  inputEl.value.length,
                                  inputEl.value.length
                                );
                              }, 0);
                            }
                          }}
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                          className="eye_icon"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div className="rememberdiv">
                  <div className="remeLft">
                    <FormControlLabel
                      control={<Checkbox />}
                      label={translation.auth.remember}
                      className="rem_me"
                      checked={formik.values.remember}
                      name="remember"
                      onChange={formik.handleChange}
                      onClick={() => {
                        handleRemember(!formik.values.remember);
                      }}
                    />
                  </div>
                  <div onClick={() => navigate("/forgotpassword")}>
                    <h4>{translation.auth.forgot}</h4>
                  </div>
                </div>
                <button
                  className="btn submit_btn mtm_80"
                  type="submit"
                  style={{ width: "100%" }}
                  disabled={formik.isSubmitting}
                >
                  {translation.auth.signin}
                </button>
              </div>
            </form>
            <div className="haveAcc">
              <p>
                {translation.auth.noacc}{" "}
                <span onClick={() => navigate("/signup")}>
                  {translation.auth.signup}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default trackWindowScroll(Login);
