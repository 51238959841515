import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../app/Store";

export interface TokenCategories {
  _id: string;
  name: string;
  tokens: string;
  totalPotSize?: number | undefined;
}

interface General {
  tokenCategories: TokenCategories[] | null;
}

const initialState: General = {
  tokenCategories: null,
};
export const tournamentSlice = createSlice({
  name: "tournament",
  initialState,
  reducers: {
    resetTokenCategories: (state, action: PayloadAction<TokenCategories[]>) => {
      state.tokenCategories = action.payload;
    },
  },
});
export const { resetTokenCategories } = tournamentSlice.actions;

export const getTokenCategories = (state: RootState) =>
  state.tournament.tokenCategories;

export default tournamentSlice.reducer;
