import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDIJU3C8ibpqFDZFXPEw2moxu6JALc7TUY",
  authDomain: "sports-frediction.firebaseapp.com",
  projectId: "sports-frediction",
  storageBucket: "sports-frediction.appspot.com",
  messagingSenderId: "718003449693",
  appId: "1:718003449693:web:db20aede51aabce62702b4",
  measurementId: "G-2N98H9D9V7",
};

const app = initializeApp(firebaseConfig);
export default app;
