import React, { useState, useContext } from "react";

interface LanguageContextType {
  language: string;
  changeLanguage: (lang: string) => void;
}
// const LanguageContext = React.createContext({});
const LanguageContext = React.createContext<LanguageContextType>({
  language: "en",
  changeLanguage: () => {},
});
export const useLanguageContext = () => useContext(LanguageContext);
export default function LanguageContextProvider({
  children,
}: {
  children: any;
}) {
  const [language, changeLanguage] = useState("en");

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}
