/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */
import React, { useState } from "react";
import useTranslation from "../../hooks/Translation";
import { useAppSelector } from "../../hooks/Store";
import { getCms } from "../../reducers/generalSlice";

const About = () => {
  const translation = useTranslation() as any;
  const getCmsData = useAppSelector(getCms);

  return (
    <div className="editprofile">
      <h4>{translation.pridictionList.about}</h4>
      <div className="acc_Contnr">
        <div className="aboutpage">
          <p
            className={getCmsData ? "about_txt" : "about_txt2"}
            dangerouslySetInnerHTML={{
              __html: getCmsData
                ? getCmsData?.aboutUs
                : "Information not found",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default About;
