import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../app/Store";

export interface General {
  cms?: CMS | null;
}
export interface CMS {
  phone: string;
  countryCode: string;
  email: string;
  address: string;
  cancellation: string;
  terms: string;
  legal: string;
  privacy: string;
  aboutUs: string;
}

const initialState: General = {
  cms: null,
};
export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    resetCms: (state, action: PayloadAction<CMS>) => {
      state.cms = action.payload;
    },
  },
});
export const { resetCms } = generalSlice.actions;

export const getCms = (state: RootState) => state.general.cms;

export default generalSlice.reducer;
