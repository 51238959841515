// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from "react-toastify";

export function successToast(message: string) {
  toast.dismiss();
  toast.success(message);
}
export function errorToast(message: string) {
  toast.dismiss();
  toast.error(message);
}
export function warnToast(message: string) {
  toast.dismiss();
  toast.warn(message);
}
