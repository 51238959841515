/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { RightDrawer } from "../../features";
import useTranslation from "../../hooks/Translation";
import { SidebarMenu } from "../../components";
import { useAppDispatch } from "../../hooks/Store";
import useAuth from "../../hooks/UseAuth";
import { setCredentials } from "../../reducers/authSlice";
import { STORAGE_KEYS, getFromStorage } from "../../helpers/Storage";
import { useLazyGetProfileQuery } from "../../service/Auth";
import "./Header.scss";

// import { useLanguageContext } from "../../context/LanguageContext";
type HeaderType = {
  scrollPosition: ScrollPosition;
};
const Header = ({ scrollPosition }: HeaderType) => {
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [ActiveDrawer, SetActiveDrawer] = useState<number>(1);

  // const { language, changeLanguage } = useLanguageContext();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const dispatch = useAppDispatch();
  const [GetProfile] = useLazyGetProfileQuery();
  const [tokens, setToken] = useState("");
  const user = useAuth();

  const fetchData = async (token: any) => {
    try {
      const response = await GetProfile({}).unwrap();
      if (response?.statusCode === 200) {
        setToken(response?.data?.tokens);
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: response?.data || null,
          })
        );
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.userData);

    if (token) {
      dispatch(
        setCredentials({
          token: JSON.parse(`${token}`),
          user: JSON.parse(`${userData}`) || null,
        })
      );
      fetchData(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const windowScrollPosition = window.scrollY;
      const headerHeight = 0; // Adjust this value as needed

      if (windowScrollPosition > headerHeight) {
        setBackgroundColor("#22252d"); // Replace 'your-color' with the desired background color
      } else {
        setBackgroundColor("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setMenuVisible(open);
    };

  const path = window.location.pathname;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <header
      style={{ backgroundColor }}
      className={path === "/" ? "hdr mob_vw" : "hdr"}
    >
      <RightDrawer
        sidebarVisible={sidebarVisible}
        setSidebarVisible={setSidebarVisible}
        ActiveDrawer={ActiveDrawer}
        predictions=""
        userName=""
        userImage=""
      />
      <div className="container mn_hdr">
        <div className="hdr_col hdr_mnu">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              cursor: "pointer",
            }}
            onClick={toggleDrawer(true)}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className="hdr_col hdr_logo" onClick={() => navigate("/")}>
          <LazyLoadImage
            src="/static/images/frediction.png"
            alt="logo"
            effect="blur"
            height="100%"
            width="100%"
            scrollPosition={scrollPosition}
          />
        </div>
        <div className="hdr_col hdr_btn">
          <div
            // type="button"
            style={{
              color: "#ecac35",
              textTransform: "uppercase",
              cursor: "pointer",
            }}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              user ? navigate("/userprofile") : navigate("/login");
            }}
          >
            <h4 className="hdr_tokens">
              {user ? user?.userName : translation.Globals.sign_in}
            </h4>
          </div>
          <h4
            style={{
              color: "#ecac35",
              textTransform: "uppercase",
              cursor: "pointer",
            }}
            className="hdr_tokens hdr_tkns2"
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              user ? navigate("/userprofile") : navigate("/login");
            }}
          >
            {tokens ? `${tokens} T` : null}
          </h4>
        </div>
      </div>
      <SidebarMenu
        toggleDrawer={toggleDrawer}
        menuVisible={menuVisible}
        setMenuVisible={setMenuVisible}
        SetActiveDrawer={SetActiveDrawer}
        setSidebarVisible={setSidebarVisible}
      />
    </header>
  );
};

export default trackWindowScroll(Header);
