/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Layout from "../../layout";
import useTranslation from "../../hooks/Translation";
import "../../components/Components.scss";
import { LiveMatchDetail, MatchCardTabs, MatchDetail } from "../../features";
import {
  useLazyGetTournamentDetailsQuery,
  useLazyGetTourneysDetailPageQuery,
} from "../../service/tournaments";
import { errorToast } from "../../helpers/ShowToast";
import {
  Season,
  Seasons,
  Tournaments,
  TourneyDetails,
} from "../../types/general";
import { Loader } from "../../components";

const TournamentDetail = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;

  const [newCls, setNewCls] = useState<boolean>(false);
  const [seasonData, setSeasonData] = useState<Season[]>([]);
  const [torunament, setTorunament] = useState("Tournament");
  const [matchDetail, setMatchDetail] = useState<TourneyDetails>();
  const [selectedMatchId, setSelectedMatchId] = useState<string>("");
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");
  const [tourneyData, setTourneyData] = useState<Tournaments[]>([]);
  const [activeTab, setActiveTab] = useState<string>("upcoming");
  const [seasonalData, setSeasonalData] = useState<Seasons>();
  const [loading, setLoading] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<number>(-1);
  const [open, setOpen] = React.useState(false);

  const [cardFirstDate, setCardFirstDate] = useState<
    string | null | undefined
  >();

  const [getTourneys] = useLazyGetTourneysDetailPageQuery();
  const [getTourneyDetailData] = useLazyGetTournamentDetailsQuery();

  const path = window.location.pathname;
  const location = useLocation();

  const state = new URLSearchParams(location.search).get("id");

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getTourneys({ state }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setTourneyData(res?.data);

        if (res?.data?.length) {
          await getTournamentDetails(res?.data);
        }
      } else if (res.statusCode === 503) {
        errorToast(translation.toastMessages.service_unavailable);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  const getTournamentDetails = async (data: Tournaments[]) => {
    if (data?.length && data[0]?.fixtures?.length) {
      const fixtures = data[0]?.fixtures;
      const copiedFixturesss = fixtures ? [...fixtures] : [];
      const sort = copiedFixturesss?.sort((a, b) => {
        const dateA = moment(a?.fixture?.date) as any;
        const dateB = moment(b?.fixture?.date) as any;
        return dateA - dateB;
      });
      const currentDate = moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      const fDate = sort?.length ? sort[0]?.fixture.date : undefined;
      const lastDate = sort?.length
        ? // eslint-disable-next-line no-unsafe-optional-chaining
          sort[sort?.length - 1]?.fixture.date
        : undefined;

      const isMatchLive = moment(currentDate).isBetween(fDate, lastDate);
      try {
        setLoading(true);
        const response = await getTourneyDetailData({
          type: isMatchLive ? "live" : activeTab,
          id: data?.length ? data[0]?._id : "",
          fixtureId: isMatchLive ? data[0]?.fixtures[0]?.fixture_id : null,
          categoryId: data?.length ? data[0]?.categoryId?._id : "",
        }).unwrap();
        setLoading(false);
        if (response?.statusCode === 200) {
          setSelectedMatchId(data[0]?._id);
          setSelectedCategoryId(data[0]?.categoryId?._id);
          setMatchDetail(response?.data);
        } else if (response.statusCode === 503) {
          errorToast(translation.toastMessages.service_unavailable);
        }
      } catch (error: any) {
        setLoading(false);
        // console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <Layout>
      <Loader isLoad={loading} />
      <div className="nv_spc cmn_bg tornmain cms">
        <div className="container">
          <div className="sb">
            <div className="mob_view">
              <i
                className="fa-solid fa-arrow-up-long bck_btn mt_19"
                onClick={() => navigate(`/predictions/${state}`)}
              />
            </div>
            <h2>{translation.mytourney.tournamentDetail}</h2>
          </div>
          <div className="tourmain">
            <div className="trLft ">
              <MatchCardTabs
                loading={loading}
                setLoading={setLoading}
                setTorunament={setTorunament}
                setCardFirstDate={setCardFirstDate}
                seasonData={seasonData}
                setSeasonData={setSeasonData}
                setTourneyData={setTourneyData}
                tourneyData={tourneyData}
                setSelectedMatchId={setSelectedMatchId}
                selectedCategoryId={selectedCategoryId}
                setSelectedCategoryId={setSelectedCategoryId}
                setNewCls={setNewCls}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                setSeasonalData={setSeasonalData}
                setMatchDetail={setMatchDetail}
                selectedMatchId={selectedMatchId}
                path={path}
                setCountDown={setCountDown}
                countDown={countDown}
                setOpen={setOpen}
                open={false}
              />
            </div>
            {selectedMatchId ? (
              <div className={newCls ? "trRyt added" : "trRyt"}>
                <div className="top_hdng" onClick={() => setNewCls(false)}>
                  <div className="mob_view">
                    <i className="fa-solid fa-arrow-up-long bck_btn" />
                  </div>
                </div>
                {torunament === "Tournament" ? (
                  <MatchDetail
                    setSelectedMatchId={setSelectedMatchId}
                    cardId={selectedMatchId}
                    selectedCategoryId={selectedCategoryId}
                    matchDetail={matchDetail}
                    seasonData={seasonData}
                    tourneyData={tourneyData}
                    setMatchDetail={setMatchDetail}
                    state="tournamentDetail"
                    cardFirstDate={cardFirstDate}
                    setCardFirstDate={setCardFirstDate}
                  />
                ) : (
                  <LiveMatchDetail
                    setSelectedMatchId={setSelectedMatchId}
                    cardId={selectedMatchId}
                    seasonData={seasonData}
                    tourneyData={tourneyData}
                    seasonalData={seasonalData}
                  />
                )}
              </div>
            ) : (
              <div
                style={{ width: "100%" }}
                className="no_data mob_dsply web_nodata"
              >
                {/* {translation.Globals.no_data} */}
              </div>
            )}
          </div>
        </div>
        {!loading && seasonData?.length === 0 && tourneyData?.length === 0 ? (
          <p className="no_data mob_nodata">
            You haven’t joined any tournament yet. Hurry up, Join Now!!
          </p>
        ) : null}
      </div>
    </Layout>
  );
};

export default TournamentDetail;
