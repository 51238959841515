import React, { useEffect, useState } from "react";

import "./TokenHistory.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Layout from "../../layout";
import useTranslation from "../../hooks/Translation";
import { useLazyGetTokenHistoryQuery } from "../../service/Common";
import { tokenhistory } from "../../types/general";
import { Loader } from "../../components";

const TokenHistory = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const [tokenHistory, setTokenHistory] = useState<tokenhistory[]>();
  const [loading, setLoading] = useState(false);
  const [getTokenHistoryData] = useLazyGetTokenHistoryQuery();

  const getUserTokenHistory = async () => {
    try {
      setLoading(true);
      const res = await getTokenHistoryData({}).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setTokenHistory(res?.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const totalTokens = tokenHistory?.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.tokens;
  }, 0);

  useEffect(() => {
    getUserTokenHistory();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <Loader isLoad={loading} />
      <main className="ldrbrd_wrp cms">
        <div className="ldrbrd_dtls inputdiv container nv_spc ">
          <div className="mob_view">
            <i
              className="fa-solid fa-arrow-up-long bck_btn"
              onClick={() => navigate("/userprofile")}
            />
          </div>
          <h2 className="hdng">{translation.leaderBoard.token_history}</h2>
          <h6 className="yellow_text tokens_received">
            {translation.leaderBoard.total} <span>{totalTokens}</span>{" "}
            <span>{translation.leaderBoard.tokens_received}</span>
          </h6>

          {tokenHistory?.length
            ? tokenHistory.map((item) => {
                return (
                  <div key={item?._id} className="tms">
                    <div className="tkn_lft">
                      <h4 className="txt">
                        {item?.tokens}{" "}
                        <span>{translation.leaderBoard.tokens_received}</span>{" "}
                        {/* <span>({translation.Globals.premium})</span> */}
                      </h4>
                    </div>
                    <div className="tms_ryt">
                      <p>
                        {moment(item?.createdAt).format("D MMM, YYYY h:mm A")}
                      </p>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </main>
    </Layout>
  );
};

export default TokenHistory;
