/* eslint-disable import/no-extraneous-dependencies */
import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import pagenotfound from "./pagenotfound.module.scss";
import useTranslation from "../../hooks/Translation";

type props = {
  scrollPosition: ScrollPosition;
};
const PageNotFound = ({ scrollPosition }: props) => {
  const translation = useTranslation() as any;
  return (
    <main>
      <div className="container">
        <div className={pagenotfound.notfound}>
          <div className={pagenotfound.custome_row}>
            <div className="w_50">
              <figure>
                <LazyLoadImage
                  src="../static/img/notfound.svg"
                  alt=""
                  effect="blur"
                  height="100%"
                  width="100%"
                  scrollPosition={scrollPosition}
                />
              </figure>
            </div>
            <div className="w_50">
              <h1>404</h1>

              <h2>{translation.Globals.page_not_found}</h2>
              <p>{translation.Globals.no_page_des}</p>
              <Link to="/">
                <Button className={pagenotfound.btn}>
                  {translation.header.home}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default trackWindowScroll(PageNotFound);
