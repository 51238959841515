/* eslint-disable import/no-extraneous-dependencies */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import moment from "moment";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useTranslation from "../../hooks/Translation";
import { useLazyGetLiveMatchDataQuery } from "../../service/tournaments";
import { Tournaments } from "../../types/general";
import { Loader } from "../../components";

interface DrawerState {
  setSidebarVisible: Dispatch<SetStateAction<boolean>>;
  selectedFixtureId?: string | undefined;
  sidebarVisible: boolean;
  scrollPosition: ScrollPosition;
}

interface ScoreType {
  playerName: string;
  elapsed: number;
  teamAGoal: number;
  teamBGoal: number;
  playerId: string | undefined;
}

const MatchDetailDrawer = ({
  setSidebarVisible,
  selectedFixtureId,
  sidebarVisible,
  scrollPosition,
}: DrawerState) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const [liveDetail, setLiveDetail] = useState<Tournaments>();
  const [scoreData, setScoreData] = useState<ScoreType[]>([]);
  const [loading, setLoading] = useState(false);
  const [getLiveDataMethod] = useLazyGetLiveMatchDataQuery();

  const getLiveMatchData = async () => {
    try {
      setLoading(true);
      const res = await getLiveDataMethod({
        fixtureId: selectedFixtureId,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setLiveDetail(res?.data || undefined);
        const modifiedArr = [] as ScoreType[];
        if (res?.data?.events?.length) {
          let countOfTeamA = 0 as number;
          let countOfTeamB = 0 as number;

          res?.data?.events
            ?.filter((ele) => ele?.type === "Goal")
            ?.map((item) => {
              modifiedArr.push({
                playerId: item?.player?.id,
                playerName: item?.player?.name,
                elapsed: item?.time?.elapsed,
                teamAGoal:
                  item?.team?.id === res?.data?.teams?.home?.id
                    ? (countOfTeamA += 1)
                    : countOfTeamA,
                teamBGoal:
                  item?.team?.id === res?.data?.teams?.away?.id
                    ? (countOfTeamB += 1)
                    : countOfTeamB,
              });
              return 0;
            });

          setScoreData(modifiedArr);
        } else {
          setScoreData([]);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setScoreData([]);
    setLiveDetail(undefined);
    getLiveMatchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarVisible]);

  return (
    <div className="chat_otr invitemain mtcdrwr_dl">
      <Loader isLoad={loading} />
      <div className="mob_view pr_3">
        <i
          className="fa-solid fa-arrow-up-long bck_btn"
          onClick={() => setSidebarVisible(false)}
        />
      </div>
      <h3>
        {moment(liveDetail?.fixture?.date).format("dddd")}{" "}
        {moment(liveDetail?.fixture?.date).format("LL")}
      </h3>
      <p> {moment(liveDetail?.fixture?.date).format("LT")}</p>
      <div className="matchteams">
        <div className="team1">
          <figure>
            <LazyLoadImage
              src={liveDetail?.teams?.home?.logo || "/static/images/team4.png"}
              alt=""
              effect="blur"
              height="100%"
              width="100%"
              scrollPosition={scrollPosition}
            />
          </figure>
          <div className="scro">
            <h3>{liveDetail?.goals?.home || 0}</h3>
            <h2>{liveDetail?.teams?.home?.name || ""}</h2>
          </div>
        </div>
        <div className="centerdiv">
          <figure className="vs">
            <LazyLoadImage
              src="/static/images/vs2.png"
              alt=""
              effect="blur"
              height="100%"
              width="100%"
              scrollPosition={scrollPosition}
            />
          </figure>
          {/* <p>{item?.timeLeft}</p> */}
        </div>
        <div className="team1 team2">
          <div className="scro">
            <h3>{liveDetail?.goals?.away || 0}</h3>
            <h2>{liveDetail?.teams?.away?.name || ""}</h2>
          </div>
          <figure>
            <LazyLoadImage
              src={liveDetail?.teams?.away?.logo || "/static/images/team2.png"}
              alt=""
              effect="blur"
              height="100%"
              width="100%"
              scrollPosition={scrollPosition}
            />
          </figure>
        </div>
      </div>
      <div className="scrbrd_otr">
        <div className="scoreboard">
          <div className="or_opt">
            <h4>{translation.mytourney.scores}</h4>
          </div>
          <div className="scoretable">
            {scoreData?.length
              ? scoreData?.map((item) => (
                  <div className="matchscroe" key={item?.playerId}>
                    <h3>
                      {item?.teamAGoal}:{item?.teamBGoal}
                    </h3>
                    <h3>{item?.playerName || ""}</h3>
                    <h3>
                      {item?.elapsed || ""}
                      {`'`}
                    </h3>
                  </div>
                ))
              : null}
          </div>
        </div>
        <div className="scoreboard">
          <div className="or_opt">
            <h4>{translation.mytourney.cards}</h4>
          </div>
          <div className="scoretable">
            {liveDetail?.events?.length
              ? liveDetail?.events
                  ?.filter((ele) => ele?.type === "Card")
                  ?.map((item) => (
                    <div className="matchscroe" key={item?.player?.id}>
                      <h3>
                        {item?.time?.elapsed || ""}
                        {`'`}
                      </h3>
                      <h3>{item?.player?.name || ""}</h3>
                      <div
                        className="colr"
                        style={{
                          background: item?.detail?.includes("Yellow")
                            ? "#ECAC35"
                            : "red",
                        }}
                      />
                    </div>
                  ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default trackWindowScroll(MatchDetailDrawer);
