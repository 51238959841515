// eslint-disable-next-line import/no-extraneous-dependencies
import crypto from "crypto-js";

function generateRandomString(length: number) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let randomString = "";

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

export function generateSEKAndHash(data: any) {
  const algorithm = "aes-256-cbc";
  const iv = "9MSkTNVhJcWedegR".slice(0, 16);
  try {
    const key = crypto.lib.WordArray.random(32);

    const body = JSON.stringify(data);

    const encrypted = crypto.AES.encrypt(body, key, {
      iv: crypto.enc.Utf8.parse(iv),
      mode: crypto.mode.CBC,
    });

    const encryptedHex = encrypted.ciphertext.toString();
    const keyHash = key.toString();

    return {
      hash: keyHash,
      sek: encryptedHex,
    };
  } catch (error) {
    console.error("", error);
    return null;
  }
}
//   const randomBytes = lib.WordArray.random(32); // Generates 128 bits (16 bytes) of random data
//   const sek = randomBytes.toString();
//   const hashedSEK = SHA256(sek).toString();
//   return { sek, hashedSEK };

//   const { sek, hashedSEK } = generateSEKAndHash();
//   console.log("SEK:", sek);
//   console.log("Hashed SEK:", hashedSEK);
//   return {
//     sek,
//     hashedSEK,
//   };
