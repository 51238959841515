/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Tournaments } from "../types/general";

type props = {
  item: Tournaments;
  state: any;
  select_id: string;
  scrollPosition: ScrollPosition;
  handleClick: () => void;
  changeGoalValue: (value: number, team: string, matchId: string) => void;
};

const PredictionCard = ({
  item,
  state,
  select_id,
  handleClick,
  scrollPosition,
  changeGoalValue,
}: props) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [matchStartDate, setMatchStartDate] = useState(
    new Date(item?.fixture?.date)
  );

  const calculateTimeLeft = () => {
    const currentDateTime = moment();
    const targetDateTime = moment(matchStartDate);
    const timeDiff = targetDateTime.diff(currentDateTime);

    if (timeDiff < 0) {
      return {
        minutes: 0,
        seconds: 0,
      };
    }
    const hoursLeft = Math.floor(timeDiff / (1000 * 3600));
    const minutesLeft = Math.floor((timeDiff % (1000 * 3600)) / (1000 * 60));
    const secondsLeft = Math.floor((timeDiff / 1000) % 60);

    return {
      hours: hoursLeft,
      minutes: minutesLeft,
      seconds: secondsLeft,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateTimeLeft()]);

  const formatTimeUnit = (timeUnit: any) => {
    return timeUnit < 10 ? `0${timeUnit}` : timeUnit.toString();
  };

  return (
    <div className="prdctnCrd">
      <div
        className={
          `${select_id}` === item?._id ? "prict_crd mnCrd2" : "prict_crd mnCrd"
        }
        onClick={handleClick}
      >
        <div className="pri_cdcl pri_cdft">
          <figure className="pri_cdcl_lt">
            <LazyLoadImage
              src={item.teams?.home?.logo}
              alt=""
              effect="blur"
              scrollPosition={scrollPosition}
            />
          </figure>
          <div className="pri_cdcl_rt">
            <h6>{item.teams?.home?.name}</h6>
            {state === "livematch" ? (
              <h2>2</h2>
            ) : (
              <div className="pri_cdcl_rtin counter">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    let goalValue = item?.goals?.home || 0;
                    if (goalValue > 0) {
                      goalValue -= 1;
                    }
                    changeGoalValue(goalValue, "home", item?._id);
                  }}
                  className="cntr_indr"
                >
                  <figure>
                    <LazyLoadImage
                      src="/static/images/minus-circle.svg"
                      alt=""
                      effect="blur"
                      scrollPosition={scrollPosition}
                    />
                  </figure>
                </div>
                <h2>{item?.goals?.home || 0}</h2>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    let goalValue = item?.goals?.home || 0;
                    if (goalValue < 10) {
                      goalValue += 1;
                    }
                    changeGoalValue(goalValue, "home", item?._id);
                  }}
                  className="cntr_indr"
                >
                  <figure>
                    <LazyLoadImage
                      src="/static/images/add-circle.svg"
                      alt=""
                      effect="blur"
                      scrollPosition={scrollPosition}
                    />
                  </figure>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="pri_cdcl pri_cdsd">
          <div className={state === "livematch" ? "str_mtch2" : "str_mtch"}>
            <div onClick={handleClick} className="str_img">
              {state === "livematch" ? null : (
                <figure>
                  <LazyLoadImage
                    src={
                      select_id === item?._id
                        ? "/static/images/white_star_new.svg"
                        : "/static/images/star.png"
                    }
                    alt=""
                    effect="blur"
                    scrollPosition={scrollPosition}
                  />
                </figure>
              )}
            </div>
            {select_id === item?._id ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className="vrs_rvrs">2X</span>
                {state === "livematch" ? <p>LIVE</p> : null}
              </div>
            ) : (
              <div>
                <figure className="versus">
                  <LazyLoadImage
                    src="/static/images/versus.png"
                    alt=""
                    effect="blur"
                    scrollPosition={scrollPosition}
                  />
                </figure>
                {state === "livematch" ? (
                  <p style={{ color: "#DC1430", fontWeight: 400 }}>LIVE</p>
                ) : null}
              </div>
            )}

            {moment(currentDate).isSame(matchStartDate, "date") ? (
              <div className="jhjhjjhj">
                {timeLeft && formatTimeUnit(timeLeft.hours) <= 0 ? (
                  <h6 className={select_id === item?._id ? "black" : "gold"}>
                    {formatTimeUnit(timeLeft.minutes)}m{" "}
                    {formatTimeUnit(timeLeft.seconds)}s
                  </h6>
                ) : (
                  <h6 className={select_id === item?._id ? "black" : "gold"}>
                    {formatTimeUnit(timeLeft.hours)}h{" "}
                    {formatTimeUnit(timeLeft.minutes)}m{" "}
                  </h6>
                )}
              </div>
            ) : null}
          </div>
        </div>

        <div className="pri_cdcl pri_cdtd">
          <figure className="pri_cdcl_lt">
            <LazyLoadImage
              src={item.teams?.away?.logo}
              alt=""
              effect="blur"
              scrollPosition={scrollPosition}
            />
          </figure>
          <div className="pri_cdcl_rt">
            <h6>{item.teams?.away?.name || ""}</h6>
            {state === "livematch" ? (
              <h2>4</h2>
            ) : (
              <div className="pri_cdcl_rtin counter">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    let goalValue = item?.goals?.away || 0;
                    if (goalValue > 0) {
                      goalValue -= 1;
                    }
                    changeGoalValue(goalValue, "away", item?._id);
                  }}
                  className="cntr_indr"
                >
                  <figure>
                    <LazyLoadImage
                      src="/static/images/minus-circle.svg"
                      alt=""
                      effect="blur"
                      scrollPosition={scrollPosition}
                    />
                  </figure>
                </div>
                <h2>{item?.goals?.away || 0}</h2>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    let goalValue = item?.goals?.away || 0;
                    if (goalValue < 10) {
                      goalValue += 1;
                    }
                    changeGoalValue(goalValue, "away", item?._id);
                  }}
                  className="cntr_indr"
                >
                  <figure>
                    <LazyLoadImage
                      src="/static/images/add-circle.svg"
                      alt=""
                      effect="blur"
                      scrollPosition={scrollPosition}
                    />
                  </figure>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default trackWindowScroll(PredictionCard);
