// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from "query-string";
import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import type { RootState } from "../app/Store";

import { resetAuth } from "../reducers/authSlice";
import { API_URL } from "../helpers/constants/Urls";
import { errorToast } from "../helpers/ShowToast";

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).auth;
    const { tempToken } = (getState() as RootState).auth;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    } else if (tempToken) {
      headers.set("authorization", `Bearer ${tempToken}`);
    }
    headers.set("devicetype", "WEB");
    return headers;
  },
  paramsSerializer: (rec) => {
    return queryString.stringify(rec, { arrayFormat: "bracket", encode: true });
  },
});
const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 400) {
    const errors = Object.values(result?.error?.data || {});
    console.log({ errors });
    if (errors?.length > 1 && errors[1] === 400) {
      return result;
    }
    if (errors?.length) {
      const error = errors[0] as any;
      if (error?.length) {
        // ShowToast(error[0]);
      }
    }
  }
  if (result.error?.status === 401) {
    api.dispatch(resetAuth());
    if (window) {
      errorToast("Session expired");
      window.location.replace("/");
    }
  }
  // if (result.error?.status === 429) {
  //   console.log("4299 hit");
  //   if (window) {
  //     errorToast("Too many hits!!");
  //   }
  // }

  return result;
};
const emptySplitApi = createApi({
  baseQuery: baseQueryWithAuth,
  tagTypes: ["UNAUTHORIZED", "UNKNOWN_ERROR"],
  endpoints: () => ({}),
});
export default emptySplitApi;
