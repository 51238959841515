/* eslint-disable object-shorthand */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { usePostChangePasswordMutation } from "../../service/Auth";
import { generateSEKAndHash } from "../../utils/crypto";
import useTranslation from "../../hooks/Translation";
import { errorToast, successToast } from "../../helpers/ShowToast";
import { Loader } from "../../components";

const ChangePassword = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const [oldPassword, setOldPassword] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<boolean>(false);
  const [ChangePasswordMutation, ChangePasswordMutationData] =
    usePostChangePasswordMutation();
  const deviceId = navigator.userAgent;
  // const data = generateSEKAndHash();

  // formik
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .label("oldpassword")
        .required(translation.validations.old_pass_req)
        .label("Password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          translation.validations.pass_valid
        ),
      newPassword: Yup.string()
        .label("newPassword")
        .required(translation.validations.new_pass_req)
        .label("Password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          translation.validations.pass_valid
        )
        .notOneOf(
          [Yup.ref("password"), null],
          translation.validations.new_pass_val
        ),

      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], translation.validations.confirm_pass)
        .required(translation.validations.confirmpass_req),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      const data = {
        oldPassword: formik.values.oldPassword,
        password: formik.values.newPassword,
        deviceId: deviceId,
        appkey: new Date().toISOString(),
      };
      const body = generateSEKAndHash(data);
      if (body) {
        try {
          const response = await ChangePasswordMutation(body).unwrap();

          if (response?.statusCode === 200) {
            successToast(response?.message || "");
          } else if (response?.statusCode === 503) {
            errorToast(translation.toastMessages.service_unavailable);
          }
        } catch (error: any) {
          if (error?.data?.message) {
            errorToast(error?.data?.message || "");
          } else {
            errorToast(translation.toastMessages.no_internet);
          }
        }
      }

      // navigate("/");
    },
  });
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Loader isLoad={ChangePasswordMutationData.isLoading} />
        <TextField
          className="adornment txt_fld"
          placeholder={translation.pridictionList.enteroldpass}
          fullWidth
          type={oldPassword ? "text" : "password"}
          id="oldPassword"
          name="oldPassword"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.oldPassword}
          helperText={formik.touched.oldPassword && formik.errors.oldPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                className="iconador"
                sx={{ top: "27px" }}
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setOldPassword(!oldPassword);
                    const inputEl: HTMLInputElement | null =
                      document.getElementById(
                        "oldPassword"
                      ) as HTMLInputElement | null;
                    if (inputEl) {
                      setTimeout(() => {
                        inputEl.setSelectionRange(
                          inputEl.value.length,
                          inputEl.value.length
                        );
                      }, 0);
                    }
                  }}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  {oldPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          className="adornment txt_fld"
          placeholder={translation.pridictionList.enternewpass}
          fullWidth
          type={newPassword ? "text" : "password"}
          id="newPassword"
          name="newPassword"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.newPassword}
          helperText={formik.touched.newPassword && formik.errors.newPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                className="iconador"
                sx={{ top: "27px" }}
              >
                <IconButton
                  className="eye_icon"
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setNewPassword(!newPassword);
                    const inputEl: HTMLInputElement | null =
                      document.getElementById(
                        "newPassword"
                      ) as HTMLInputElement | null;
                    if (inputEl) {
                      setTimeout(() => {
                        inputEl.setSelectionRange(
                          inputEl.value.length,
                          inputEl.value.length
                        );
                      }, 0);
                    }
                  }}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  {newPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className="valid">
          <p>8 {translation.Globals.character}</p>
          <p>1 {translation.Globals.uppercase}</p>
          <p>1 {translation.Globals.numeric}</p>
        </div>
        <TextField
          className="adornment txt_fld"
          placeholder={translation.pridictionList.confirmpass}
          fullWidth
          type={confirmPassword ? "text" : "password"}
          id="confirmNewPassword"
          name="confirmNewPassword"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmNewPassword}
          helperText={
            formik.touched.confirmNewPassword &&
            formik.errors.confirmNewPassword
          }
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                className="iconador"
                sx={{ top: "27px" }}
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setConfirmPassword(!confirmPassword);
                    const inputEl: HTMLInputElement | null =
                      document.getElementById(
                        "confirmNewPassword"
                      ) as HTMLInputElement | null;
                    if (inputEl) {
                      setTimeout(() => {
                        inputEl.setSelectionRange(
                          inputEl.value.length,
                          inputEl.value.length
                        );
                      }, 0);
                    }
                  }}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  {confirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          className="btnn btn_primary"
          disabled={formik.isSubmitting}
        >
          {translation.Globals.updatePassword}
        </Button>
      </form>
    </div>
  );
};

export default ChangePassword;
