/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import "./LeaderboardDetails.scss";
import moment from "moment";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate } from "react-router-dom";
import Layout from "../../layout";
import useTranslation from "../../hooks/Translation";
import { ListingFilters, Loader, SidebarMenu } from "../../components";
import { errorToast } from "../../helpers/ShowToast";
import { leaderBoardData } from "../../types/general";

import { useLazyGetLeaderBoardQuery } from "../../service/Leaderboard";

type LeaderDetails = {
  scrollPosition: ScrollPosition;
};
const LeaderboardDetails = ({ scrollPosition }: LeaderDetails) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const [menuVisible, setMenuVisible] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [ActiveDrawer, SetActiveDrawer] = useState(1);
  const [activeButton, setActiveButton] = useState<number>(1);
  const [query, setQuery] = useState<string>("");
  const [getLeaderBoardData] = useLazyGetLeaderBoardQuery();
  const [leaderBoard, setLeaderBoard] = useState<leaderBoardData | undefined>();
  const [selectedCat, setSelectedCat] = useState([] as any);
  const [selectedTournament, setSelectedTournament] = useState([] as any);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setMenuVisible(open);
    };

  const getLeaderBoard = async (key: string) => {
    try {
      setLoading(true);
      const res = await getLeaderBoardData({
        type: activeButton === 1 ? "teams" : "players",
        search: query || "",
        category: !key && selectedCat?.length ? selectedCat : [],
        toDate: !key && endDate ? moment(endDate).format("YYYY-MM-DD") : null,
        fromDate:
          !key && startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        tournament:
          !key && selectedTournament?.length ? selectedTournament : [],
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setLeaderBoard(res?.data || []);
      }
      if (res.statusCode === 503) {
        errorToast(translation.toastMessages.service_unavailable);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLeaderBoard("clear");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeButton, query]);

  const handleClearFilter = () => {
    setSelectedCat([]);
    setSelectedTournament([]);
    setStartDate(null);
    setEndDate(null);
    setSidebarVisible(false);
  };

  return (
    <Layout>
      <Loader isLoad={loading} />
      <main className="ldrbrd_wrp cms">
        <div className="ldrbrd_dtls inputdiv container nv_spc ">
          <div className="mob_view">
            <i
              className="fa-solid fa-arrow-up-long bck_btn"
              onClick={toggleDrawer(true)}
            />
          </div>
          <h2 className="hdng">{translation.leaderBoard.leaderBoard}</h2>
          <div className="srch_mob_top">
            <div className="tabs">
              <div className="tbs_lft">
                <button
                  type="button"
                  className={
                    activeButton === 1 ? "ldr_btn btn" : " btn btn_outline"
                  }
                  onClick={() => {
                    setActiveButton(1);
                    setQuery("");
                    handleClearFilter();
                  }}
                >
                  {translation.leaderBoard.teams}
                </button>
                <button
                  type="button"
                  className={
                    activeButton === 2 ? "ldr_btn btn" : " btn btn_outline"
                  }
                  onClick={() => {
                    setActiveButton(2);
                    setQuery("");
                    handleClearFilter();
                  }}
                >
                  {translation.leaderBoard.players}
                </button>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="tab_ryt"
                onClick={() => {
                  setSidebarVisible(true);
                  SetActiveDrawer(5);
                }}
              >
                <figure>
                  <LazyLoadImage
                    src="/static/img/filter_icon.svg"
                    alt=""
                    effect="blur"
                    height="100%"
                    width="100%"
                    scrollPosition={scrollPosition}
                  />
                </figure>
                <p> {translation.leaderBoard.filters}</p>
              </div>
            </div>
            <div className="mob_top">
              <TextField
                placeholder="Search here"
                className="txt_inpt srch_field"
                onChange={(e) => setQuery(e.target.value)}
                value={query}
                variant="outlined"
              />
            </div>
          </div>
          {activeButton === 1 ? (
            <div>
              {leaderBoard?.data?.map((item: any) => {
                return (
                  <div
                    key={item.id}
                    className="tms"
                    onClick={() =>
                      navigate("/leaderboard", {
                        state: {
                          teamId: item?._id,
                          teamPoints: item?.points,
                          teamName: item?.name,
                          teamLogo: item?.logo,
                        },
                      })
                    }
                  >
                    <div className="tms_lft">
                      <figure>
                        <LazyLoadImage
                          src={item.logo}
                          alt=""
                          effect="blur"
                          height="100%"
                          width="100%"
                          scrollPosition={scrollPosition}
                        />
                      </figure>
                      <h4 className="txt">{item.name}</h4>
                    </div>
                    <div className="tms_ryt">
                      <h4 className="yellow_text txt">
                        {item.points} <span>pts</span>
                      </h4>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="users btmdiv">
              <table>
                <thead className="btm_div2">
                  <tr>
                    <th>{translation.mytourney.rank}</th>

                    <th style={{ textAlign: "center" }}>
                      {translation.mytourney.total_pts}
                    </th>
                  </tr>
                </thead>
                {leaderBoard?.data?.map((item) => {
                  return (
                    <tbody key={item._id}>
                      <tr
                        onClick={() =>
                          navigate("/leaderboard", {
                            state: {
                              playerId: item?._id,
                              playerName: item?.userName,
                              playerPoints: item?.points,
                              playerImage: item?.image,
                            },
                          })
                        }
                      >
                        <td>
                          <div className="user userleft2" key={item?._id}>
                            <div className="userleft ">
                              <figure className="user_dp">
                                <LazyLoadImage
                                  src={
                                    item?.image
                                      ? item?.image
                                      : "/static/images/user_placeholder.png"
                                  }
                                  alt=""
                                  effect="blur"
                                  height="100%"
                                  width="100%"
                                  scrollPosition={scrollPosition}
                                />
                              </figure>
                              <h4>{item?.userName}</h4>
                            </div>
                          </div>
                        </td>
                        <td className="points">{item.points}</td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
          )}
        </div>
      </main>
      <SidebarMenu
        toggleDrawer={toggleDrawer}
        menuVisible={menuVisible}
        setMenuVisible={setMenuVisible}
        SetActiveDrawer={SetActiveDrawer}
        setSidebarVisible={setSidebarVisible}
      />
      <ListingFilters
        setSelectedCat={setSelectedCat}
        selectedCat={selectedCat}
        setSelectedTournament={setSelectedTournament}
        selectedTournament={selectedTournament}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setSidebarVisible={setSidebarVisible}
        sidebarVisible={sidebarVisible}
        getLeaderBoard={getLeaderBoard}
        handleClearFilter={handleClearFilter}
      />
    </Layout>
  );
};

export default trackWindowScroll(LeaderboardDetails);
